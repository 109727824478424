import React from 'react';
import Card from 'react-bootstrap/Card';
import Carousel from 'react-multi-carousel';
import { BsFillHandThumbsUpFill } from "react-icons/bs";
import { Helmet } from "react-helmet";

const responsive = {
	desktop: {
		breakpoint: { max: 3000, min: 1024 },
		items: 1,
		slidesToSlide: 1 // optional, default to 1.
	},
	tablet: {
		breakpoint: { max: 1024, min: 464 },
		items: 1,
		slidesToSlide: 1 // optional, default to 1.
	},
	mobile: {
		breakpoint: { max: 464, min: 0 },
		items: 1,
		slidesToSlide: 1 // optional, default to 1.
	}
};

// ================================|| MobileDevelopmentPortfolio ||================================ //

const MobileDevelopmentPortfolio = () => {

	return (
		<>
			<Helmet>
				<title>Mobile Development Portfolio - The Bridge Code</title>
			</Helmet>
			<div className="container-fluid p-0">
				<div className="position-relative overflow-hidden text-center pro_web">
					<div className="col-md-7 p-lg-5 mx-auto ">
						<h1 className="display-2 fw-bolder text-dark pb-4 title-c">Mobile Development Portfolio</h1>
						<p className="lead fw-normal text-dark">We love to see your growth with us</p>
					</div>
				</div>

				<div className="container-fluid py-5" >
					<div className="d-md-flex p-md-0 m-md-0 justify-content-center">
						<div className="col-md-5 order-2">
							<div className="d-md-flex mb-5 ps-md-0 ">
								<div className="smartphone m-md-auto">
									{/* ml-0 me-5 mr-auto mr-md-auto ml-md-auto mr-sm-auto ml-lg-auto mr-lg-0 ml-sm-0 mt-md-5 mt-lg-0 */}
									<div className='bg-light'>
										<div className='single-welcome-slide-wrapper'>
											<Carousel
												swipeable={true}
												draggable={false}
												showDots={false}
												responsive={responsive}
												ssr={true} // means to render carousel on server-side.
												infinite={true}
												autoPlay={true}
												autoPlaySpeed={3000}
												keyBoardControl={true}
												customTransition="all 1.5"
												transitionDuration={500}
												containerClass="carousel-container"
												removeArrowOnDeviceType={["tablet", "mobile"]}
											>
												<Card className='mx-auto phone'>
													<Card.Img variant="top" src="https://res.cloudinary.com/dm1muiikf/image/upload/v1684484853/website/porfolio/web_portfolio/1.png" />
												</Card>
												<Card className='mx-auto phone'>
													<Card.Img variant="top" src="https://res.cloudinary.com/dm1muiikf/image/upload/v1684484853/website/porfolio/web_portfolio/2.png" />
												</Card>
												<Card className='mx-auto phone'>
													<Card.Img variant="top" src="https://res.cloudinary.com/dm1muiikf/image/upload/v1684484853/website/porfolio/web_portfolio/3.png" />
												</Card>
												<Card className='mx-auto phone'>
													<Card.Img variant="top" src="https://res.cloudinary.com/dm1muiikf/image/upload/v1684484853/website/porfolio/web_portfolio/4.png" />
												</Card>
												<Card className='mx-auto phone'>
													<Card.Img variant="top" src="https://res.cloudinary.com/dm1muiikf/image/upload/v1684484853/website/porfolio/web_portfolio/5.png" />
												</Card>
												<Card className='mx-auto phone'>
													<Card.Img variant="top" src="https://res.cloudinary.com/dm1muiikf/image/upload/v1684484853/website/porfolio/web_portfolio/6.png" />
												</Card>
												<Card className='mx-auto phone'>
													<Card.Img variant="top" src="https://res.cloudinary.com/dm1muiikf/image/upload/v1684484853/website/porfolio/web_portfolio/7.png" />
												</Card>
												<Card className='mx-auto phone'>
													<Card.Img variant="top" src="https://res.cloudinary.com/dm1muiikf/image/upload/v1684484853/website/porfolio/web_portfolio/8.png" />
												</Card>
												<Card className='mx-auto phone'>
													<Card.Img variant="top" src="https://res.cloudinary.com/dm1muiikf/image/upload/v1684484853/website/porfolio/web_portfolio/9.png" />
												</Card>
												<Card className='mx-auto phone'>
													<Card.Img variant="top" src="https://res.cloudinary.com/dm1muiikf/image/upload/v1684484853/website/porfolio/web_portfolio/10.png" />
												</Card>
												<Card className='mx-auto phone'>
													<Card.Img variant="top" src="https://res.cloudinary.com/dm1muiikf/image/upload/v1684484853/website/porfolio/web_portfolio/11.png" />
												</Card>
											</Carousel>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div className="col-md-6 align-self-center ps-md-1 mt-4 mt-md-0 m-md-0 ms-md-5">
							<div className='sky_wrap'>
								<h2 className="mb-4 display-4 fw-bold">Snowmart</h2>
								<p>Snowmart is a one-stop supermarket chain that aims to offer customers a wide range of basic home and personal products under one roof. Each Snowmart store
									stocks home utility products - including food, toiletries, beauty products, garments, kitchenware, bed and bath linen, home appliances and more - available at
									competitive prices that our customers appreciate. Our core objective is to offer customers good products at great value.
								</p>
								<div className='row'>
									<h3 className='fs-2 fw-bold'>Features</h3>
									<ul className='col-md-6'>
										<li className="list-group-item p-1 fs-5"><BsFillHandThumbsUpFill /> Login/Signup</li>
										<li className="list-group-item p-1 fs-5"><BsFillHandThumbsUpFill /> Home</li>
										<li className="list-group-item p-1 fs-5"><BsFillHandThumbsUpFill /> Categories</li>
										<li className="list-group-item p-1 fs-5"><BsFillHandThumbsUpFill /> Product List</li>
										<li className="list-group-item p-1 fs-5"><BsFillHandThumbsUpFill /> Product Detail Page</li>
										<li className="list-group-item p-1 fs-5"><BsFillHandThumbsUpFill /> Search Functionality</li>
										<li className="list-group-item p-1 fs-5"><BsFillHandThumbsUpFill /> Add to Cart Functionality</li>
									</ul>
									<ul className='col-md-6'>
										<li className="list-group-item p-1 fs-5"><BsFillHandThumbsUpFill /> Payment Integration</li>
										<li className="list-group-item p-1 fs-5"><BsFillHandThumbsUpFill /> Profile</li>
										<li className="list-group-item p-1 fs-5"><BsFillHandThumbsUpFill /> My Account</li>
										<li className="list-group-item p-1 fs-5"><BsFillHandThumbsUpFill /> Add Multiple Address</li>
										<li className="list-group-item p-1 fs-5"><BsFillHandThumbsUpFill /> My Orders</li>
										<li className="list-group-item p-1 fs-5"><BsFillHandThumbsUpFill /> Checkout</li>
										<li className="list-group-item p-1 fs-5"><BsFillHandThumbsUpFill /> Signout</li>
										{/* <li></li> */}
									</ul>
								</div>
								<div className='col-md-4 mx-auto text-center'>
									<a href='https://play.google.com/store/apps/details?id=com.snowmartonline.com' target={'_blank'}>
										<img src='https://res.cloudinary.com/dm1muiikf/image/upload/v1684484853/website/porfolio/web_portfolio/google.png' className='img-fluid text-center' sizes={50} alt='mobile Web Portfolio' />
									</a>
								</div>
							</div>
						</div>
					</div>
				</div>

				<div className="container-fluid py-5 mobile_web" >
					<div className="d-md-flex p-md-0 m-md-0 justify-content-center">
						<div className="col-md-5">
							<div className="d-md-flex mb-5 ps-md-0 ">
								<div className="smartphone m-md-auto mx-auto">
									{/* ml-0 me-5 mr-auto mr-md-auto ml-md-auto mr-sm-auto ml-lg-auto mr-lg-0 ml-sm-0 mt-md-5 mt-lg-0 */}
									<div className='bg-light'>
										<div className='single-welcome-slide-wrapper'>
											<Carousel
												swipeable={true}
												draggable={false}
												showDots={false}
												responsive={responsive}
												ssr={true} // means to render carousel on server-side.
												infinite={true}
												autoPlay={true}
												autoPlaySpeed={3000}
												keyBoardControl={true}
												customTransition="all 1.5"
												transitionDuration={500}
												containerClass="carousel-container"
												removeArrowOnDeviceType={["tablet", "mobile"]}
											>

												<Card className='mx-auto phone' >
													<Card.Img variant="top" src="https://res.cloudinary.com/dm1muiikf/image/upload/v1684484853/website/porfolio/web_portfolio/A1.png" />
												</Card>
												<Card className='mx-auto phone'>
													<Card.Img variant="top" src="https://res.cloudinary.com/dm1muiikf/image/upload/v1684484853/website/porfolio/web_portfolio/A2.png" />
												</Card>
												<Card className='mx-auto phone' >
													<Card.Img variant="top" src="https://res.cloudinary.com/dm1muiikf/image/upload/v1684484853/website/porfolio/web_portfolio/A3.png" />
												</Card>
												<Card className='mx-auto phone' >
													<Card.Img variant="top" src="https://res.cloudinary.com/dm1muiikf/image/upload/v1684484853/website/porfolio/web_portfolio/A4.png" />
												</Card>
												<Card className='mx-auto phone' >
													<Card.Img variant="top" src="https://res.cloudinary.com/dm1muiikf/image/upload/v1684484853/website/porfolio/web_portfolio/A5.png" />
												</Card>
												<Card className='mx-auto phone' >
													<Card.Img variant="top" src="https://res.cloudinary.com/dm1muiikf/image/upload/v1684484853/website/porfolio/web_portfolio/A6.png" />
												</Card>
												<Card className='mx-auto phone' >
													<Card.Img variant="top" src="https://res.cloudinary.com/dm1muiikf/image/upload/v1684484853/website/porfolio/web_portfolio/A7.png" />
												</Card>
												<Card className='mx-auto phone' >
													<Card.Img variant="top" src="https://res.cloudinary.com/dm1muiikf/image/upload/v1684484853/website/porfolio/web_portfolio/A8.png" />
												</Card>
												<Card className='mx-auto phone' >
													<Card.Img variant="top" src="https://res.cloudinary.com/dm1muiikf/image/upload/v1684484853/website/porfolio/web_portfolio/A9.png" />
												</Card>
												<Card className='mx-auto phone' >
													<Card.Img variant="top" src="https://res.cloudinary.com/dm1muiikf/image/upload/v1684484853/website/porfolio/web_portfolio/A10.png" />
												</Card>
											</Carousel>
										</div>
									</div>
								</div>
							</div>
						</div>

						<div className="col-md-6 align-self-center ps-md-1 mt-4 mt-md-0 m-md-0 me-md-5">
							<div className='sky_wrap'>
								<h2 className="mb-4 display-4 fw-bold">Mrk's Liquor</h2>
								<p>
									Mr Ks liquor sells best in craft beer, whiskey, and other beverages for store pick up. Get access to recommendations by Mike (craft beer and whiskey
									expert) on the highest rated beer/whiskey and hard to find beers and whiskey from all-independent brewers from USA. Download and learn about the various
									craft beer and stay notified on rare beer availabilities. You can also pickup your favorites once your account is setup. You must be 21 years old to use
									this app and make purchase. You must provide valid ID when picking up your order.
								</p>
								<div className='row'>
									<h3 className='fs-2 fw-bold'>Features</h3>
									<ul className='col-md-6'>
										<li className="list-group-item p-1 fs-5"><BsFillHandThumbsUpFill /> Login/Signup</li>
										<li className="list-group-item p-1 fs-5"><BsFillHandThumbsUpFill /> Login with Social media</li>
										<li className="list-group-item p-1 fs-5"><BsFillHandThumbsUpFill /> View New Arrivals</li>
										<li className="list-group-item p-1 fs-5"><BsFillHandThumbsUpFill /> Category</li>
										<li className="list-group-item p-1 fs-5"><BsFillHandThumbsUpFill /> Product List</li>
										<li className="list-group-item p-1 fs-5"><BsFillHandThumbsUpFill /> Product Detail Page</li>
										<li className="list-group-item p-1 fs-5"><BsFillHandThumbsUpFill /> Share Functionality</li>
										<li className="list-group-item p-1 fs-5"><BsFillHandThumbsUpFill /> My Account</li>
										<li className="list-group-item p-1 fs-5"><BsFillHandThumbsUpFill /> Profile</li>
									</ul>
									<ul className='col-md-6'>
										<li className="list-group-item p-1 fs-5"><BsFillHandThumbsUpFill /> Settings</li>
										<li className="list-group-item p-1 fs-5"><BsFillHandThumbsUpFill /> Add to Cart</li>
										<li className="list-group-item p-1 fs-5"><BsFillHandThumbsUpFill /> Wishlist</li>
										<li className="list-group-item p-1 fs-5"><BsFillHandThumbsUpFill /> Checkout</li>
										<li className="list-group-item p-1 fs-5"><BsFillHandThumbsUpFill /> Add Address</li>
										<li className="list-group-item p-1 fs-5"><BsFillHandThumbsUpFill /> Mike's Review</li>
										<li className="list-group-item p-1 fs-5"><BsFillHandThumbsUpFill /> Payment Integration</li>
										<li className="list-group-item p-1 fs-5"><BsFillHandThumbsUpFill /> Sign-out</li>
										{/* <li></li> */}
									</ul>
								</div>
								<div className='row mx-auto text-center'>
									<div className='col-md-4'>
										<a href='https://play.google.com/store/apps/details?id=com.apps.liquor' target={'_blank'}>
											<img src='https://res.cloudinary.com/dm1muiikf/image/upload/v1684484853/website/porfolio/web_portfolio/google.png' className='img-fluid text-center' sizes={50} alt='mobile Web Portfolio'/>
										</a>
									</div>
									<div className='col-md-4'>
										<a href='https://apps.apple.com/us/app/mrks-liquor/id1490689297' target={'_blank'}>
											<img src='https://res.cloudinary.com/dm1muiikf/image/upload/v1684484853/website/porfolio/web_portfolio/ios.png' className='img-fluid text-center' sizes={50} alt='mobile Web Portfolio'/>
										</a>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default MobileDevelopmentPortfolio;