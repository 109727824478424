import React from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

// ================================|| CaseStudy ||================================ //

const CaseStudy = () => {
    return (
        <>
            <Helmet>
				<title>Case Study - The Bridge Code</title>
			</Helmet>
            <div className='container-fluid p-0'>
                <div className="position-relative overflow-hidden text-center casestudy">
                    <div className="col-md-7 p-lg-5 mx-auto ">
                        <p className="display-2 fw-bolder text-dark pb-4 title-c">Case Study</p>
                        <p className="lead fw-normal text-dark px-5">We love to see your growth with us</p>
                        <Link to='/contact-us' class="cl-button -primary -big rounded-3">Contact Us</Link>
                    </div>
                </div>
                <div className="container-fluid">
                    <div className="col-md-12">
                        <div className='container title-c '>
                            <p className='display-3 fw-bold'>Industries In Focus</p>
                            <p className='mx-auto px-md-5 '>We are a one-stop solution for companies looking to outsource software development services. Being a trusted software development outsourcing company, we assist
                                clients from different industries in all phases of their software development needs.</p>
                        </div>
                        <div className="row pt-5 text-center pb-5">
                            <div className="col-md-3 mw-100 py-2">
                                <Link to='/case-study/supplychain' >
                                    <img src='https://res.cloudinary.com/dm1muiikf/image/upload/v1684484853/website/logistic.jpg' className='caseimg' alt="logistic" />
                                </Link>
                                <p className="fs-5 fw-bold casetext">Supply Chain & Logistics</p>
                            </div>
                            <div className="col-md-3 mw-100 py-2">
                                <Link to='/case-study/travel'>
                                    <img src='https://res.cloudinary.com/dm1muiikf/image/upload/v1684484853/website/travel_tourism.png' className='img-fluid caseimg ' alt="Travel Tourism" />
                                </Link>
                                <p className="fs-5 fw-bold casetext">Travel & Hospitality</p>
                            </div>
                            <div className="col-md-3 mw-100 py-2">
                                <Link to='/case-study/hitech'>
                                    <img src='https://res.cloudinary.com/dm1muiikf/image/upload/v1684484853/website/hitech.jpg' className='img-fluid caseimg' alt="Hitech" />
                                </Link>
                                <p className="fs-5 fw-bold casetext">Hitech</p>
                            </div>
                            <div className="col-md-3 mw-100 py-2">
                                <Link to='/case-study/healthcare'>
                                    <img src='https://res.cloudinary.com/dm1muiikf/image/upload/v1684484853/website/medical.jpg' className='img-fluid caseimg' alt="Medical" />
                                </Link>
                                <p className="fs-5 fw-bold casetext">Healthcare & Life Science</p>
                            </div>
                            <div className="col-md-3 mw-100 py-2">
                                <Link to='/case-study/digitalretail'>
                                    <img src='https://res.cloudinary.com/dm1muiikf/image/upload/v1684484853/website/digital_retail.jpg' className='img-fluid caseimg' alt="Digital Retail" />
                                </Link>
                                <p className="fs-5 fw-bold casetext">Digital Retail</p>
                            </div>
                            <div className="col-md-3 mw-100 py-2">
                                <Link to='/case-study/fintech'>
                                    <img src='https://res.cloudinary.com/dm1muiikf/image/upload/v1684484853/website/fintech.jpg' className='img-fluid caseimg' alt="Fintech" />
                                </Link>
                                <p className="fs-5 fw-bold casetext">Fintech</p>
                            </div>
                            <div className="col-md-3 mw-100 py-2">
                                <Link to='/case-study/mediaadvertising'>
                                    <img src='https://res.cloudinary.com/dm1muiikf/image/upload/v1684484853/website/media_entertainment.png' className='img-fluid caseimg' alt="Media E  ntertainment" />
                                </Link>
                                <p className="fs-5 fw-bold casetext">Media & Advertising</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default CaseStudy;