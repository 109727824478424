import React from 'react';
import Card from 'react-bootstrap/Card';
import Carousel from 'react-multi-carousel';
import { Link } from 'react-router-dom';
import { scrollToTop } from "../utils/utilityFunctions";
import { FaInstagram, FaSkype, FaLinkedin, FaWhatsapp, FaFacebook, FaTwitter } from "react-icons/fa";

// ================================|| Footer ||================================ //

const responsive = {
    desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 7
    },
    tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 3,
        slidesToSlide: 3 // optional, default to 1.
    },
    mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 1,
        slidesToSlide: 1 // optional, default to 1.
    }
};

const handleNavLinkClick = ()=>{
    scrollToTop()
    const navbarToggle = document.querySelector('.navbar-toggler');
    if (navbarToggle && window.getComputedStyle(navbarToggle).display !== 'none' && (navbarToggle.getAttribute('aria-expanded') == "true")) {
        navbarToggle.click();
    }
}

const Footer = () => {
    return (
        <>
            <div className='container text-center align-item-middle'>
                <div className='col-md-12 row mx-auto pt-2'>
                    <hr />
                    <Carousel
                        swipeable={true}
                        draggable={false}
                        showDots={true}
                        responsive={responsive}
                        ssr={true} // means to render carousel on server-side.
                        infinite={true}
                        autoPlay={true}
                        autoPlaySpeed={3000}
                        keyBoardControl={true}
                        customTransition="all .5"
                        transitionDuration={500}
                        containerClass="carousel-container p-0"
                        removeArrowOnDeviceType={["tablet", "mobile", "desktop"]}
                        dotListClass="custom-dot-list-style"
                        itemClass="align-self-center"
                        height="600px"
                    >
                        <div>
                            <div>
                                <Card.Img variant="top" src="https://res.cloudinary.com/dm1muiikf/image/upload/v1684484853/website/footer_p/goodfirms.png" style={{width: 140}} alt="Testimonials" />
                            </div>
                        </div>
                        <div>
                            <div>
                                <Card.Img variant="top" src="https://res.cloudinary.com/dm1muiikf/image/upload/v1684484853/website/footer_p/great_place_to_work.png" style={{width: 140}} alt="Testimonials" />
                            </div>
                        </div>
                        <div>
                            <div>
                                <Card.Img variant="top" src="https://res.cloudinary.com/dm1muiikf/image/upload/v1684484853/website/footer_p/cmmi.png" style={{width: 145}} alt="Testimonials" />
                            </div>
                        </div>
                        <div>
                            <div>
                                <Card.Img variant="top" src="https://res.cloudinary.com/dm1muiikf/image/upload/v1684484853/website/footer_p/iso_9001_2015.png" style={{width: 140}} alt="Testimonials" />
                            </div>
                        </div>
                        <div>
                            <div>
                                <Card.Img variant="top" src="https://res.cloudinary.com/dm1muiikf/image/upload/v1684484853/website/footer_p/iso_27001.png" style={{width: 140}} alt="Testimonials" />
                            </div>
                        </div>
                        <div>
                            <div>
                                <Card.Img variant="top" src="https://res.cloudinary.com/dm1muiikf/image/upload/v1684484853/website/footer_p/clutch.png" style={{width: 140}} alt="Testimonials" />
                            </div>
                        </div>
                    </Carousel>
                    {/* <div className='col-md-2 col-sm-4 col-6 align-self-center'><img src='https://res.cloudinary.com/dm1muiikf/image/upload/v1684484853/website/footer_p/goodfirms.png' className='img-fluid' width={140} alt="goodfirms" /></div>
                    <div className='col-md-2 col-sm-4 col-6 align-self-center'><img src='https://res.cloudinary.com/dm1muiikf/image/upload/v1684484853/website/footer_p/great_place_to_work.png' className='img-fluid' width={140} alt="great-place-to-work" /></div>
                    <div className='col-md-2 col-sm-4 col-6 align-self-center'><img src='https://res.cloudinary.com/dm1muiikf/image/upload/v1684484853/website/footer_p/cmmi.png' className='img-fluid' width={145} alt="cmmi" /></div>
                    <div className='col-md-2 col-sm-4 col-6 align-self-center'><img src='https://res.cloudinary.com/dm1muiikf/image/upload/v1684484853/website/footer_p/iso_9001_2015.png' className='img-fluid' width={140} alt="iso_9001_2015" /></div>
                    <div className='col-md-2 col-sm-4 col-6 align-self-center'><img src='https://res.cloudinary.com/dm1muiikf/image/upload/v1684484853/website/footer_p/iso_27001.png' className='img-fluid' width={140} alt="iso_27001" /></div>
                    <div className='col-md-2 col-sm-4 col-6 align-self-center'><img src='https://res.cloudinary.com/dm1muiikf/image/upload/v1684484853/website/footer_p/clutch.png' className='img-fluid' width={140} alt="clutch" /></div> */}
                    <hr />
                </div>
            </div>
            <footer className="footer-99382 pt-5">
                <div className="container">
                    <div className="row text-white">
                        <div className="col-md-4 pr-md-5">
                            <Link to="/" onClick={handleNavLinkClick}><img src="https://res.cloudinary.com/dm1muiikf/image/upload/v1684484853/website/Footer_logo.png" className="img-fluid pb-4" alt="Thee BridgeCode" style={{ width: "250px" }} /> </Link>
                            <p>We believe in Planning, Managing and Executing Idea's with utmost Priority.</p>
                            <div>
                                <div className='col-md-12'>
                                    <a href="//www.dmca.com/Protection/Status.aspx?ID=11919ec9-e908-4342-9f40-d7c46c35af4d" title="DMCA.com Protection Status" className="dmca-badge mx-1">
                                        <img src="https://images.dmca.com/Badges/dmca_protected_sml_120l.png?ID=11919ec9-e908-4342-9f40-d7c46c35af4d" alt="DMCA.com Protection Status" />
                                    </a>
                                    <script src="https://images.dmca.com/Badges/DMCABadgeHelper.min.js"> </script>
                                    <a href="http://www.copyscape.com/">
                                        <img src="//banners.copyscape.com/img/copyscape-banner-white-200x25.png" width="200" height="25" border="0" alt="Protected by Copyscape" title="Protected by Copyscape - Do not copy content from this page." />
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-2">
                            <h3>Discover</h3>
                            <ul className="list-unstyled nav-links">
                                <li><Link to="/" className='text-decoration-none' onClick={handleNavLinkClick}>Home</Link></li>
                                <li><Link to="/about-us" className='text-decoration-none' onClick={handleNavLinkClick}>About Us</Link></li>
                                <li><Link to="/portfolio" className='text-decoration-none' onClick={handleNavLinkClick}>Portfolio</Link></li>
                                <li><Link to="/services" className='text-decoration-none' onClick={handleNavLinkClick}>Services</Link></li>
                                <li><Link to="/contact-us" className='text-decoration-none' onClick={handleNavLinkClick}>Contact Us</Link></li>
                            </ul>
                        </div>
                        <div className="col-md-2">
                            <h3>About</h3>
                            <ul className="list-unstyled nav-links">
                                <li><Link to="/solution" className='text-decoration-none' onClick={handleNavLinkClick}>Solution</Link></li>
                                <li><Link to="/case-study" className='text-decoration-none' onClick={handleNavLinkClick}>Case Study</Link></li>
                                <li><Link to="/career" className='text-decoration-none' onClick={handleNavLinkClick}>Career</Link></li>
                                <li><Link to="/blogs" className='text-decoration-none' onClick={handleNavLinkClick}>Blogs</Link></li>
                            </ul>
                        </div>
                        <div className="col-md-2">
                            <h3>Help</h3>
                            <ul className="list-unstyled nav-links">
                                <li><Link to="/login" className='text-decoration-none' onClick={handleNavLinkClick}>Log In</Link></li>
                                <li><Link to="/privacy-policy" className='text-decoration-none' onClick={handleNavLinkClick}>Privacy Policy</Link></li>
                                <li><Link to="/terms-conditions" className='text-decoration-none' onClick={handleNavLinkClick}>Terms &amp; Conditions</Link></li>
                                <li><Link to="/terms-of-use" className='text-decoration-none' onClick={handleNavLinkClick}>Terms of Uses</Link></li>
                            </ul>
                        </div>
                        <div className="col-md-2">
                            <h3>Follow Us</h3>
                            <ul className="row" style={{ listStyle: "none", marginLeft: 0, paddingLeft: 0 }}>
                                <li className='col p-1 my-1'><Link to="https://join.skype.com/invite/dqpa9PFcZ1aI" aria-label="Footer"><img src='https://res.cloudinary.com/dm1muiikf/image/upload/v1704114953/website/footer_p/icons8-skype.png' /></Link></li>
                                <li className='col p-1 my-1'><Link to="https://www.linkedin.com/company/the-bridge-code/" aria-label="Footer"><img src='https://res.cloudinary.com/dm1muiikf/image/upload/v1704114953/website/footer_p/icons8-linkedin.png' /></Link></li>
                                <li className='col p-1 my-1'><Link to="https://www.facebook.com/thebridgecode/" aria-label="Footer"><img src='https://res.cloudinary.com/dm1muiikf/image/upload/v1704114953/website/footer_p/icons8-facebook.png' /></Link></li>
                                <li className='col p-1 my-1'><Link to="https://wa.me/message/2WJS2ZS4J2INL1" aria-label="Footer"><img src='https://res.cloudinary.com/dm1muiikf/image/upload/v1704114953/website/footer_p/icons8-whatsapp.png' /></Link></li>
                                <li className='col p-1 my-1'><Link to="https://www.instagram.com/thebridgecode/" aria-label="Footer"><img src='https://res.cloudinary.com/dm1muiikf/image/upload/v1704114953/website/footer_p/icons8-instagram.png' /></Link></li>
                                <li className='col p-1 my-1'><Link to="https://twitter.com/thebridgecode" aria-label="Footer"><img src='https://res.cloudinary.com/dm1muiikf/image/upload/v1704114953/website/footer_p/icons8-twitterx.png' /></Link></li>
                                <li className='col p-1 my-1'><Link to="https://t.snapchat.com/sYP1935x" aria-label="Footer"><img src='https://res.cloudinary.com/dm1muiikf/image/upload/v1704716673/website/footer_p/icons8-snapchat.png' height={42} width={42} /></Link></li>
                            </ul>
                        </div>
                    </div>

                    <div className="row align-items-center mx-auto">
                        <div className="col-12 text-center">
                            <div className="copyright mt-3">
                                <p><small>&copy; 2023 Bridgecode Technologies L.L.P. All rights Reserved.</small></p>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        </>
    );
};

export default Footer;