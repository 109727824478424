import React from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

// ================================|| Fintech ||================================ //

const Fintech = () => {
	return (
		<>
			<Helmet>
				<title>Fintech - The Bridge Code</title>
			</Helmet>
			<div className='container-fluid p-0 '>
				<div class="position-relative overflow-hidden text-center supplychain">
					<div class="col-md-5 p-lg-5 mx-auto my-1">
						<h1 class="display-2 fw-bolder text-white title-c">Fintech Case Study</h1>
						<Link to='/contact-us' class="cl-button -primary -big rounded-3">Contact Us</Link>
					</div>
				</div>

				<div className="container">
					<div>
						<h4 className="text-h4">The Goal</h4>
						<p>A Bank needed a Debt Collection platform for business processes implementation, the Current system they were using was a Legacy system and was Outdated.</p>
					</div>

					<div>
						<h4 className="text-h4">Challenges</h4>
						<p>The main challenge was to create a system that would be flexible to the process changes and transfer the Current Data from the Old system to the Newly
							developed Software. The team had to review many different options before choosing the right Architecture, schema and Technology before starting the Development.
						</p>
					</div>

					<div>
						<h4 className="text-h4">Solution</h4>
						<p>In order for the solution to be as flexible as possible, in about Eight months, we designed a system that was based on Current Market Trends using Artificial
							Intelligence. The Below process describe and complete the necessary tasks and processes :
						</p>
					</div>

					<section className="steps steps-area">
						<div className="container">
							<ul className="timelines">
								<li className="timelines-box wow fadeInLeft">
									<i className="icon-layers"></i>
									<p className="timelines-title"> Automate revenue processes</p>
									<p className="timelines-details">Automating payment processes by accepting credit cards and other payment options and setting up quick pay landing
										pages have increased the level of ease for customers.</p>
									<span className="deee">1</span>
								</li>
								<li className="timelines-box wow fadeInRight">
									<i className="icon-chat"></i>
									<p className="timelines-title">Centralized debt collection</p>
									<p className="timelines-details">Debt collection software centralizes all the accounts in one place and enabled the bank to create a more efficient
										process. As all data is accessible in one place, It helps in decision-making and overseeing receivables, easily consolidate debts and eradicate
										redundant activities.</p>
									<span className="deee">2</span>
								</li>
								<li className="timelines-box  wow fadeInLeft">
									<i className="icon-tools"></i>
									<p className="timelines-title">It helps mitigate debt problems before they arise</p>
									<p className="timelines-details">Before an account enters the collections cycle, debt collection software is using Artificial Intelligence and
										behavioural insights to score customers and recognize potential risks</p>
									<span className="deee">3</span>
								</li>
								<li className="timelines-box wow fadeInRight">
									<i className="icon-trophy"></i>
									<p className="timelines-title">It enables to deploy the most effective communications strategies</p>
									<p className="timelines-details">As mentioned before, collating the data into one system, while harnessing artificial intelligence and the latest
										behavioural insights, will put you in a position to drive customer engagement by using the right communication channel. Further, with this data you
										will be able to test multiple channels in parallel, optimizing in real-time for the best strategies.</p>
									<span className="deee">4</span>
								</li>
								<li className="timelines-box wow fadeInRight">
									<i className="icon-chat"></i>
									<p className="timelines-title"> It’s scalable and adaptable</p>
									<p className="timelines-details">One of the most important elements of improving collections is having the ability to be adaptable. This software has
										the scalability to grow with Bank needs.</p>
									<span className="deee">5</span>
								</li>
							</ul>
						</div>
					</section>

					<div>
						<h4 className="text-h4">Results </h4>
						<p>By automating many of the processes involved in debt collection, the tool saves time, reduces manual labor, and improves efficiency. It also provides better
							communication, increased transparency, and customer experience.
						</p>
						<p>Additionally, using AI and behavioural insights in the tool enables debt collectors to develop better strategies for debt recovery. This Collection Product
							is a valuable tool for debt collectors, debtors, and institutions seeking to improve their debt recovery processes.</p>
						<p style={{ marginTop: "50px" }}></p>
					</div>

				</div >

				<div class="container-cs" id="case_study">
					<div class="circles-container">
						<div class="circles circleOne"></div>
						<div class="circles circleTwo"></div>
						<div class="circles circleThree"></div>
						<div class="circles circleFour"></div>
						<div class="logo">FinTech Case Study</div>
					</div>
					<svg width="1230" height="820" viewBox="0 0 1230 820" fill="none" xmlns="http://www.w3.org/2000/svg" class="mx-auto">
						<g opacity="0.15" class="line bottom bottomTwo">
							<path d="M615.5 661L615.5 497" stroke="#2378D5" stroke-width="12" />
						</g>
						<g opacity="0.15" class="line top topFour">
							<path d="M829 105L829 160.231C829 171.277 820.046 180.231 809 180.231L635 180.231C623.954 180.231 615 189.185 615 200.231L615 268" stroke="#2378D5" stroke-width="12" />
						</g>
						<g opacity="0.15" class="line top topThree">
							<path d="M615 104L615 268" stroke="#2378D5" stroke-width="12" />
						</g>
						<g opacity="0.15" class="line top topTwo">
							<path d="M402 106L402 160.769C402 171.815 410.954 180.769 422 180.769L595 180.769C606.046 180.769 615 189.724 615 200.769L615 268" stroke="#2378D5" stroke-width="12" />
						</g>
						<g opacity="0.15" class="line top topFive">
							<path d="M1041 105L1041 160.231C1041 171.276 1032.05 180.231 1021 180.231L635 180.231C623.954 180.231 615 189.185 615 200.231L615 268" stroke="#2378D5" stroke-width="12" />
						</g>
						<g opacity="0.15" class="line top topOne">
							<path d="M190 106L190 160.769C190 171.815 198.954 180.769 210 180.769L594.503 180.769C605.549 180.769 614.503 189.724 614.503 200.769L614.503 268" stroke="#2378D5" stroke-width="12" />
						</g>
						<g class="dots top dotsOne">
							<path d="M190 106L190 160.769C190 171.815 198.954 180.769 210 180.769L594.503 180.769C605.549 180.769 614.503 189.724 614.503 200.769L614.503 268" stroke="#2378D5" stroke-width="4" />
						</g>
						<g class="dots top dotsFive">
							<path d="M1041 105L1041 160.231C1041 171.276 1032.05 180.231 1021 180.231L635 180.231C623.954 180.231 615 189.185 615 200.231L615 268" stroke="#2378D5" stroke-width="4" />
						</g>
						<g class="dots top dotsTwo">
							<path d="M402 106L402 160.769C402 171.815 410.954 180.769 422 180.769L595 180.769C606.046 180.769 615 189.724 615 200.769L615 268" stroke="#2378D5" stroke-width="4" />
						</g>
						<g class="dots top dotsThree">
							<path d="M615 104L615 268" stroke="#2378D5" stroke-width="4" />
						</g>
						<g class="dots top dotsFour">
							<path d="M829 105L829 160.231C829 171.277 820.046 180.231 809 180.231L635 180.231C623.954 180.231 615 189.185 615 200.231L615 268" stroke="#2378D5" stroke-width="4" />
						</g>
						<g class="dots bottom dotsOne">
							<path d="M353.5 659.5L353.5 604.5C353.5 593.455 362.454 584.5 373.5 584.5L595 584.5C606.046 584.5 615 575.546 615 564.5L615 497" stroke="#2378D5" stroke-width="4" />
						</g>
						<g class="dots bottom dotsTwo">
							<path d="M615.5 661L615.5 497" stroke="#2378D5" stroke-width="4" />
						</g>
						<g class="dots bottom dotsThree">
							<path d="M877 659.5L877 604.5C877 593.454 868.046 584.5 857 584.5L635.5 584.5C624.454 584.5 615.5 575.545 615.5 564.5L615.5 497" stroke="#2378D5" stroke-width="4" />
						</g>
						<g filter="url(#filter0_d_484_1188)" class="block top topOne">
							<rect x="127" y="1" width="125" height="125" rx="6" fill="white" />
							<image x="150" y="23" width={85} xlinkHref="https://res.cloudinary.com/dm1muiikf/image/upload/v1684484853/website/csd/python.png" />
							<rect x="126.5" y="0.5" width="126" height="126" rx="6.5" stroke="#ADADAD" stroke-opacity="0.6" />
						</g>

						<g filter="url(#filter1_d_484_1188)" class="block top topTwo">
							<rect x="339.25" y="1" width="125" height="125" rx="6" fill="white" />
							<image x="343" y="1" width={115} xlinkHref="https://res.cloudinary.com/dm1muiikf/image/upload/v1684484853/website/csd/sql.png" />
							<rect x="338.75" y="0.5" width="126" height="126" rx="6.5" stroke="#ADADAD" stroke-opacity="0.6" />
						</g>
						<g filter="url(#filter2_d_484_1188)" class="block top topThree">
							<rect x="551.5" y="1" width="125" height="125" rx="6" fill="white" />
							<image x="565" y="23" width={95} xlinkHref="https://res.cloudinary.com/dm1muiikf/image/upload/v1684484853/website/csd/react.png" />
							<rect x="551" y="0.5" width="126" height="126" rx="6.5" stroke="#ADADAD" stroke-opacity="0.6" />
						</g>
						<g filter="url(#filter3_d_484_1188)" class="block top topFour">
							<rect x="763.75" y="1" width="125" height="125" rx="6" fill="white" />
							<image x="773" y="23" width={105} xlinkHref="https://res.cloudinary.com/dm1muiikf/image/upload/v1684484853/website/csd/javascript.png" />
							<rect x="763.25" y="0.5" width="126" height="126" rx="6.5" stroke="#ADADAD" stroke-opacity="0.6" />
						</g>
						<g filter="url(#filter4_d_484_1188)" class="block top topFive">
							<rect x="976" y="1" width="125" height="125" rx="6" fill="white" />
							<image x="975" y="18" width={135} xlinkHref="https://res.cloudinary.com/dm1muiikf/image/upload/v1690872418/website/case_study/azure_sso.png" />
							<rect x="975.5" y="0.5" width="126" height="126" rx="6.5" stroke="#ADADAD" stroke-opacity="0.6" />
						</g>
						<g filter="url(#filter6_d_484_1188)" class="block bottom bottomTwo">
							<rect x="525" y="640" width="180" height="176" rx="6" fill="white" />
							<image x="528" y="655" width={175} xlinkHref="https://res.cloudinary.com/dm1muiikf/image/upload/v1690872419/website/case_study/fintech.png" />
							<rect x="524.5" y="639.5" width="181" height="177" rx="6.5" stroke="#ADADAD" stroke-opacity="0.6" />
						</g>
						<defs>
							<filter id="filter0_d_484_1188" x="126" y="0" width="130" height="130" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
								<feFlood flood-opacity="0" result="BackgroundImageFix" />
								<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
								<feOffset dx="3" dy="3" />
								<feColorMatrix type="matrix" values="0 0 0 0 0.0340278 0 0 0 0 0.157404 0 0 0 0 0.291667 0 0 0 0.15 0" />
								<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_484_1188" />
								<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_484_1188" result="shape" />
							</filter>
							<filter id="filter1_d_484_1188" x="338.25" y="0" width="130" height="130" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
								<feFlood flood-opacity="0" result="BackgroundImageFix" />
								<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
								<feOffset dx="3" dy="3" />
								<feColorMatrix type="matrix" values="0 0 0 0 0.0340278 0 0 0 0 0.157404 0 0 0 0 0.291667 0 0 0 0.15 0" />
								<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_484_1188" />
								<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_484_1188" result="shape" />
							</filter>
							<filter id="filter2_d_484_1188" x="550.5" y="0" width="130" height="130" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
								<feFlood flood-opacity="0" result="BackgroundImageFix" />
								<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
								<feOffset dx="3" dy="3" />
								<feColorMatrix type="matrix" values="0 0 0 0 0.0340278 0 0 0 0 0.157404 0 0 0 0 0.291667 0 0 0 0.15 0" />
								<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_484_1188" />
								<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_484_1188" result="shape" />
							</filter>
							<filter id="filter3_d_484_1188" x="762.75" y="0" width="130" height="130" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
								<feFlood flood-opacity="0" result="BackgroundImageFix" />
								<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
								<feOffset dx="3" dy="3" />
								<feColorMatrix type="matrix" values="0 0 0 0 0.0340278 0 0 0 0 0.157404 0 0 0 0 0.291667 0 0 0 0.15 0" />
								<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_484_1188" />
								<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_484_1188" result="shape" />
							</filter>
							<filter id="filter4_d_484_1188" x="975" y="0" width="130" height="130" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
								<feFlood flood-opacity="0" result="BackgroundImageFix" />
								<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
								<feOffset dx="3" dy="3" />
								<feColorMatrix type="matrix" values="0 0 0 0 0.0340278 0 0 0 0 0.157404 0 0 0 0 0.291667 0 0 0 0.15 0" />
								<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_484_1188" />
								<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_484_1188" result="shape" />
							</filter>
							<filter id="filter5_d_484_1188" x="259" y="639" width="185" height="181" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
								<feFlood flood-opacity="0" result="BackgroundImageFix" />
								<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
								<feOffset dx="3" dy="3" />
								<feColorMatrix type="matrix" values="0 0 0 0 0.0340278 0 0 0 0 0.157404 0 0 0 0 0.291667 0 0 0 0.15 0" />
								<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_484_1188" />
								<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_484_1188" result="shape" />
							</filter>
							<filter id="filter6_d_484_1188" x="524" y="639" width="185" height="181" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
								<feFlood flood-opacity="0" result="BackgroundImageFix" />
								<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
								<feOffset dx="3" dy="3" />
								<feColorMatrix type="matrix" values="0 0 0 0 0.0340278 0 0 0 0 0.157404 0 0 0 0 0.291667 0 0 0 0.15 0" />
								<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_484_1188" />
								<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_484_1188" result="shape" />
							</filter>
							<filter id="filter7_d_484_1188" x="789" y="639" width="185" height="181" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
								<feFlood flood-opacity="0" result="BackgroundImageFix" />
								<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
								<feOffset dx="3" dy="3" />
								<feColorMatrix type="matrix" values="0 0 0 0 0.0340278 0 0 0 0 0.157404 0 0 0 0 0.291667 0 0 0 0.15 0" />
								<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_484_1188" />
								<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_484_1188" result="shape" />
							</filter>
						</defs>
					</svg>
				</div>

				{/* **********************  Mobile view casestudy ********************** */}
				<div class="" id="casestudy">
					<div id="demo">
						<svg viewBox="0 0 630 1630" fill="none" xmlns="http://www.w3.org/2000/svg" class="mt-5">
							<g opacity="0.15" class="line bottom bottomTwo">
								<path d="M315 104L315 1468" stroke="#2378D5" stroke-width="12" />
							</g>
							<g opacity="0.15" class="line top topTwo">
								<path d="M315 104L315 1468" stroke="#2378D5" stroke-width="12" />
							</g>
							<g class="dots top dotsTwo">
								<path d="M315 104L315 468" stroke="#2378D5" stroke-width="4" />
							</g>
							<g class="dots top dotsThree">
								<path d="M315 104L315 468" stroke="#2378D5" stroke-width="4" />
							</g>
							<g class="dots top dotsFour">
								<path d="M315 104L315 468" stroke="#2378D5" stroke-width="4" />
							</g>
							<g class="dots bottom dotsOne">
								<path d="M315 661L315.5 1297" stroke="#2378D5" stroke-width="4" />
							</g>
							<g class="dots bottom dotsTwo">
								<path d="M315 661L315.5 1297" stroke="#2378D5" stroke-width="4" />
							</g>
							<g class="dots bottom dotsThree">
								<path d="M315 661L315.5 1297" stroke="#2378D5" stroke-width="4" />
							</g>
							<g class="block top topOne">
								<rect x="250" y="1" width="125" height="125" rx="6" fill="white" />
								<image x="255" y="15" width={111} xlinkHref="https://res.cloudinary.com/dm1muiikf/image/upload/v1684484853/website/csd/python.png" />
								<rect x="250.5" y="0.5" width="126" height="126" rx="6.5" stroke="#ADADAD" stroke-opacity="0.6" />
							</g>
							<g class="block top topTwo">
								<rect x="250" y="228" width="125" height="125" rx="6" fill="white" />
								<image x="256" y="229" width={115} xlinkHref="https://res.cloudinary.com/dm1muiikf/image/upload/v1684484853/website/csd/sql.png" />
								<rect x="250.5" y="228.5" width="126" height="126" rx="6.5" stroke="#ADADAD" stroke-opacity="0.6" />
							</g>
							<g class="block top topThree">
								<rect x="250" y="455" width="125" height="125" rx="6" fill="white" />
								<image x="259" y="459" width={105} xlinkHref="https://res.cloudinary.com/dm1muiikf/image/upload/v1684484853/website/csd/react.png" />
								<rect x="250.5" y="455.5" width="126" height="126" rx="6.5" stroke="#ADADAD" stroke-opacity="0.6" />
							</g>
							<g class="block top topFour">
								<rect x="250" y="682" width="125" height="125" rx="6" fill="white" />
								<image x="251" y="695" width={115} xlinkHref="https://res.cloudinary.com/dm1muiikf/image/upload/v1684484853/website/csd/javascript.png" />
								<rect x="250.25" y="682.5" width="126" height="126" rx="6.5" stroke="#ADADAD" stroke-opacity="0.6" />
							</g>
							<g class="block top topFive">
								<rect x="250" y="909" width="125" height="125" rx="6" fill="white" />
								<image x="250" y="920" width={135} xlinkHref="https://res.cloudinary.com/dm1muiikf/image/upload/v1690872418/website/case_study/azure_sso.png" />
								<rect x="250.5" y="909.5" width="126" height="126" rx="6.5" stroke="#ADADAD" stroke-opacity="0.6" />
							</g>
							<svg id="svg" viewBox="0 0 320 1" width="71%" >
								<circle class="background" cx="220" cy="290" r="35" stroke="" fill="#0051AA" />
								<circle class="background" cx="220" cy="290" r="75" stroke="" fill="#B3D1EF" />
								<circle class="background" cx="220" cy="290" r="55" stroke="" fill="#0051AA" />
								<foreignObject x="170" y="275" width="100" height="100">
									<div className="fs-6 text-white text-center">
										FinTech Case Study
									</div>
								</foreignObject>
								<circle class="background" cx="220" cy="290" r="95" stroke="" fill="rgba(0, 74, 171, 0.1)" />
							</svg>
							<g class="block bottom bottomTwo">
								<rect x="220" y="1436" width="180" height="176" rx="6" fill="white" />
								<image x="229" y="1457" width={175} xlinkHref="https://res.cloudinary.com/dm1muiikf/image/upload/v1690872419/website/case_study/fintech.png" />
								<rect x="220.5" y="1436.5" width="181" height="177" rx="6.5" stroke="#ADADAD" stroke-opacity="0.6" />
							</g>
							<defs>
								<filter id="filter0_d_484_1188" x="126" y="0" width="130" height="130" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
									<feFlood flood-opacity="0" result="BackgroundImageFix" />
									<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
									<feOffset dx="3" dy="3" />
									<feColorMatrix type="matrix" values="0 0 0 0 0.0340278 0 0 0 0 0.157404 0 0 0 0 0.291667 0 0 0 0.15 0" />
									<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_484_1188" />
									<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_484_1188" result="shape" />
								</filter>
								<filter id="filter1_d_484_1188" x="338.25" y="0" width="130" height="130" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
									<feFlood flood-opacity="0" result="BackgroundImageFix" />
									<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
									<feOffset dx="3" dy="3" />
									<feColorMatrix type="matrix" values="0 0 0 0 0.0340278 0 0 0 0 0.157404 0 0 0 0 0.291667 0 0 0 0.15 0" />
									<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_484_1188" />
									<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_484_1188" result="shape" />
								</filter>
								<filter id="filter2_d_484_1188" x="550.5" y="0" width="130" height="130" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
									<feFlood flood-opacity="0" result="BackgroundImageFix" />
									<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
									<feOffset dx="3" dy="3" />
									<feColorMatrix type="matrix" values="0 0 0 0 0.0340278 0 0 0 0 0.157404 0 0 0 0 0.291667 0 0 0 0.15 0" />
									<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_484_1188" />
									<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_484_1188" result="shape" />
								</filter>
								<filter id="filter3_d_484_1188" x="762.75" y="0" width="130" height="130" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
									<feFlood flood-opacity="0" result="BackgroundImageFix" />
									<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
									<feOffset dx="3" dy="3" />
									<feColorMatrix type="matrix" values="0 0 0 0 0.0340278 0 0 0 0 0.157404 0 0 0 0 0.291667 0 0 0 0.15 0" />
									<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_484_1188" />
									<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_484_1188" result="shape" />
								</filter>
								<filter id="filter4_d_484_1188" x="975" y="0" width="130" height="130" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
									<feFlood flood-opacity="0" result="BackgroundImageFix" />
									<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
									<feOffset dx="3" dy="3" />
									<feColorMatrix type="matrix" values="0 0 0 0 0.0340278 0 0 0 0 0.157404 0 0 0 0 0.291667 0 0 0 0.15 0" />
									<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_484_1188" />
									<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_484_1188" result="shape" />
								</filter>
								<filter id="filter5_d_484_1188" x="259" y="639" width="185" height="181" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
									<feFlood flood-opacity="0" result="BackgroundImageFix" />
									<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
									<feOffset dx="3" dy="3" />
									<feColorMatrix type="matrix" values="0 0 0 0 0.0340278 0 0 0 0 0.157404 0 0 0 0 0.291667 0 0 0 0.15 0" />
									<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_484_1188" />
									<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_484_1188" result="shape" />
								</filter>
								<filter id="filter6_d_484_1188" x="524" y="639" width="185" height="181" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
									<feFlood flood-opacity="0" result="BackgroundImageFix" />
									<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
									<feOffset dx="3" dy="3" />
									<feColorMatrix type="matrix" values="0 0 0 0 0.0340278 0 0 0 0 0.157404 0 0 0 0 0.291667 0 0 0 0.15 0" />
									<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_484_1188" />
									<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_484_1188" result="shape" />
								</filter>
								<filter id="filter7_d_484_1188" x="789" y="639" width="185" height="181" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
									<feFlood flood-opacity="0" result="BackgroundImageFix" />
									<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
									<feOffset dx="3" dy="3" />
									<feColorMatrix type="matrix" values="0 0 0 0 0.0340278 0 0 0 0 0.157404 0 0 0 0 0.291667 0 0 0 0.15 0" />
									<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_484_1188" />
									<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_484_1188" result="shape" />
								</filter>
							</defs>
						</svg>
					</div>
				</div>
			</div>
		</>
	);
};

export default Fintech;