import { useState, useEffect } from "react";
import { MdDelete } from "react-icons/md";
import { useNavigate } from 'react-router-dom';
import { FaEye } from "react-icons/fa";
import { Helmet } from "react-helmet";
import moment from 'moment';
import API from '../../../middleware/API';
import DataTable from 'react-data-table-component';

// ================================|| DemoProjectList ||================================ //

function DemoProjectList() {
    const navigate = useNavigate();
    const [Page, setPage] = useState(1);
    const itemFieldPOS = ["POS"];
    const itemFieldLiveChat = ["Live Chat"];
    const itemFieldTftWeb = ["TFT Web"];
    const itemFieldTftAdmin = ["TFT Admin"];
    const itemFieldCRM = ["CRM Demo"];
    const itemFieldAccountManagement = ["Account Management"];
    const itemFieldNopCommerce = ["Nop-Commerce Website"];
    const [DemoProjectName, setDemoProjectName] = useState("");
    const [DemoProjectdata, setDemoProjectData] = useState();
    const [TotalDemoProject, setTotalDemoProject] = useState();
    const [loading, setLoading] = useState(false);

    const rowsperPage = "5";

    useEffect(() => {
        getDemoProjectList();
    }, [Page]);

    const getDemoProjectList = async () => {
        try {
            setLoading(true);
            var GetDemoProjectDetailAPI = API.getDemoProjectDetail;
            GetDemoProjectDetailAPI = GetDemoProjectDetailAPI + '?pageNumber=' + Page + '&rowsperPage=' + rowsperPage;
            const response = await fetch(GetDemoProjectDetailAPI);
            const jsonData = await response.json();
            setDemoProjectData(jsonData.recordsets[0]);
            setTotalDemoProject(jsonData.recordsets[1][0].TotalRows);
            setLoading(false);
        }
        catch (error) {
            console.log(error);
            setLoading(false);
        }
    };

    function viewDemoProjectById(id) {
        navigate('/admin/demo-project?Id=' + id);
    }

    function deleteDemoProjectById(id) {
        var result = window.confirm('Are you sure you want to delete this record?');
        if (result) {
            try {
                var DeleteDemoProjectDetailByIdAPI = API.deleteDemoProjectDetailById;
                DeleteDemoProjectDetailByIdAPI = DeleteDemoProjectDetailByIdAPI + "?id=" + id;
                const response = fetch(DeleteDemoProjectDetailByIdAPI).then(() => {
                    getDemoProjectList(Page);
                });
            }
            catch (error) {
                console.log(error);
            }
        }
    }

    const columns = [
        {
            name: 'Id',
            selector: row => row.Id,
            sortable: true,
            width: "4rem"
        },
        {
            name: 'Name',
            selector: row => row.FullName,
            sortable: true
        },
        {
            name: 'Email',
            selector: row => row.Email,
            sortable: true
        },
        {
            name: 'Project Name',
            selector: row => row.ProjectName,
            sortable: true
        },
        {
            name: 'Created Date',
            selector: row => moment(row.CreatedOn).format('DD-MM-YYYY'),
            sortable: true
        },
        {
            name: 'Action',
            cell: (props) => <div>
                <a><FaEye size={30} className="m-1 p-1 cl-button -primary -small" onClick={() => viewDemoProjectById(props.Id)} style={{ cursor: "pointer" }} /></a>
                <a><MdDelete size={30} className="m-1 p-1 cl-button -primary -small" onClick={() => deleteDemoProjectById(props.Id)} style={{ cursor: "pointer" }} /></a>
            </div>,
            sortable: false
        },
    ];

    return (
        <>
            <Helmet>
                <title>Demo Project - The Bridge Code</title>
            </Helmet>
            <div className="container-fluid">
                <div className="container d-flex pt-5 pb-3">
                    <div className="col-md-4">
                        <h3 className="card-header px-3">Demo Project Details</h3>
                        <div className="col-md-7 px-3">
                            <div className="form-item pb-4">
                                <select class="form-select form-select-sm mb-3" id='select' onChange={(e) => setDemoProjectName(e.target.value)}>
                                    <option value="" selected>Select</option>
                                    <option value="POS" >POS</option>
                                    <option value="CRM Demo" >CRM Demo</option>
                                    <option value="Live Chat" >Live Chat</option>
                                    <option value="TFT Web" >TFT Website(React)</option>
                                    <option value="TFT Admin" >TFT Admin(React)</option>
                                    <option value="Account Management" >Account Management</option>
                                    <option value="Nop-Commerce Website" >Nop-Commerce Website</option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-8">
                        {itemFieldPOS.find((e) => e === DemoProjectName) && (
                            <div style={{ textAlign: "left" }}>
                                <p><span className="fw-bolder fs-5">URL: </span><a href="https://pos.thebridgecode.com/" target="_blank">https://pos.thebridgecode.com/</a></p>
                                <p><span className="fw-bolder fs-5">Username: </span>admin@thebridgecode.com</p>
                                <p><span className="fw-bolder fs-5">Password: </span>admin@123</p>
                            </div>
                        )}
                        {itemFieldCRM.find((e) => e === DemoProjectName) && (
                            <div style={{ textAlign: "left" }}>
                                <p><span className="fw-bolder fs-5">URL: </span><a href="https://crm.thebridgecode.com/" target="_blank">https://crm.thebridgecode.com/</a></p>
                                <p><span className="fw-bolder fs-5">Username: </span>TBC1</p>
                                <p><span className="fw-bolder fs-5">Password: </span>SuperAdmin@123</p>
                            </div>
                        )}
                        {itemFieldLiveChat.find((e) => e === DemoProjectName) && (
                            <div style={{ textAlign: "left" }}>
                                <p><span className="fw-bolder fs-5">URL: </span><a href="https://livechat.thebridgecode.com/" target="_blank">https://livechat.thebridgecode.com/</a></p>
                            </div>
                        )}
                        {itemFieldTftWeb.find((e) => e === DemoProjectName) && (
                            <div style={{ textAlign: "left" }}>
                                <p><span className="fw-bolder fs-5">URL: </span><a href="https://tftweb.thebridgecode.com/" target="_blank">https://tftweb.thebridgecode.com/</a></p>
                                <p><span className="fw-bolder fs-5">Username: </span>admin@thebridgecode.com</p>
                                <p><span className="fw-bolder fs-5">Password: </span>admin@123</p>
                            </div>
                        )}
                        {itemFieldTftAdmin.find((e) => e === DemoProjectName) && (
                            <div style={{ textAlign: "left" }}>
                                <p><span className="fw-bolder fs-5">URL: </span><a href="https://tftadmin.thebridgecode.com/" target="_blank">https://tftadmin.thebridgecode.com/</a></p>
                                <p><span className="fw-bolder fs-5">Username: </span>admin@thebridgecode.com</p>
                                <p><span className="fw-bolder fs-5">Password: </span>admin@123</p>
                            </div>
                        )}
                        {itemFieldAccountManagement.find((e) => e === DemoProjectName) && (
                            <div className="container">
                                <p><span className="fw-bolder fs-5">URL: </span><a href="https://account.thebridgecode.com/" target="_blank">https://account.thebridgecode.com/</a></p>
                                <div className="row">
                                    <div className="col-sm">
                                        <p><span className="fw-bolder fs-6">Role: </span>Super Admin</p>
                                        <p><span className="fw-bolder fs-6">Username: </span>ACC101</p>
                                        <p><span className="fw-bolder fs-6">Password: </span>Admin</p>
                                    </div>
                                    <div className="col-sm">
                                        <p><span className="fw-bolder fs-6">Role: </span>Accountant</p>
                                        <p><span className="fw-bolder fs-6">Username: </span>ACC102</p>
                                        <p><span className="fw-bolder fs-6">Password: </span>Accountant@123</p>
                                    </div>
                                    <div className="col-sm">
                                        <p><span className="fw-bolder fs-6">Role: </span>Employee</p>
                                        <p><span className="fw-bolder fs-6">Username: </span>ACC103</p>
                                        <p><span className="fw-bolder fs-6">Password: </span>Employee@123</p>
                                    </div>
                                    <div className="col-sm">
                                        <p><span className="fw-bolder fs-6">Role: </span>Manager</p>
                                        <p><span className="fw-bolder fs-6">Username: </span>ACC104</p>
                                        <p><span className="fw-bolder fs-6">Password: </span>Manager@123</p>
                                    </div>
                                </div>
                            </div>
                        )}
                        {itemFieldNopCommerce.find((e) => e === DemoProjectName) && (
                            <div style={{ textAlign: "left" }}>
                                <p><span className="fw-bolder fs-5">URL: </span><a href="https://185.196.21.59:451/" target="_blank">https://185.196.21.59:451/</a></p>
                                <p><span className="fw-bolder fs-5">Username: </span>admin@thebridgecode.com</p>
                                <p><span className="fw-bolder fs-5">Password: </span>admin@123</p>
                            </div>
                        )}
                    </div>
                </div>

                <div className="container mx-auto pt-3">
                    <div className="px-3 pb-3">
                        <div className="col-sm-12 table-responsive text-nowrap shadow p-3 mb-5 bg-body rounded">
                            <DataTable
                                columns={columns}
                                data={DemoProjectdata}
                                pagination
                                paginationServer
                                progressPending={loading}
                                paginationTotalRows={TotalDemoProject}
                                paginationPerPage={rowsperPage}
                                paginationComponentOptions={{
                                    noRowsPerPage: true
                                }}
                                onChangePage={page => setPage(page)}
                            ></DataTable>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default DemoProjectList;