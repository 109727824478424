import React from 'react';
import { BsFillHandThumbsUpFill, BsFillCheckCircleFill } from "react-icons/bs";
import Faq from "react-faq-component";
import { Link } from 'react-router-dom';
import { Helmet } from "react-helmet";

const data = {
    title: <h1 className='pb-3 display-5 fw-bold title'>FAQ (How it works)</h1>,
    rows: [
        {
            title: <b>1. How long does website development take?</b>,
            content: `Depending on the Type, size, complexity and, most critically, the client’s active involvement in the process, a website takes anywhere between one and 4 weeks to build and launch. Some incredibly large projects on our Portfolio have broken the year mark and still ongoing.<br />
            We at TheBridgeCode try to keep the Client updated and in the loop so we can avoid any obstacles and resolve them quickly.`,
        },
        {
            title: <b>2. How long does website redesign take?</b>,
            content: `A typical small business website takes around 15 days to 1 month to build.<br />
            Redesigning factor also depnds on number of Pages which needs to be Redesigned, Client has Design/Theme in mind, We need to develop it from scratch or just Integrate a theme.`,
        },
        {
            title: <b>3. What happens if my site breaks?</b>,
            content: `We’re always here to help! We can cover emergencies and non-emergencies alike. Just be sure to give us as many details as possible about the problem.<br />
            Additionally, we provide a maintenance agreement to help prevent many common site crashes from ever happening in the first place. That way, problems are minimized, and you can have peace of mind knowing that when problems do occur, you’re covered!`,
        },
        {
            title: <b>4. I don't know what is Domain name and hosting, Please Explain?</b>,
            content: `Domain names and web hosting are two different services. However, they work together to make websites possible.<br />
            Basically a domain name system is like a massive address book that is constantly updated. Behind each domain name, there is an address of the web hosting service storing the website’s files.<br />
            For E.g. thebridgecode.com is domain name and Hosting where our website is hoted.`,
        },
        {
            title: <b>5. Can you handle ongoing maintenance?</b>,
            content: `Yes! In fact, Whether we have developed your website or not, we’d really love to handle maintenance. We’re going to be the party most intimately familiar with how your website works, and are going to be on top of upgrades and patches to keep the site in top shape.`,
        },
        {
            title: <b>6. How much does a new website cost?</b>,
            content: `We can always provide you with Ballpark Estimates once you share your requirements or we take a call and understand it. Having a rough idea of expected costs as soon as possible helps you to qualify whether what you have in mind is commercially feasible and if you would like to investigate further.<br />
            Ballpark estimates are educated guesses based on our experience, so please remember that the actual cost may go up or down as we take the time to explore your requirements in more detail.`,
        },
        {
            title: <b>7. Will my website be mobile Responsive?</b>,
            content: `Yes, we ensure to develop a responsive website that would make your website appealing and deliver an optimized browsing experience to your Customers.`,
        },
        {
            title: <b>8. Who writes the content for the site?</b>,
            content: `We can help you in adding relevant content to your website. Our team of content writers can help you in delivering professional content by understanding your requirements and business objectives. If you wish you can write your own content and we can validate and make changes too.`,
        },
        {
            title: <b>9. What if I need help on my site down the road?</b>,
            content: `We have many website Maintenance options available, which you can explore by asking us.`,
        },
    ],
};

const styles = {
    bgColor: 'white',
    titleTextColor: "#000",
    rowTitleColor: "#000",
    rowContentColor: '#000',
    arrowColor: "#000",
    transitionDuration: "0.2s",

};

const config = {
    animate: true,
    // arrowIcon: "V",
    tabFocus: true,
    expandIcon: "+",
    collapseIcon: "-",
};

// ================================|| WebsiteDevelopment ||================================ //

const WebsiteDevelopment = () => {
    return (
        <>
            <Helmet>
				<title>Website Development - The Bridge Code</title>
			</Helmet>
            <div className='container-fluid p-0'>
                <div class="position-relative overflow-hidden text-center web_dev">
                    <div class="col-md-7 p-lg-5 mx-auto my-1">
                        <h1 class="display-2 fw-bolder text-white title-c">Website Development</h1>
                        <p class="lead fw-normal text-light">Craft your solutions for business specific requirements with our web development services</p>
                        <Link to='/contact-us'><button className='btn-change2'>Contact Us</button></Link>
                    </div>
                </div>
                <div className="container mt-5 mb-5">
                    <div className="row col-md-12">
                        <div className="col-md-6">
                            <div className="title"><h1>Overview</h1></div>
                            <p className="display-5">Build next gen Web Solutions</p>
                            <p className="fs-3 fw-bold">We take Pride to develop Future Web Solution.</p>
                            <p>The Bridgecode Technologies is a leading web development agency in India, offering the best web development services to worldwide organizations, startups, and various Enerprise Clients to Build web solutions with their specifications and brand identity.</p>
                            <ul className="">
                                <li className="list-group-item p-1 fs-5"><BsFillHandThumbsUpFill /> Website Development</li>
                                <li className="list-group-item p-1 fs-5"><BsFillHandThumbsUpFill /> Website Designing</li>
                                <li className="list-group-item p-1 fs-5"><BsFillHandThumbsUpFill /> UI/UX design</li>
                                <li className="list-group-item p-1 fs-5"><BsFillHandThumbsUpFill /> Robust Web Solutions</li>
                                <li className="list-group-item p-1 fs-5"><BsFillHandThumbsUpFill /> Open-source Framework Experts</li>
                                <li className="list-group-item p-1 fs-5"><BsFillHandThumbsUpFill /> CRM Web Devlopment</li>
                                <li className="list-group-item p-1 fs-5"><BsFillHandThumbsUpFill />  Support and Maintenance</li>
                            </ul>
                        </div>
                        <div className="col-md-6 align-self-center">
                            <img src="https://res.cloudinary.com/dm1muiikf/image/upload/v1684484853/website/web_dev/web_development_banner.svg" alt="" className="img-fluid" />
                        </div>
                    </div>
                </div>

                <div className="container-fluid csd_serv ">
                    <div className="container">
                        <div className="row text-center justify-content-between">
                            <h1 className="display-2 fw-bold text-light title-c mb-5">Technologies We Use</h1>

                            <div className="three rounded shadow-lg text-white pt-3 mt-5" style={{ backgroundColor: "rgb(0,0,0,0.3)" }}>
                                <div className="mb-3"><img src="https://res.cloudinary.com/dm1muiikf/image/upload/v1684484853/website/csd/ASP_Net.png" className="img-fluid bg-body" width={105} alt="Asp.net" /></div>
                                <h4>ASP.Net</h4>
                                <p className="text-light">Free. Cross-platform. Open source. A framework for building web apps and services with .NET. Interactive web UI with C#
                                    Blazor is a feature of ASP.NET for building interactive web UIs using C# instead of JavaScript. Blazor gives you real .NET running in the
                                    browser on WebAssembly.</p>
                                <div className="row px-2">
                                    <h4 className="text-start title">Features</h4>
                                    <ul className="col-md-5 text-start">
                                        <li className="list-group-item p-1"><BsFillCheckCircleFill /> High flexibility</li>
                                        <li className="list-group-item p-1"><BsFillCheckCircleFill /> Open-source</li>
                                        <li className="list-group-item p-1"><BsFillCheckCircleFill /> Fast and scalable</li>
                                    </ul>
                                    <ul className="col-md-6 text-start">
                                        <li className="list-group-item p-1"><BsFillCheckCircleFill /> Build secure apps</li>
                                        <li className="list-group-item p-1"><BsFillCheckCircleFill /> Free hosting on Azure</li>
                                        {/* <li className="list-group-item p-1"><BsFillCheckCircleFill /> ReactJS</li> */}
                                    </ul>
                                </div>
                            </div>

                            <div className="three rounded shadow-lg text-white pt-3 mt-5">
                                <div className="mb-3"><img src="https://res.cloudinary.com/dm1muiikf/image/upload/v1684484853/website/csd/NET_Core.png" className="img-fluid bg-body " width={85} height={185} alt="Asp.Net core" /></div>
                                <h4>ASP.Net Core</h4>
                                <p className="text-light">ASP.NET Core is a free and open-source web framework and successor to ASP.NET, developed by Microsoft. It is a modular framework
                                    that runs on both the full .NET Framework, on Windows, and the cross-platform</p>
                                <div className="row px-2">
                                    <h4 className="text-start title">Features</h4>
                                    <ul className="col-md-5 text-start">
                                        <li className="list-group-item p-1"><BsFillCheckCircleFill /> High flexibility</li>
                                        <li className="list-group-item p-1"><BsFillCheckCircleFill /> Open-source</li>
                                        <li className="list-group-item p-1"><BsFillCheckCircleFill /> Fast and scalable</li>
                                    </ul>
                                    <ul className="col-md-6 text-start">
                                        <li className="list-group-item p-1"><BsFillCheckCircleFill /> Build secure apps</li>
                                        <li className="list-group-item p-1"><BsFillCheckCircleFill /> Free hosting on Azure</li>
                                        {/* <li className="list-group-item p-1"><BsFillCheckCircleFill /> ReactJS</li> */}
                                    </ul>
                                </div>
                            </div>

                            <div className="three rounded shadow-lg text-white pt-3 mt-5" style={{ backgroundColor: "rgb(0,0,0,0.3)" }}>
                                <div className="mb-3"><img src="https://res.cloudinary.com/dm1muiikf/image/upload/v1684484853/website/csd/javascript.png" className="img-fluid bg-body" width={75} alt="javascript" /></div>
                                <h4>JavaScript</h4>
                                <p className="text-light">JavaScript is a lightweight, interpreted scripting language widely used to enhance and add content to a web page. It gets used in various ways,
                                    from adding simple functions or buttons to creating complex animations.JavaScript runs on top of a browser’s built-in engine and adds \
                                    functionality with simple commands placed inside tags within an HTML.</p>
                                <div className="row px-2">
                                    <h4 className="text-start title">Features</h4>
                                    <ul className="col-md-5 text-start">
                                        <li className="list-group-item p-1"><BsFillCheckCircleFill /> Facilitates rapid project development</li>
                                        <li className="list-group-item p-1"><BsFillCheckCircleFill /> Reduces compilation time</li>
                                        {/* <li className="list-group-item p-1"><BsFillCheckCircleFill /> High interoperability</li> */}
                                    </ul>
                                    <ul className="col-md-6 text-start">
                                        <li className="list-group-item p-1"><BsFillCheckCircleFill /> Enhanced speed performance</li>
                                        <li className="list-group-item p-1"><BsFillCheckCircleFill /> Shorter codes</li>
                                        <li className="list-group-item p-1"><BsFillCheckCircleFill /> Extended Functionality</li>
                                    </ul>
                                </div>
                            </div>

                            <div className="three rounded shadow-lg text-white pt-3 mt-5">
                                <div className="mb-3"><img src="https://res.cloudinary.com/dm1muiikf/image/upload/v1684484853/website/csd/html.png" className="img-fluid" width={75} alt="html" /></div>
                                <h4>HTML/CSS</h4>
                                <p className="text-light">HTML is the most basic of all coding languages. If you’re planning to develop a website or web app, HTML will provide you with a structure for Web pages.
                                    Since most websites get built on top of HTML, developers can style your code to fit your specific needs by an experienced developer.</p>
                                <div className="row px-2">
                                    <h4 className="text-start title">Features</h4>
                                    <ul className="col-md-5 text-start">
                                        <li className="list-group-item p-1"><BsFillCheckCircleFill /> Lightweight </li>
                                        <li className="list-group-item p-1"><BsFillCheckCircleFill /> Fast to load</li>
                                        <li className="list-group-item p-1"><BsFillCheckCircleFill /> Easier to gain command in the language</li>
                                    </ul>
                                    <ul className="col-md-6 text-start">
                                        <li className="list-group-item p-1"><BsFillCheckCircleFill /> Compatibility with a very high</li>
                                        <li className="list-group-item p-1"><BsFillCheckCircleFill /> Free to use</li>
                                        {/* <li className="list-group-item p-1"><BsFillCheckCircleFill /> Location APIs</li> */}
                                    </ul>
                                </div>
                            </div>

                            <div className="three rounded shadow-lg text-white pt-3 mt-5" style={{ backgroundColor: "rgb(0,0,0,0.3)" }}>
                                <div className="mb-3"><img src="https://res.cloudinary.com/dm1muiikf/image/upload/v1684484853/website/csd/python.png" className="img-fluid bg-body" width={75} alt="python" /></div>
                                <h4>Python</h4>
                                <p className="text-light">Python is a great language to go for web development if you want to do just about anything. It’s an incredibly versatile language that you can employ in any number of industries and for any number of purposes.</p>
                                <div className="row px-2">
                                    <h4 className="text-start title">Features</h4>
                                    <ul className="col-md-5 text-start">
                                        <li className="list-group-item p-1"><BsFillCheckCircleFill /> Free to use</li>
                                        <li className="list-group-item p-1"><BsFillCheckCircleFill /> Supports tons of libraries</li>
                                        <li className="list-group-item p-1"><BsFillCheckCircleFill /> Enhanced Productivity</li>
                                    </ul>
                                    <ul className="col-md-6 text-start">
                                        <li className="list-group-item p-1"><BsFillCheckCircleFill /> High code readability</li>
                                        <li className="list-group-item p-1"><BsFillCheckCircleFill /> Offers portability</li>
                                        {/* <li className="list-group-item p-1"><BsFillCheckCircleFill /> .NET Framework 4.6.1</li> */}
                                    </ul>
                                </div>
                            </div>

                            <div className="three rounded shadow-lg text-white pt-3 mt-5" >
                                <div className="mb-3"><img src="https://res.cloudinary.com/dm1muiikf/image/upload/v1684484853/website/csd/c.png" className="img-fluid bg-body" width={75} alt="C++" /></div>
                                <h4>C++</h4>
                                <p className="text-light">C++ is among the most popular programming languages out there. Using C++, you can create high-performing web projects
                                    across multiple platforms, including mobile OS. Noticeably, Apple’s iOS operating system was developed using C++, so you can start here if apps
                                    are your thing.</p>
                                <div className="row px-2">
                                    <h4 className="text-start title">Features</h4>
                                    <ul className="col-md-5 text-start">
                                        <li className="list-group-item p-1"><BsFillCheckCircleFill /> Highly scalable language</li>
                                        <li className="list-group-item p-1"><BsFillCheckCircleFill /> Facilitates quick</li>
                                        <li className="list-group-item p-1"><BsFillCheckCircleFill /> Multiple built-in</li>
                                    </ul>
                                    <ul className="col-md-6 text-start">
                                        <li className="list-group-item p-1"><BsFillCheckCircleFill /> High Portability</li>
                                        <li className="list-group-item p-1"><BsFillCheckCircleFill /> Vast community</li>
                                        {/* <li className="list-group-item p-1"><BsFillCheckCircleFill /> PWA</li> */}
                                    </ul>
                                </div>
                            </div>

                            <div className="three rounded shadow-lg text-white pt-3 mt-5" style={{ backgroundColor: "rgb(0,0,0,0.3)" }}>
                                <div className="mb-3"><img src="https://res.cloudinary.com/dm1muiikf/image/upload/v1684484853/website/csd/go.png" className="img-fluid bg-body p-3" width={105} alt="go" /></div>
                                <h4>Go</h4>
                                <p className="text-light">Go is a statically typed, compiled high-level programming language designed at Google. Developers use Go in an array of operating systems and frameworks to develop web applications, cloud and networking services, and other types of software.</p>
                                <div className="row px-2">
                                    <h4 className="text-start title">Features</h4>
                                    <ul className="col-md-5 text-start">
                                        <li className="list-group-item p-1"><BsFillCheckCircleFill /> Open-Source</li>
                                        <li className="list-group-item p-1"><BsFillCheckCircleFill /> Static Typing</li>
                                        <li className="list-group-item p-1"><BsFillCheckCircleFill /> Concurrency Support</li>
                                    </ul>
                                    <ul className="col-md-6 text-start">
                                        <li className="list-group-item p-1"><BsFillCheckCircleFill /> Powerful Standard Library and Tool Set</li>
                                        <li className="list-group-item p-1"><BsFillCheckCircleFill /> Ease of Use</li>
                                        <li className="list-group-item p-1"><BsFillCheckCircleFill /> Garbage Collection</li>
                                    </ul>
                                </div>
                            </div>

                            <div className="three rounded shadow-lg text-white pt-3 mt-5">
                                <div className="mb-3"><img src="https://res.cloudinary.com/dm1muiikf/image/upload/v1684484853/website/csd/angularjs.png" className="img-fluid bg-body" width={75} alt="angularjs" /></div>
                                <h4>Angular Development</h4>
                                <p className="text-light">An Angular framework makes it easy to develop web applications. Combining dependency injection, declarative templates,
                                    end-to-end tooling, and integrated best practices, it solves almost all the challenges when creating a web app.</p>
                                <div className="row px-2">
                                    <h4 className="text-start title">Features</h4>
                                    <ul className="col-md-5 text-start">
                                        <li className="list-group-item p-1"><BsFillCheckCircleFill /> CSS</li>
                                        <li className="list-group-item p-1"><BsFillCheckCircleFill /> HTML</li>
                                        <li className="list-group-item p-1"><BsFillCheckCircleFill /> Ruby On Rails (API)</li>
                                    </ul>
                                    <ul className="col-md-6 text-start">
                                        <li className="list-group-item p-1"><BsFillCheckCircleFill /> JavaScript</li>
                                        <li className="list-group-item p-1"><BsFillCheckCircleFill /> Node.Js</li>
                                        <li className="list-group-item p-1"><BsFillCheckCircleFill /> ReactJS</li>
                                    </ul>
                                </div>
                            </div>

                            <div className="three rounded shadow-lg text-white pt-3 mt-5" style={{ backgroundColor: "rgb(0,0,0,0.3)" }}>
                                <div className="mb-3"><img src="https://res.cloudinary.com/dm1muiikf/image/upload/v1684484853/website/csd/php.png" className="img-fluid bg-body" width={75} alt="php" /></div>
                                <h4>PHP</h4>
                                <p className="text-light">Loved by website developers, PHP is one of the most popular server-side scripting languages in use today. If you’re
                                    looking to write dynamic websites with WordPress, start your career as a web developer or add to your existing skill set, PHP is a great place
                                    to begin.</p>
                                <div className="row px-2">
                                    <h4 className="text-start title">Features</h4>
                                    <ul className="col-md-5 text-start">
                                        <li className="list-group-item p-1"><BsFillCheckCircleFill /> High flexibility</li>
                                        <li className="list-group-item p-1"><BsFillCheckCircleFill /> Open-source</li>
                                        <li className="list-group-item p-1"><BsFillCheckCircleFill /> Enhanced speed</li>
                                    </ul>
                                    <ul className="col-md-6 text-start">
                                        <li className="list-group-item p-1"><BsFillCheckCircleFill /> Good documentation</li>
                                        <li className="list-group-item p-1"><BsFillCheckCircleFill /> Open-source</li>
                                        {/* <li className="list-group-item p-1"><BsFillCheckCircleFill /> ReactJS</li> */}
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="container p-5">
                    <div className="text-center mx-5">
                        <h1 className="title-c">Our Web Application Development Services</h1>
                        <p>Avail top web development services to create feature-rich web portals that are responsive, robust, scalable and quality-driven.</p>
                    </div>
                    <div className="row text-center justify-content-between">
                        <div className="col-md-3 bg-body rounded shadow-lg mx-2 my-4">
                            <img src="https://res.cloudinary.com/dm1muiikf/image/upload/v1684484853/website/web_dev/Full-Stack-development-services.jpeg" alt="Full-Stack-development-services" width={100} className="img-fluid p-2" />
                            <h4>Full Stack development services</h4>
                            <p>Realize your web development ideas to create front-end as well as back-end web app solutions with our expert full-stack web developers.We use the latest technologies and languages to create most popular stacks: MEAN stack, MERN stack, Django stack, LAMP stack, Rails on ruby(Ror) stack.</p>
                        </div>
                        <div className="col-md-3 bg-body rounded shadow-lg mx-2 my-4">
                            <img src="https://res.cloudinary.com/dm1muiikf/image/upload/v1684484853/website/web_dev/Progressive-web-apps.jpeg" alt="Progressive web apps" width={100} className="img-fluid p-2" />
                            <h4>Progressive web apps</h4>
                            <p>Our web development firm builds feature rich progressive web applications with visually appealing content, hassle-free navigation and quicker load time. We offer impactful user experience and improve mobile conversion rates.</p>
                        </div>
                        <div className="col-md-3 bg-body rounded shadow-lg mx-2 my-4">
                            <img src="https://res.cloudinary.com/dm1muiikf/image/upload/v1684484853/website/web_dev/Custom-web-application-development.jpeg" alt="" width={100} className="img-fluid p-2" />
                            <h4>Custom web application development</h4>
                            <p>Our custom web application services offer web app solutions that are secure, flexible and scalable. Web apps developed by us can be easily customized to the project’s requirements and don’t face compatibility issues.</p>
                        </div>
                        <div className="col-md-3 bg-body rounded shadow-lg mx-2 my-4">
                            <img src="https://res.cloudinary.com/dm1muiikf/image/upload/v1684484853/website/web_dev/Enterprice-web-solutions.jpeg" alt="Enterprice-web-solutions" width={100} className="img-fluid p-2" />
                            <h4>Enterprise web solutions</h4>
                            <p>Build an enterprise web application that is secure, reliable and agile. We are your one-stop solution that enfolds all the web development processes like UI/UX, wireframing, development and quality assurance.</p>
                        </div>
                        <div className="col-md-3 bg-body rounded shadow-lg mx-2 my-4">
                            <img src="https://res.cloudinary.com/dm1muiikf/image/upload/v1684484853/website/web_dev/eCommerce-web-development.jpeg" alt="" width={100} className="img-fluid p-2" />
                            <h4>eCommerce web development</h4>
                            <p>Enhance your business growth and build your web presence by our ecommerce website development processes. We ensure that our customers get premium progressive web app development solutions with an Awesome user experience.</p>
                        </div>
                        <div className="col-md-3 bg-body rounded shadow-lg mx-2 my-4">
                            <img src="https://res.cloudinary.com/dm1muiikf/image/upload/v1684484853/website/web_dev/Content-Management-System.jpeg" alt="Content-Management-System" width={100} className="img-fluid p-2" />
                            <h4>Content Management System</h4>
                            <p>By Integrating CMS software to your website, we ensure that the content is way easier to manage. Using our web development services we generate custom solutions that improve the overall functionality of your website.</p>
                        </div>
                    </div>
                </div>

                {/* <!-- Cta--> */}
                <div className="container-fluid p-0 m-0">
                    <div className="parallax-container py-5">
                        <div className="parallax-content section-xl">
                            <div className="container">
                                <div className="row justify-content-end text-right">
                                    <div className="col-sm-7 text-center">
                                        <h3 className="display-4 fw-bold text-white mb-5">Why The Bridge Code?</h3>
                                        <p className='text-light mb-4'>BridgeCode Technologies is a Firm where we provide software development, integrations, and resources you need to compete
                                            in the Fast changing world. We believe in the power of marketing, sales, content management and great customer service are key to success in any
                                            Business. We have started a New Approach for our Clients where they can see the Potential of our Skills and Software. Try our Free Demo! </p>
                                        <p className='mt-3 text-white fs-4 fw-bold'>If you have the Passion, We have the Plan!!</p>
                                        <div className="mt-4">
                                            <Link to='/solution' className="cl-button -primary -small mx-1">Try Free Demo</Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="container pt-5 pb-5">
                    <div className="col-md-12 ">
                        <div className="col-md-8 mx-auto">
                            <Faq data={data} styles={styles} config={config} />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default WebsiteDevelopment;