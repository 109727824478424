import { useState, useEffect } from "react";
import { MdDelete } from "react-icons/md";
import { useNavigate } from 'react-router-dom';
import { FaFile, FaEye } from "react-icons/fa";
import { Helmet } from "react-helmet";
import moment from 'moment';
import API from '../../../middleware/API';
import DataTable from 'react-data-table-component';

// ================================|| ProjectDetailList ||================================ //

function ProjectDetailList() {
    const navigate = useNavigate();
    const [Page, setPage] = useState(1);
    const [Projectdata, setProjectData] = useState();
    const [TotalProject, setTotalProject] = useState();
    const [loading, setLoading] = useState(false);

    const rowsperPage = "5";

    useEffect(() => {
        getProjectList();
    }, [Page]);

    const getProjectList = async () => {
        try {
            setLoading(true);
            var GetProjectDetailAPI = API.getProjectDetail;
            GetProjectDetailAPI = GetProjectDetailAPI + '?pageNumber=' + Page + '&rowsperPage=' + rowsperPage;
            const response = await fetch(GetProjectDetailAPI);
            const jsonData = await response.json();
            setProjectData(jsonData.recordsets[0]);
            setTotalProject(jsonData.recordsets[1][0].TotalRows);
            setLoading(false);
        }
        catch (error) {
            console.log(error);
            setLoading(false);
        }
    };

    function viewProjectById(id) {
        navigate('/admin/project-detail?Id=' + id);
    }

    function deleteProjectById(id) {
        var result = window.confirm('Are you sure you want to delete this record?');
        if (result) {
            try {
                var DeleteProjectDetailByIdAPI = API.deleteProjectDetailById;
                DeleteProjectDetailByIdAPI = DeleteProjectDetailByIdAPI + "?id=" + id;
                const response = fetch(DeleteProjectDetailByIdAPI).then(() => {
                    getProjectList(Page);
                });
            }
            catch (error) {
                console.log(error);
            }
        }
    }

    const columns = [
        {
            name: 'Id',
            selector: row => row.Id,
            sortable: true,
            width: "4rem"
        },
        {
            name: 'Name',
            selector: row => row.Name,
            sortable: true
        },
        {
            name: 'Email',
            selector: row => row.Email,
            sortable: true
        },
        {
            name: 'Mobile No',
            selector: row => row.MobileNo,
            sortable: true
        },
        {
            name: 'Country',
            selector: row => row.Country,
            sortable: true
        },
        {
            name: 'Industry',
            selector: row => row.Industry,
            sortable: true
        },
        {
            name: 'Document',
            cell: (props) =>
                <div><a href={props.Image} target="_blank"><FaFile size={'1.5rem'} /></a></div>,
            sortable: false,
            width: "6rem"
        },
        {
            name: 'Created Date',
            selector: row => moment(row.CreatedOn).format('DD-MM-YYYY'),
            sortable: true
        },
        {
            name: 'Action',
            cell: (props) => <div>
                <a><FaEye size={30} className="m-1 p-1 cl-button -primary -small" onClick={() => viewProjectById(props.Id)} style={{ cursor: "pointer" }} /></a>
                <a><MdDelete size={30} className="m-1 p-1 cl-button -primary -small" onClick={() => deleteProjectById(props.Id)} style={{ cursor: "pointer" }} /></a>
            </div>,
            sortable: false
        },
    ];


    return (
        <>
            <Helmet>
                <title>Project Leads - The Bridge Code</title>
            </Helmet>
            <div className="container-fluid">
                <div className="container pt-5">
                    <h3 className="card-header px-3 pb-3">Project Details</h3>
                </div>
                
                <div className="container mx-auto pt-3">
                    <div className="px-3 pb-3">
                        <div className="col-sm-12 table-responsive text-nowrap shadow p-3 mb-5 bg-body rounded">
                            <DataTable
                                columns={columns}
                                data={Projectdata}
                                pagination
                                paginationServer
                                progressPending={loading}
                                paginationTotalRows={TotalProject}
                                paginationPerPage={rowsperPage}
                                paginationComponentOptions={{
                                    noRowsPerPage: true
                                }}
                                onChangePage={page => setPage(page)}
                            ></DataTable>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default ProjectDetailList;