import React, { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import Col from 'react-bootstrap/Col';
import Nav from 'react-bootstrap/Nav';
import Row from 'react-bootstrap/Row';
import Tab from 'react-bootstrap/Tab';
import Form from 'react-bootstrap/Form';
import { Link } from 'react-router-dom';
import { Helmet } from "react-helmet";
import API from '../../../middleware/API';

// ================================|| Career ||================================ //

const Career = () => {
	const [fname, setFName] = useState("");
	const [lname, setLName] = useState("");
	const [appliedForRole, setappliedForRole] = useState("");
	const itemsRenderField = ["Other"];
	const [otherRole, setotherRole] = useState("");
	const [email, setEmail] = useState("");
	const [mobileNumber, setMobileNumber] = useState("");
	const [company, setCompany] = useState("");
	const [file, setFile] = useState("");
	const [linkedin, setLinkedin] = useState("");
	const [github, setGithub] = useState("");
	const [portfolio, setPortfolio] = useState("");
	const [message, setMessage] = useState("");
	const [Show, setShow] = useState(false);
	const handleClose = () => setShow(false);               // Open model close on click on a close button.
	const handleShow = () => setShow(true);                 // On button click Open the model.
	const [appliedForErrorMessage, setAppliedForErrorMessage] = useState("");
	const [roleErrorMessage, setRoleErrorMessage] = useState("");
	const [pdfErrorMessage, setPDFErrorMessage] = useState("");
	const [CurrentOpeningdata, setCurrentOpeningData] = useState();

	useEffect(() => {
		getCurrentOpeningList();
	}, []);

	const getCurrentOpeningList = async () => {
		try {
			var GetCurrentOpeningsAPI = API.getCurrentOpenings;
			GetCurrentOpeningsAPI = GetCurrentOpeningsAPI + '?pageNumber=' + 1 + '&rowsperPage=' + 10;
			const response = await fetch(GetCurrentOpeningsAPI);
			const jsonData = await response.json();
			setCurrentOpeningData(jsonData.recordsets[0]);
		}
		catch (error) {
			console.log(error);
		}
	};

	let submitHandler = async (e) => {
		e.preventDefault();
		var re = /(?:\.([^.]+))?$/;
		var ext = re.exec(file);
		if (appliedForRole === "") {
			setAppliedForErrorMessage("Please Select Any Role");
		}
		else {
			if (appliedForRole === "Other" && otherRole === "") {
				setRoleErrorMessage("Please Enter Role Name");
			}
			else {
				if (ext[0] === ".pdf") {
					try {
						var SignuploadformAPI = API.signuploadform;
						const signResponse = await fetch(SignuploadformAPI);
						const signData = await signResponse.json();
						const url = "https://api.cloudinary.com/v1_1/" + signData.cloudname + "/auto/upload";
						const files = document.querySelector("[type=file]").files;
						let file = files[0];
						const formData = new FormData();

						formData.append("file", file);
						formData.append("api_key", signData.apikey);
						formData.append("timestamp", signData.timestamp);
						formData.append("signature", signData.signature);
						formData.append("eager", "c_pad,h_300,w_400|c_crop,h_200,w_260");
						formData.append("folder", "website");

						const fileData = await fetch(url, {
							method: "POST",
							body: formData
						})
						const fileJson = await fileData.json();
						console.log(fileJson);

						var SendCareerDetailEmailAPI = API.sendCareerDetailEmail;
						let result = await fetch(SendCareerDetailEmailAPI, {
							method: "POST",
							headers: {
								'Accept': 'application/json',
								'Content-type': 'application/json',
							},
							json: true,
							body: JSON.stringify({
								firstName: fname,
								lastName: lname,
								appliedForRole: appliedForRole,
								otherRole: otherRole,
								email: email
							}),
						});
						let resJsons = await result.json();
						if (resJsons.status === "SUCCESS") {
							var SaveCareerDetailAPI = API.saveCareerDetail;
							let res = await fetch(SaveCareerDetailAPI, {
								method: "POST",
								headers: {
									'Accept': 'application/json',
									'Content-type': 'application/json',
								},
								json: true,
								body: JSON.stringify({
									firstName: fname,
									lastName: lname,
									appliedForRole: appliedForRole,
									otherRole: otherRole,
									email: email,
									mobileNo: mobileNumber,
									lastCompany: company,
									cv: fileJson.secure_url,
									linkedIn: linkedin,
									gitHub: github,
									portFolio: portfolio
								}),
							});
							let resJson = await res.json();
							if (res.status === 200) {
								setFName("");
								setLName("");
								setappliedForRole("");
								setotherRole("");
								setEmail("");
								setMobileNumber("");
								setCompany("");
								setFile("");
								setLinkedin("");
								setGithub("");
								setPortfolio("");
								setRoleErrorMessage("");
								setPDFErrorMessage("");
								setMessage("Your response has been submitted sucessfully.");
							}	
						}
					} catch (error) {
						console.log(error);
					}
				} else {
					setPDFErrorMessage("Please upload only Pdf Format");
				}
			}
		}
	};

	return (
		<>
			<Helmet>
				<title>Career - The Bridge Code</title>
			</Helmet>
			<div className='container-fluid p-0'>
				<div class="position-relative overflow-hidden text-center career">
					<div class="col-md-5 p-lg-5 mx-auto my-5">
						<h1 class="display-1 fw-bolder text-white pb-4 title-c">Career</h1>
						<p class="lead fw-normal text-light">We love to see your growth with us</p>
						<Link to='/contact-us' class="cl-button -primary -big rounded-3">Contact Us</Link>
					</div>
				</div>
			</div>

			<div className='container pt-5'>
				<div className='row col-md-12 pb-5 justify-content-around'>
					<div className='col-md-5'>
						<h4 className='text-sky'>Opportunities at</h4>
						<h1 className='head text-sky display-3'>The Bridge Code</h1>
						<p className='pt-3'>The Bridge Code provides you a challenging and exciting career path. A company where you can give life to your ideas, a company that helps you to learn,
							enjoy and contribute. We know that our growth and success depends on the quality and commitment of our employees. We provide you a great environment to work with cutting edge technologies.</p>
						<p>Submit your skills to us and let us enable it to grow. Your ideas, new ways of looking at things and your expertise will enable us to fuel our business.</p>
					</div>
					<div className='col-md-6'>
						<h4>&nbsp;</h4>
						<h1 className='head text-sky display-3'>Careers</h1>
						<p className='pt-3 fs-5'><b>Every career path is different. Build your career with Bridge Code.</b></p>
						<p>Innovation is known to transform the way we do business in the modern world. We being the pioneer product based company in the globe, provide the platform to transform your innovative ideas. Our niche products will engage you with the worlds leading technologies.</p>
						<p>We will inspire you to gain invaluable experience in our collaborative, ethical and fun culture. Bridge Code offers you to work in a well Administered, Fun and Integrated environment.</p>
					</div>
				</div>
				<hr />
			</div>

			<div className='container-md px-3 px-md-0 mt-5 pb-5'>
				<h1 className='head text-sky'>Current Opening</h1>
				<Tab.Container id="left-tabs-example" defaultActiveKey={1}>
					<Row>
						<Col sm={4}>
							<Nav variant="pills" className="flex-column" style={{ backgroundColor: "#0b3155" }}>
								{CurrentOpeningdata &&
									CurrentOpeningdata.map(({ Id, Title }) => (
										<Nav.Item className='p-2 fs-5'>
											<Nav.Link eventKey={Id} className='text-white tab-link-variation-2'>{Title}</Nav.Link>
										</Nav.Item>
									))}
							</Nav>
						</Col>
						<Col sm={8}>
							<Tab.Content>
								{CurrentOpeningdata &&
									CurrentOpeningdata.map(({ Id, JobDescription }) => (
										<Tab.Pane eventKey={Id}>
											<div className='container mt-3' dangerouslySetInnerHTML={{ __html: JobDescription }}></div>
										</Tab.Pane>
									))}
							</Tab.Content>
						</Col>
					</Row>
				</Tab.Container>
			</div>

			<Modal show={Show} style={{ marginTop: "300px" }}>
				<Modal.Header>
					<Modal.Title style={{ fontSize: "1.3rem" }}>
						Your response has been submitted sucessfully.<br />
						<input type="submit" value="Ok" style={{ width: "fit-content", marginLeft: "200px" }} onClick={handleClose} className="cl-button -primary -small rounded-2 py-2" />
					</Modal.Title>
				</Modal.Header>
			</Modal>

			<section className='container mb-5 rounded-3 ' style={{ backgroundColor: "#5072A7" }}>
				<div className='row col-md-12 d-flex py-5'>
					<div className='col-md-6 align-self-middle p-3 p-md-5 text-white'>
						<h1>HOW TO APPLY?</h1>
						<ul style={{ listStyle: "none" }}>
							<li className='pb-2 text-break'>1. You can send your updated CV on <a className="text-decoration-none text-white" href='mailto:{Email}' style={{ cursor: "pointer" }}>hr@thebridgecode.com</a></li>
							<li className='pb-2'>2. Your can apply from this form.</li>
							<li className='pb-2'>3. You can directly contact on this number also <br /><span style={{ margin: "1rem" }}> +91 79 84909172.</span></li>
							<li className='pb-2'>4. We welcome walk in also at <br /><b style={{ margin: "1rem" }}> THE BRIDGE CODE </b><br /><span style={{ margin: "1rem" }}> 406, Shanti House, Near Madhusudan House, Off CG Road, Navrangpura, Ahmedabad - 380009. </span></li>
						</ul>
					</div>

					<div className='col-md-6 align-center bg-light pt-5' >
						<h1 className='text-center mb-3'>APPLY TO THIS JOB:</h1>
						<Form className='p-5 row border' name='career' id="myForm" onSubmit={submitHandler}>
							<Form.Group className='mb-3 col-md-6' controlId='formBasicFirstname'>
								<Form.Label id="fname" >First Name <span className='text-danger'>*</span></Form.Label>
								<Form.Control type='text' placeholder='First Name' name="fname" onChange={(e) => setFName(e.target.value)} value={fname} required />
							</Form.Group>

							<Form.Group className='mb-3 col-md-6' controlId='formBasicLastname'>
								<Form.Label id="lname" >Last Name <span className='text-danger'>*</span></Form.Label>
								<Form.Control type='text' placeholder='Last Name' name="lname" onChange={(e) => setLName(e.target.value)} value={lname} required />
							</Form.Group>

							<Form.Group className="mb-3" controlId="formBasicAppliedForRole">
								<Form.Label id="appliedForRole" >Applied For Role <span className='text-danger'>*</span></Form.Label>
								<Form.Select aria-label="Default select example" id="selectList" onChange={(e) => setappliedForRole(e.target.value)} value={appliedForRole} required>
									<option>-- Select Role --</option>
									{CurrentOpeningdata && CurrentOpeningdata.map((CurrentOpeningdata) => (
										<option key={CurrentOpeningdata.Title} value={CurrentOpeningdata.Title}>
											{CurrentOpeningdata.Title}
										</option>
									))}
									<option>Other</option>
								</Form.Select>
							</Form.Group>
							<div className="error-message">{appliedForErrorMessage ? <p>{appliedForErrorMessage}</p> : null}</div>

							{itemsRenderField.find((e) => e === appliedForRole) && (
								<Form.Group className="mb-3" controlId="formBasicOtherRole">
									<Form.Label id="otherRole" >Role Name <span className='text-danger'>*</span></Form.Label>
									<Form.Control type="text" placeholder="Enter Role Name" name="otherRole" onChange={(e) => setotherRole(e.target.value)} value={otherRole} />
								</Form.Group>
							)}
							<div className="error-message">{roleErrorMessage ? <p>{roleErrorMessage}</p> : null}</div>

							<Form.Group className="mb-3" controlId="formBasicEmail">
								<Form.Label id="email" >Email address <span className='text-danger'>*</span></Form.Label>
								<Form.Control type="email" placeholder="Enter email" name="email" onChange={(e) => setEmail(e.target.value)} value={email} required />
							</Form.Group>

							<Form.Group className='mb-3' controlId="formBasicNumber">
								<Form.Label id="mobile_no" > Mobile Number <span className='text-danger'>*</span></Form.Label>
								<Form.Control type="number" placeholder="Enter number" name="mobileNumber" onChange={(e) => setMobileNumber(e.target.value)} value={mobileNumber} required />
							</Form.Group>

							<Form.Group className='mb-3' controlId='formBasiccompany'>
								<Form.Label id="company" >Last Company</Form.Label>
								<Form.Control type='text' placeholder='Last Company' name="company" onChange={(e) => setCompany(e.target.value)} value={company} />
							</Form.Group>

							<Form.Group className='mb-3' controlId='formBasicFile'>
								<Form.Label id='file'>CV <span className='text-danger'>*</span></Form.Label>
								<Form.Control type='file' accept='application/pdf' name="file" id="files" onChange={(e) => setFile(e.target.value)} value={file} required />
							</Form.Group>
							<div className="error-message">{pdfErrorMessage ? <p>{pdfErrorMessage}</p> : null}</div>

							<Form.Group className='mb-3'>
								<Form.Label id="linkedin" >linkedin</Form.Label>
								<Form.Control type='link' placeholder='Linkedin Url' name="linkedin" onChange={(e) => setLinkedin(e.target.value)} value={linkedin} />
							</Form.Group>

							<Form.Group className='mb-3'>
								<Form.Label id="github">GitHub</Form.Label>
								<Form.Control type='link' placeholder='GitHub Url' name="github" onChange={(e) => setGithub(e.target.value)} value={github} />
							</Form.Group>

							<Form.Group className='mb-3'>
								<Form.Label id="portfolio" >Portfolio</Form.Label>
								<Form.Control type='link' placeholder='Portfolio Url' name="portfolio" onChange={(e) => setPortfolio(e.target.value)} value={portfolio} />
							</Form.Group>

							<div className="row">
								<div className="col-md-12 py-1 d-block">
									<input type="submit" value="Submit" onClick={handleShow} className="cl-button -primary -small rounded-2 py-2" />
								</div>
							</div>
							<div className="message">{message ? <p>{message}</p> : null}</div>
						</Form>
					</div>
				</div>
			</section>
		</>
	);
};

export default Career;