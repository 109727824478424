import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import Col from 'react-bootstrap/Col';
import Nav from 'react-bootstrap/Nav';
import Row from 'react-bootstrap/Row';
import Card from 'react-bootstrap/Card';
import Tab from 'react-bootstrap/Tab';
import { FaAndroid, FaBuffer, FaFileExport, FaHandshake, FaSearchengin, FaServer, FaAngleDoubleRight, FaArrowRight } from "react-icons/fa";
import { TbApi } from "react-icons/tb";
import { GrSettingsOption, GrServer, GrCloudComputer } from "react-icons/gr";
import { RiNumber1, RiNumber2, RiNumber3, RiNumber4, RiNumber5, RiNumber6, RiNumber7, RiNumber8 } from "react-icons/ri";
import { Link } from 'react-router-dom';
import { Helmet } from "react-helmet";
import API from '../../middleware/API';

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import Carousel from 'react-multi-carousel';
//For the Partical image(Banner)
import ParticleImage, { Vector, forces } from "react-particle-image";

// ---------------------Banner Image Funcation Start---------------------
const particleOptions = {
	filter: ({ x, y, image }) => {
		// Get pixel
		const pixel = image.get(x, y);
		// Make a particle for this pixel if blue > 50 (range 0-255)
		return pixel.b > 50;
	},
	color: ({ x, y, image }) => "#00C8FF",
	radius: () => Math.random() * 1.5 + 0.5,
	mass: () => 40,
	friction: () => 0.15,
	initialPosition: ({ canvasDimensions }) => {
		return new Vector(canvasDimensions.width / 2, canvasDimensions.height / 2);
	}
};

const motionForce = (x, y) => {
	return forces.disturbance(x, y, 5);
};
// ---------------------Banner Image Funcation End---------------------

const responsive = {
	desktop: {
		breakpoint: { max: 3000, min: 1025 },
		items: 4,
		slidesToSlide: 4 // optional, default to 1.
	},
	tablet: {
		breakpoint: { max: 1024, min: 768 },
		items: 2,
		slidesToSlide: 2 // optional, default to 1.
	},
	mobile: {
		breakpoint: { max: 767, min: 0 },
		items: 1,
		slidesToSlide: 1 // optional, default to 1.
	}
};

// ================================|| Home ||================================ //

const Home = () => {

	const [Name, setName] = useState("");
	const [Email, setEmail] = useState("");
	const [MobileNo, setMobileNo] = useState("");
	const [Country, setCountry] = useState("");
	const [Industry, setIndustry] = useState("");
	const [Image, setImage] = useState("");
	const [Description, setDescription] = useState("");
	const [message, setMessage] = useState("");
	const [Show, setShow] = useState(false);
	const handleClose = () => setShow(false);               // Open model close on click on a close button.
	const handleShow = () => setShow(true);                 // On button click Open the model.

	let submitHandler = async (e) => {
		e.preventDefault();
		try {
			var SignuploadformAPI = API.signuploadform;
			const signResponse = await fetch(SignuploadformAPI);
			const signData = await signResponse.json();
			const url = "https://api.cloudinary.com/v1_1/" + signData.cloudname + "/auto/upload";
			const files = document.querySelector("[type=file]").files;
			let file = files[0];
			const formData = new FormData();

			formData.append("file", file);
			formData.append("api_key", signData.apikey);
			formData.append("timestamp", signData.timestamp);
			formData.append("signature", signData.signature);
			formData.append("eager", "c_pad,h_300,w_400|c_crop,h_200,w_260");
			formData.append("folder", "website");

			const fileData = await fetch(url, {
				method: "POST",
				body: formData
			})
			const fileJson = await fileData.json();
			console.log(fileJson);

			var SendProjectDetailEmailAPI = API.sendProjectDetailEmail;
			let result = await fetch(SendProjectDetailEmailAPI, {
				method: "POST",
				headers: {
					'Accept': 'application/json',
					'Content-type': 'application/json',
				},
				json: true,
				body: JSON.stringify({
					name: Name,
					email: Email
				}),
			});
			let resJsons = await result.json();
			if (resJsons.status === "SUCCESS") {
				var SaveProjectDetailAPI = API.saveProjectDetail;
				let res = await fetch(SaveProjectDetailAPI, {
					method: "POST",
					headers: {
						'Accept': 'application/json',
						'Content-type': 'application/json',
					},
					json: true,
					body: JSON.stringify({
						name: Name,
						email: Email,
						mobileNo: MobileNo,
						country: Country,
						industry: Industry,
						image: fileJson.secure_url,
						description: Description,
					}),
				});
				let resJson = await res.json();
				if (res.status === 200) {
					setName("");
					setEmail("");
					setMobileNo("");
					setCountry("");
					setIndustry("");
					setImage("");
					setDescription("");
					setMessage("Your response has been submitted sucessfully.");
				}
			}
		} catch (error) {
			console.log(error);
		}
	};

	let iconStyles = { color: "#000", fontSize: "3.5em" };

	return (
		<>
			<Helmet>
				<title>Home - The Bridge Code</title>
			</Helmet>
			<Modal show={Show} style={{ marginTop: "300px" }}>
				<Modal.Header>
					<Modal.Title style={{ fontSize: "1.3rem" }}>
						Your response has been submitted sucessfully.<br />
						<input type="submit" value="Ok" style={{ width: "fit-content", marginLeft: "200px" }} onClick={handleClose} className="cl-button -primary -small rounded-2 py-2" />
					</Modal.Title>
				</Modal.Header>
			</Modal>

			{/* Website Banner */}
			<div className="m-0 container-fluid home p-0 pt-2">
				<div className="container align-self-center">
					<div className="row justify-content-center text-white mx-auto flex-column-reverse flex-lg-row pt-4 pt-lg-0">
						<div className='col-lg-5 col-md-10 col-sm-12'>
							<h4 className="">Welcome to</h4>
							<h2 className="display-3 fw-bold title">The Bridge Code</h2>
							<p>We at Bridgecode Technologies LLP always have positive approach towards our Client’s Ideas and we do give suggestions to our Client about latest Technology
								and Trends.</p>
							<p>We are a Software Development and Consulting Firm with a Unique and Client First Approach that's why our Motto is <span className='h5'>"Your Business Our Perfection"</span></p>
							<ul className='p-0' style={{ listStyle: "none" }}>
								<li className='mb-2'><b>What you can Expect from us :</b></li>
								<li><FaArrowRight className='blink' size={20} style={{ color: "#0097c0" }} /> Security and Trust</li>
								<li><FaArrowRight className='blink' size={20} style={{ color: "#0097c0" }} /> 24x7 Availability</li>
								<li><FaArrowRight className='blink' size={20} style={{ color: "#0097c0" }} /> Timezone Flexibility</li>
								<li><FaArrowRight className='blink' size={20} style={{ color: "#0097c0" }} /> Flexible Pricing Structure</li>
							</ul>
						</div>
						<div className="col-lg-7 col-md-10 col-sm-12 text-center align-self-center">

							<ParticleImage
								src={"https://res.cloudinary.com/dm1muiikf/image/upload/v1684484853/website/the_bridge_code.png"}
								width={750}
								height={450}
								scale={3.75}
								entropy={10}
								maxParticles={4000}
								particleOptions={particleOptions}
								mouseMoveForce={motionForce}
								touchMoveForce={motionForce}
								backgroundColor="#000000"
							/>
						</div>
					</div>
				</div>
			</div>

			{/* <!-- See all services--> */}
			<div className="company">
				<div className="container d-flex">
					<div className="row justify-content-center">
						<div className="col-lg-7 col-md-10 col-sm-12 text-center">
							<img src="https://res.cloudinary.com/dm1muiikf/image/upload/v1684484853/website/about-us1.jpg" layout="responsive" alt="The Bridgecode about1" className='firstimg border border-3 rounded-3 border-white' />
							<img src="https://res.cloudinary.com/dm1muiikf/image/upload/v1684484853/website/about-us3.jpg" layout="responsive" alt="The Bridgecode about2" className='secondimg border border-3 rounded-3 border-white' />
						</div>
						<div className='col-lg-5 col-md-10 col-sm-12 align-self-center'>
							<h2 className="display-3 fw-bold title">Who we are?</h2>
							<p>We specialize in designing and development of scalable solutions for startups and enterprises.</p>
							<p>Since our founding in 2017, our team has assisted numerous startups and enterprises in implementing software. These Software/Products have been used by Top Multinational and Fortune 500 Companies.</p>
							<p>We are an certified company based in India and Expanding Rapidly.</p>
							<div className='d-flex justify-content-center'><Link to='../about-us' className='cl-button -primary -small mt-3 mx-auto'>Company Overview <FaAngleDoubleRight /></Link></div>
						</div>
					</div>
				</div>
			</div>

			{/* Our Expertise */}
			<div className='container company_d' id='Expertise'>
				<div className='col-md-12'>
					<div className='row'>
						<h1 className=' display-3 title-c fw-bold'>Our Expertise</h1>
						<div className='col-md-3 company_border py-3'>
							<span>
								<p className='circl'><GrSettingsOption style={iconStyles} /></p>
							</span>
							{/* <p><GrSettingsOption value={{ color: 'blue' }} /></p> */}
							<h4 className='blue_cl'>Custom Software Development</h4>
							<p>We design software applications that meet the specific needs of an individual or a company tailored with modern and latest Technologies.</p>
						</div>
						<div className='col-md-3 company_border py-3'>
							<span>
								<p className='circl'><GrCloudComputer style={iconStyles} /></p>
							</span>
							<h4 className='blue_cl'>Web Development</h4>
							<p>It involve everything related to building a web based solution, whether it is a simple text page or a complex web application.</p>
						</div>
						<div className='col-md-3 company_border py-3'>
							<span>
								<p className='circl'><FaAndroid style={iconStyles} /></p>
							</span>
							<h4 className='blue_cl'>Mobile App Development</h4>
							<p>Rise of Mobile devices it is very much important for the business to get exposure across smartphones and their apps. We Develop the most intuitive, scalable and
								secure Mobile app for
								the mobile first world.</p>
						</div>
						<div className='col-md-3 company_border py-3'>
							<span>
								<p className='circl'><FaBuffer style={iconStyles} /></p>
							</span>
							<h4 className='blue_cl'>Product Development</h4>
							<p>Whether it'a a newly created or rebranded goods or services we design and develop a series of steps that includes the conceptualization, design, development and
								marketing of a Product we will help you in your product's entire journey --
								from the initial idea to its market release.</p>
						</div>
						<div className='col-md-3 company_border py-3'>
							<span>
								<p className='circl'><FaHandshake style={iconStyles} /></p>
							</span>
							<h4 className='blue_cl'>Dedicated Team/Resource</h4>
							<p>By Hiring a Dedicated Resource you can get Flexibility & Cost Effectiveness with Great Skills and Expertise with a very Quick Turnaround Time for your projects
								which helps in Today's Competitive market.</p>
						</div>
						<div className='col-md-3 company_border py-3'>
							<span>
								<p className='circl'><FaSearchengin style={iconStyles} /></p>
							</span>
							<h4 className='blue_cl'>Testing & QA</h4>
							<p>Are you sure your software product or application does what it is supposed to do as there is no room for bugs, we provide testing services which reduce SDLC times
								and production costs.</p>
						</div>
						<div className='col-md-3 company_border py-3'>
							<span>
								<p className='circl'><TbApi style={iconStyles} /></p>
							</span>
							<h4 className='blue_cl'>API Development & Integration</h4>
							<p>Data Exchange between applications happens using API, we can help you develop as well as Integrate any API you wish to despite the Industry and complexity of
								the API</p>
						</div>
						<div className='col-md-3 company_border py-3'>
							<span>
								<p className='circl'><FaFileExport style={iconStyles} /></p>
							</span>
							<h4 className='blue_cl'>Hosting Services</h4>
							<p>Are you planning to take your identity online or want to host a small webstie without increasing your costs too much. Our web servers are Located in Tier 4
								Datacenter with multiple premium bandwidth providers which gives a super fast server response time.</p>
						</div>
						<div className='col-md-3 company_border py-3'>
							<span>
								<p className='circl'><FaServer style={iconStyles} /></p>
							</span>
							<h4 className='blue_cl'>Server Support</h4>
							<p>Having a Server Support system on hand ensures that your computer network systems are operating efficiently and reliably. If your server goes down, your whole
								network is affected, from the daily operation of your business.</p>
						</div>
						<div className='col-md-3 company_border py-3'>
							<span>
								<p className='circl'><GrServer style={iconStyles} /></p>
							</span>
							<h4 className='blue_cl'>VPS/VDS Support</h4>
							<p>VPS is powerful, dedicated servers and affordable virtual dedicated servers allow you to run your own VMs.</p>
						</div>
						<div className='col-md-3 d-flex align-items-center justify-content-center'>
							<Link to='#quotation' className="cl-button -primary -big mx-1">Get Free Quotation  <FaAngleDoubleRight size={30} /></Link>
						</div>
					</div>
				</div>
			</div>

			{/* Comapny language */}
			<div className="container-fluid p-md-5 py-5 language" id='language'>
				<div className='container'>
					<div className="text-center mx-5">
						<h1 className="title-c">Our Custom Software Development Services</h1>
					</div>
					<div className="row text-center justify-content-center">
						<div className="col-xl-4 align-middle pt-5 mb-2">
							<h3 className='fw-4'>We are</h3>
							<h2 className='display-3 fw-bold'>MASTERS</h2>
							<Link to='/services' className="cl-button -primary -big mx-1 rounded">Services <FaAngleDoubleRight /></Link>
						</div>
						<div className="col-xl-4 col-md-6 my-2">
							<div className="d-flex flex-column align-items-center justify-content-between h-100 bg-body rounded shadow-sl py-2 px-3">
								<img src="https://res.cloudinary.com/dm1muiikf/image/upload/v1684484853/website/asp.png" alt="Aps.net" width={100} className="img-fluid pb-3" />
								<h4 className='text-dark'>ASP.NET</h4>
								<p className='text-dark'>A perfect choice for the emerging businesses, ASP.NET assists in developing secure websites and applications.</p>
								<Link to='../services' className='text-center px-3 py-2 cl-button -primary text-decoration-none text-sky'>Read more...</Link>
							</div>
						</div>
						<div className="col-xl-4 col-md-6 my-2">
							<div className="d-flex flex-column align-items-center justify-content-between h-100 bg-body rounded shadow-sl py-2 px-3">
								<img src="https://res.cloudinary.com/dm1muiikf/image/upload/v1684484853/website/nodejs.png" alt="nodejs" width={100} className="img-fluid pb-3" />
								<h4 className='text-dark'>Node</h4>
								<p className='text-dark '>Node. js is an open-source, cross-platform JavaScript runtime environment and library for running web applications that can run on Windows,
									Linux, Unix, macOS, and more..</p>
								<Link to='../services' className='text-center px-3 py-2 cl-button -primary text-decoration-none text-sky'>Read more...</Link>
							</div>
						</div>
					</div>
				</div>
			</div>

			{/* Business Development */}
			<div className='container-fluid h_service' id='home_services'>
				<div className='container col-md-12'>
					<div className='row'>
						<h1 className=' display-3 title-c fw-bold'>Our Process</h1>
						<div className="col-sm-4">
							<div className="service_inner left-panel">
								<ul>
									<li className="service_bxx row">
										<div className='col-sm-8'>
											<h4 className="service_title float-right">Maintenance & Support</h4>
											<p className='text-left'>Here we will provide Support Upon the Project Length and Model Client choose to work with us.</p>
										</div>
										<div className='col-sm-4'>
											<div className="service_icon center " data-aos="zoom-in-up">
												<p><RiNumber8 size={70} className="text-center align-middle" /></p>
											</div>
										</div>
									</li>
									<li className="service_bxx row">
										<div className='col-sm-8'>
											<h4 className="service_title float-right">Code Handover</h4>
											<p className='text-left'>Once all the Testing is done and Formalities has been completed we will hand over the Source Code.</p>
										</div>
										<div className='col-sm-4'>
											<div className="service_icon center " data-aos="zoom-in-up">
												<p><RiNumber7 size={70} className="text-center align-middle" /></p>
											</div>
										</div>
									</li>
									<li className="service_bxx row">
										<div className='col-sm-8'>
											<h4 className="service_title float-right">Deployment</h4>
											<p className='text-left'>Once Everything is Completed and Checked we will make it Live.</p>
										</div>
										<div className='col-sm-4'>
											<div className="service_icon center " data-aos="zoom-in-up">
												<p><RiNumber6 size={70} className="text-center align-middle" /></p>
											</div>
										</div>
									</li>

								</ul>
							</div>
						</div>
						<div className='col-sm-4'>
							<div className="service_inner2">
								<div className="top_section service_bxx">
									<p className='text-center'>The Process of determining what your projects need to achieve and what needs to be created to make that happen.</p>
									<h4 className="service_title text-center">Requirement Gathering</h4>
									<div className="service_icon spin center" data-aos="zoom-in-up">
										<p><RiNumber1 size={70} className="text-center align-middle" /></p>
									</div>
								</div>
							</div>
							<div className='main_circle'>
								<div className="main_cirvc_crc">
									<img src='https://res.cloudinary.com/dm1muiikf/image/upload/v1684484853/website/03.png' className='img-fluid' alt='03' />
								</div>
							</div>
							<div className="service_inner2">
								<div className="bottom_section service_bxx">
									<div className="service_icon spin center" data-aos="zoom-in-up">
										<p><RiNumber5 size={70} className="text-center align-middle" /></p>
									</div>
									<h4 className="service_title text-center">Testing Phase</h4>
									<p>This phase includes quality assurance testing (QA) and User acceptance testing (UAT).</p>
								</div>
							</div>
						</div>
						<div className="col-sm-4 p-0">
							<div className="service_inner right-panel">
								<ul>
									<li className="service_bxx row">
										<div className='col-sm-4 p-0'>
											<div className="service_icon" data-aos="zoom-in-up">
												<p><RiNumber2 size={70} className="text-center align-middle " /></p>
											</div>
										</div>
										<div className='col-sm-8 p-0'>
											<h4 className="service_title text-left">Freezing the Requirements</h4>
											<p className='text-left'>We will Discuss and Brainstorm after we gather the requirements and Freeze it for Smooth Development process.</p>
										</div>
									</li>
									<li className="service_bxx row">
										<div className='col-sm-4'>
											<div className="service_icon" data-aos="zoom-in-up">
												<p><RiNumber3 size={70} className="text-center align-middle text-" /></p>
											</div>
										</div>
										<div className='col-sm-8'>
											<h4 className="service_title text-left">On-Boarding and <br />Introduction with Team</h4>
											<p className='text-left'>Here we will Introduce you to the Team who will work on your Project.</p>
										</div>
									</li>
									<li className="service_bxx row">
										<div className='col-sm-4'>
											<div className="service_icon" data-aos="zoom-in-up">
												<p><RiNumber4 size={70} className="text-center align-middle text-" /></p>
											</div>
										</div>
										<div className='col-sm-8'>
											<h4 className="service_title text-right">Development Sprints</h4>
											<p className='text-left'>We will Prepare a Sprint Document where Module wise Development date and time of delivery will be written.</p>
										</div>
									</li>
								</ul>
							</div>
						</div>
					</div>
				</div>
			</div>

			{/* Mobile responsive Business Development */}
			<div className='container-fluid h_service_mobile' id='h_service_mobile'>
				<div className='container-fluid col-md-12'>
					<div className='row'>
						<h1 className=' display-3 title-c fw-bold'>Our Process</h1>
						<div className="col-md-4">
							<div className="service_inner left-panel">
								<ul className='p-0'>
									{/* 1 */}
									{/* <div className="top_section service_bxx">
										<p className='text-center'>The Process of determining what your projects need to achieve and what needs to be created to make that happen.</p>
										<h4 className="service_title text-center">Requirement Gathering</h4>
										<div className="service_icon spin center" data-aos="zoom-in-up">
											<p><RiNumber1 size={70} className="text-center align-middle" /></p>
										</div>
									</div> */}
									<li className="service_bxx row">
										<div className='col-sm-4 p-0'>
											<div className="service_icon" data-aos="zoom-in-up">
												<p><RiNumber1 size={70} className="text-center align-middle" /></p>
											</div>
										</div>
										<div className='col-sm-8 p-0'>
											<h4 className="service_title text-center">Requirement Gathering</h4>
											<p className='text-center'>The Process of determining what your projects need to achieve and what needs to be created to make that happen.</p>
										</div>
									</li>
									{/* 2 */}
									<li className="service_bxx row">
										<div className='col-sm-4 p-0'>
											<div className="service_icon" data-aos="zoom-in-up">
												<p><RiNumber2 size={70} className="text-center align-middle " /></p>
											</div>
										</div>
										<div className='col-sm-8 p-0'>
											<h4 className="service_title text-left">Freezing the Requirements</h4>
											<p className='text-left'>We will Discuss and Brainstorm after we gather the requirements and Freeze it for Smooth Development process.</p>
										</div>
									</li>
									{/* 3 */}
									<li className="service_bxx row">
										<div className='col-sm-4'>
											<div className="service_icon" data-aos="zoom-in-up">
												<p><RiNumber3 size={70} className="text-center align-middle text-" /></p>
											</div>
										</div>
										<div className='col-sm-8'>
											<h4 className="service_title text-left">On-Boarding and <br />Introduction with Team</h4>
											<p className='text-left'>Here we will Introduce you to the Team who will work on your Project.</p>
										</div>
									</li>
									{/* 4 */}
									<li className="service_bxx row">
										<div className='col-sm-4'>
											<div className="service_icon" data-aos="zoom-in-up">
												<p><RiNumber4 size={70} className="text-center align-middle text-" /></p>
											</div>
										</div>
										<div className='col-sm-8 text-center'>
											<h4 className="service_title text-right">Development Sprints</h4>
											<p className='text-left'>We will Prepare a Sprint Document where Module wise Development date and time of delivery will be written.</p>
										</div>
									</li>
								</ul>
							</div>
						</div>
						<div className='col-md-4 my-4'>
							<div className='main_circle'>
								<div className="main_cirvc_crc">
									<img src='https://res.cloudinary.com/dm1muiikf/image/upload/v1684484853/website/03.png' className='img-fluid' alt='03' />
								</div>
							</div>
						</div>
						<div className="col-md-4 p-0">
							<div className="service_inner right-panel">
								<ul className='p-0'>
									{/* 5 */}
									<li className="service_bxx row">
										<div className='col-sm-4'>
											<div className="service_icon spin center" data-aos="zoom-in-up">
												<p><RiNumber5 size={70} className="text-center align-middle" /></p>
											</div>
										</div>
										<div className='col-sm-8'>
											<h4 className="service_title text-center">Testing Phase</h4>
											<p>This phase includes quality assurance testing (QA) and User acceptance testing (UAT).</p>
										</div>
									</li>
									{/* 6 */}
									<li className="service_bxx row">
										<div className='col-sm-4'>
											<div className="service_icon center " data-aos="zoom-in-up">
												<p><RiNumber6 size={70} className="text-center align-middle" /></p>
											</div>
										</div>
										<div className='col-sm-8'>
											<h4 className="service_title float-right">Deployment</h4>
											<p className='text-left'>Once Everything is Completed and Checked we will make it Live.</p>
										</div>
									</li>
									{/* 7 */}
									<li className="service_bxx row">
										<div className='col-sm-4'>
											<div className="service_icon center " data-aos="zoom-in-up">
												<p><RiNumber7 size={70} className="text-center align-middle" /></p>
											</div>
										</div>
										<div className='col-sm-8'>
											<h4 className="service_title float-right">Code Handover</h4>
											<p className='text-left'>Once all the Testing is done and Formalities has been completed we will hand over the Source Code.</p>
										</div>
									</li>
									{/* 8 */}
									<li className="service_bxx row">
										<div className='col-sm-4'>
											<div className="service_icon center " data-aos="zoom-in-up">
												<p><RiNumber8 size={70} className="text-center align-middle" /></p>
											</div>
										</div>
										<div className='col-sm-8'>
											<h4 className="service_title float-right">Maintenance & Support</h4>
											<p className='text-left'>Here we will provide Support Upon the Project Length and Model Client choose to work with us.</p>
										</div>
									</li>
								</ul>
							</div>
						</div>
					</div>
				</div>
			</div>

			{/* Industries In Focus */}
			<div className='container indust' id='industry1'>
				<div className='row'>
					<div className=' mb-4 pb-5'>
						<p className='display-3 fw-bold title-c'>Industries In Focus</p>
						<p className='mx-auto px-5 '>We are a one-stop solution for companies looking to outsource software development services. Being a trusted software development outsourcing company, we assist
							clients from different industries in all phases of their software development needs.</p>
					</div>
					<Tab.Container id="left-tabs-example" defaultActiveKey="first" className="mt-5">
						<Row className='bg-light indust_title'>
							<Col md={3} className="" style={{ marginTop: "-25px", marginLeft: "-15px" }}>
								<Nav variant="pills" className="flex-column text-left d-block py-4" fill="true" style={{ backgroundColor: "#0b3155" }}>
									<Nav.Item className="mb-2">
										<Nav.Link eventKey="first" className='fw-bold text-white'>Supply Chain & Logistics</Nav.Link>
									</Nav.Item>
									<Nav.Item className="my-2 ">
										<Nav.Link eventKey="second" className='fw-bold text-white'>Travel & Hospitality</Nav.Link>
									</Nav.Item>
									<Nav.Item className="my-2 ">
										<Nav.Link eventKey="third" className='fw-bold text-white'>Hitech</Nav.Link>
									</Nav.Item>
									<Nav.Item className="mt-2 ">
										<Nav.Link eventKey="four" className='fw-bold text-white'>Healthcare & life Science</Nav.Link>
									</Nav.Item>
									<Nav.Item className="mt-2 ">
										<Nav.Link eventKey="five" className='fw-bold text-white'>Digital Retail</Nav.Link>
									</Nav.Item>
									<Nav.Item className="mt-2 ">
										<Nav.Link eventKey="six" className='fw-bold text-white'>Fintech</Nav.Link>
									</Nav.Item>
									<Nav.Item className="mt-2 ">
										<Nav.Link eventKey="seven" className='fw-bold text-white'>Media & Advertising</Nav.Link>
									</Nav.Item>
								</Nav>
							</Col>
							<Col md={9} className="ps-5 my-2 bg-white indust_containt" >
								<Tab.Content className='conta'>
									<Tab.Pane eventKey="first" className=''>
										<img src='https://res.cloudinary.com/dm1muiikf/image/upload/v1684484853/website/logistic.jpg' className='img-fluid image' alt='logistic' />
										<div className="middle">
											<h1 className='text'>Supply Chain Management In The Service Industry</h1>
											<p className='text'>There seems to be confusion when it comes to the term “supply chain.” Many people picture tractor-trailers, large container
												ships, forklifts, and huge warehouses – but what about the service industry? What many do not realize is that a “supply chain” is not only for
												tangible products, but also for services offered.</p>
											<Link to='/case-study/supplychain' className="cl-button -primary -small mx-2 rounded">View More <FaAngleDoubleRight /></Link>
										</div>
									</Tab.Pane>
									<Tab.Pane eventKey="second">
										<img src='https://res.cloudinary.com/dm1muiikf/image/upload/v1684484853/website/travel_tourism.png' className='img-fluid image' alt='travel_tourism' />
										<div className="middle">
											<h1 className='text'>Travel & Hospitality</h1>
											<p className='text'>Not so long ago, computer reservation systems revolutionized the travel and hospitality industry. The evolution of the internet, the smartphone and other technologies now provide significant convenience and savings to travel consumers. Websites that allow travelers to find the best deals, self-guided tour audio apps and real-time updates on flight times and arrivals are now standard travel tools, accessible to both travel professionals and consumers alike. </p>
											<Link to='/case-study/travel' className="cl-button -primary -small mx-2 rounded">View More <FaAngleDoubleRight /></Link>
										</div>
									</Tab.Pane>
									<Tab.Pane eventKey="third">
										<img src='https://res.cloudinary.com/dm1muiikf/image/upload/v1684484853/website/hitech.jpg' className='img-fluid image' height={550} alt='hitech' />
										<div className="middle">
											<h1 className='text'>Hitech</h1>
											<p className='text'>Technology continues to be a critical force for change in the world. Technology breakthroughs give enterprises more possibilities to lift their productivity and invent offerings. And while it remains difficult to forecast how technology trends will play out, business leaders can plan ahead better by watching the development of new technologies, anticipating how companies could utilize them, and understanding the factors that impact innovation and adoption. </p>
											<Link to='/case-study/hitech' className="cl-button -primary -small mx-2 rounded">View More <FaAngleDoubleRight /></Link>
										</div>
									</Tab.Pane>
									<Tab.Pane eventKey="four">
										<img src='https://res.cloudinary.com/dm1muiikf/image/upload/v1684484853/website/medical.jpg' className='img-fluid image' alt='medical' />
										<div className="middle">
											<h1 className='text'>Healthcare</h1>
											<p className='text'>Healthcare software systems provide numerous benefits, ranging from improved health management to reduced medication errors. These rising technological advancements help in healthcare administration and operations while also improving patient experiences. Explore our Healthcare Case Study for more details.</p>
											<Link to='/case-study/healthcare' className="cl-button -primary -small mx-2 rounded">View More <FaAngleDoubleRight /></Link>
										</div>
									</Tab.Pane>
									<Tab.Pane eventKey="five">
										<img src='https://res.cloudinary.com/dm1muiikf/image/upload/v1684484853/website/digital_retail.jpg' className='img-fluid image' alt='digital_retail' />
										<div className="middle">
											<h1 className='text'>Digital Retail</h1>
											<p className='text'>Can you transform as quickly as your customers’ expectations change?<br />
												Digital transformation in the retail industry is about improving customer experience and driving growth using technology. Retail leaders are already creating an impact using digital transformation as it is the need of the hour. Only retailers who embrace the digital revolution will be the ones who thrive and grow.</p>
											<Link to='/case-study/digitalretail' className="cl-button -primary -small mx-2 rounded">View More <FaAngleDoubleRight /></Link>
										</div>
									</Tab.Pane>
									<Tab.Pane eventKey="six">
										<img src='https://res.cloudinary.com/dm1muiikf/image/upload/v1684484853/website/fintech.jpg' className='img-fluid image' alt='fintech' />
										<div className="middle">
											<h1 className='text'>Fintech</h1>
											<p className='text'>Fintech stands for financial technology, and it refers to a range of technology-driven financial services. There are many fintech applications emerging in the market. The innovations range from peer-to-peer sharing apps to mobile banking apps to stock market trading apps. We can help you develop Fintech Applications which will help your businesses get ahead of the competition and stay there.</p>
											<Link to='/case-study/fintech' className="cl-button -primary -small mx-2 rounded">View More <FaAngleDoubleRight /></Link>
										</div>
									</Tab.Pane>
									<Tab.Pane eventKey="seven">
										<img src='https://res.cloudinary.com/dm1muiikf/image/upload/v1684484853/website/media_entertainment.png' className='img-fluid image' alt='media_entertainment' />
										<div className="middle">
											<h1 className='text'>Media & Advertising</h1>
											<p className='text'>Want to strengthen your customer-oriented approach and help your clients market their products in the most effective way? We offers game-changing solutions that allow for not only improving the current marketing Trends.</p>
											<Link to='/case-study/mediaadvertising' className="cl-button -primary -small mx-2 rounded">View More <FaAngleDoubleRight /></Link>
										</div>
									</Tab.Pane>
								</Tab.Content>
							</Col>
						</Row>
					</Tab.Container>
				</div>
			</div>

			{/* Our Partner */}
			<div className='container-fluid partner' id='partner'>
				<div className='container'>
					<div className='text-center'>
						<h1 className='display-3 title-c'>Our Partner</h1>
					</div>
					<div className="container mt-4 mb-5">
						<div className="row mx-auto">
							<Carousel
								swipeable={true}
								draggable={false}
								showDots={true}
								responsive={responsive}
								ssr={true} // means to render carousel on server-side.
								infinite={true}
								autoPlay={true}
								autoPlaySpeed={3000}
								keyBoardControl={true}
								customTransition="all .5"
								transitionDuration={500}
								containerClass="carousel-container p-0"
								removeArrowOnDeviceType={["tablet", "mobile", "desktop"]}
								dotListClass="custom-dot-list-style"
								// itemClass="carousel-item-padding-40-px"
								height="600px"
							>
								<div className="p-2 mb-2 mx-auto">
									<Card style={{ padding: "10px", maxWidth: "10rem", marginInline: "auto" }}>
										<Card.Img variant="top" src="https://res.cloudinary.com/dm1muiikf/image/upload/v1684484853/website/azure.png" alt="Testimonials" />
									</Card>
								</div>
								<div className="p-2 mb-2 mx-auto">
									<Card style={{ padding: "10px", maxWidth: "10rem", marginInline: "auto" }}>
										<Card.Img variant="top" src="https://res.cloudinary.com/dm1muiikf/image/upload/v1684484853/website/vultr.png" alt="Testimonials" />
									</Card>
								</div>
								<div className="p-2 mb-2 mx-auto">
									<Card style={{ padding: "10px", maxWidth: "10rem", marginInline: "auto" }}>
										<Card.Img variant="top" src="https://res.cloudinary.com/dm1muiikf/image/upload/v1684484853/website/aws-partner.png" alt="Testimonials" />
									</Card>
								</div>
								<div className="p-2 mb-2 mx-auto">
									<Card style={{ padding: "10px", maxWidth: "10rem", marginInline: "auto" }}>
										<Card.Img variant="top" src="https://res.cloudinary.com/dm1muiikf/image/upload/v1684484853/website/Elastic-Email-logo-horizontal.jpg" alt="Testimonials" />
									</Card>
								</div>
								<div className="p-2 mb-2 mx-auto">
									<Card style={{ padding: "10px", maxWidth: "7rem", marginInline: "auto" }}>
										<Card.Img variant="top" src="https://res.cloudinary.com/dm1muiikf/image/upload/v1684484853/website/google_work.png" alt="Testimonials" />
									</Card>
								</div>
								<div className="p-2 mb-2 mx-auto">
									<Card style={{ padding: "10px", maxWidth: "7rem", marginInline: "auto" }}>
										<Card.Img variant="top" src="https://res.cloudinary.com/dm1muiikf/image/upload/v1684484853/website/sap.png" alt="Testimonials" />
									</Card>
								</div>
								<div className="p-2 mb-2 mx-auto">
									<Card style={{ padding: "10px", maxWidth: "7rem", marginInline: "auto" }}>
										<Card.Img variant="top" src="https://res.cloudinary.com/dm1muiikf/image/upload/v1684484853/website/godaddy.png" alt="Testimonials" />
									</Card>
								</div>
								<div className="p-2 mb-2 mx-auto">
									<Card style={{ padding: "10px", maxWidth: "5rem", marginInline: "auto" }}>
										<Card.Img variant="top" src="https://res.cloudinary.com/dm1muiikf/image/upload/v1684484853/website/ssl.png" alt="Testimonials" />
									</Card>
								</div>
								<div className="p-2 mb-2 mx-auto">
									<Card style={{ padding: "10px", maxWidth: "7rem", marginInline: "auto" }}>
										<Card.Img variant="top" src="https://res.cloudinary.com/dm1muiikf/image/upload/v1684484853/website/contabologo.png" alt="Testimonials" />
									</Card>
								</div>
								<div className="p-2 mb-2 mx-auto">
									<Card style={{ padding: "10px", maxWidth: "7rem", marginInline: "auto" }}>
										<Card.Img variant="top" src="https://res.cloudinary.com/dm1muiikf/image/upload/v1684484853/website/zerossl.png" alt="Testimonials" />
									</Card>
								</div>
								<div className="p-2 mb-2 mx-auto">
									<Card style={{ padding: "10px", maxWidth: "6rem", marginInline: "auto" }}>
										<Card.Img variant="top" src="https://res.cloudinary.com/dm1muiikf/image/upload/v1684484853/website/csd/oracle.png" alt="Testimonials" />
									</Card>
								</div>
								<div className="p-2 mb-2 mx-auto">
									<Card style={{ padding: "10px", maxWidth: "7rem", marginInline: "auto" }}>
										<Card.Img variant="top" src="https://res.cloudinary.com/dm1muiikf/image/upload/v1684484853/website/c-panel.webp" alt="Testimonials" />
									</Card>
								</div>

								<div className="p-2 mb-2 mx-auto">
									<Card style={{ padding: "10px", maxWidth: "7rem", marginInline: "auto" }}>
										<Card.Img variant="top" src="https://res.cloudinary.com/dm1muiikf/image/upload/v1684484853/website/Microsoft-logo_rgb_c-gray.webp" alt="Testimonials" />
									</Card>
								</div>
							</Carousel>
						</div>
					</div>
				</div>
			</div>

			{/* Testimonial */}
			<div className="container mt-4 mb-5">
				<div className="row mx-auto">
					<h1 className="mb-4 display-3 title-c">Testimonials</h1>
					<Carousel
						swipeable={true}
						draggable={false}
						showDots={true}
						responsive={responsive}
						ssr={true} // means to render carousel on server-side.
						infinite={true}
						autoPlay={true}
						autoPlaySpeed={3000}
						keyBoardControl={true}
						customTransition="all .5"
						transitionDuration={500}
						containerClass="carousel-container p-0 pb-3"
						removeArrowOnDeviceType={["tablet", "mobile", "desktop"]}
						dotListClass="custom-dot-list-style"
						// itemClass="carousel-item-padding-40-px"
						height="600px"
					>
						<div className="p-2 mb-2 mx-auto">
							<Card className='card-align' style={{ padding: "10px", maxWidth: "22rem", marginInline: "auto" }}>
								<Card.Img variant="top" src="https://res.cloudinary.com/dm1muiikf/image/upload/v1684484853/website/User-avatar6.png" alt="Testimonials" />
								<Card.Body>
									<Card.Title>Barkha</Card.Title>
									<Card.Text>
										We were looking for an affordable Software Development company and we found TheBridgeCode. They developed our website and we saw an immediate
										increase in our sales. They took the time to understand who we are as a company. We wholeheartedly recommend TheBridgeCode to everyone we know.
									</Card.Text>
								</Card.Body>
							</Card>
						</div>
						<div className="p-2 mb-2 mx-auto">
							<Card className='card-align' style={{ padding: "10px", maxWidth: "22rem", marginInline: "auto" }}>
								<Card.Img variant="top" src="https://res.cloudinary.com/dm1muiikf/image/upload/v1684484853/website/User-avatar2.png" alt="Testimonials" />
								<Card.Body>
									<Card.Title>Peter</Card.Title>
									<Card.Text>
										Bridgecode is a dedicated dotnet development team. Excellent skills and on budget. We continue to get dedicated quality development with
										Bridgecode and look forward to years of a great relationship ahead.
									</Card.Text>
								</Card.Body>
							</Card>
						</div>
						<div className="p-2 mb-2 mx-auto">
							<Card className='card-align' style={{ padding: "10px", maxWidth: "22rem", marginInline: "auto" }}>
								<Card.Img variant="top" src="https://res.cloudinary.com/dm1muiikf/image/upload/v1684484853/website/User-avatar3.png" alt="Testimonials" />
								<Card.Body>
									<Card.Title >Ricardo</Card.Title>
									<Card.Text>
										I started with one website work with TheBridgeCode and thier Great service and attention to details kept me coming back! I work with them for all my website work. They
										have Great customer service and does everything possible to keep their customers happy. I can always count on their support!
									</Card.Text>
								</Card.Body>
							</Card>
						</div>
						<div className="p-2 mb-2 mx-auto">
							<Card className='card-align' style={{ padding: "10px", maxWidth: "22rem", marginInline: "auto" }}>
								<Card.Img variant="top" src="https://res.cloudinary.com/dm1muiikf/image/upload/v1684484853/website/User-avatar1.png" alt="Testimonials" />
								<Card.Body>
									<Card.Title>Michelle</Card.Title>
									<Card.Text>
										I am really happy to work with TheBridgeCode. They assigned a project manager as a single point of contact and he kept me informed
										throughtout the development process. The QA Automation team is really helpful in support of regular delivery process of the product.
									</Card.Text>
								</Card.Body>
							</Card>
						</div>
						<div className="p-2 mb-2 mx-auto">
							<Card className='card-align' style={{ padding: "10px", maxWidth: "22rem", marginInline: "auto" }}>
								<Card.Img variant="top" src="https://res.cloudinary.com/dm1muiikf/image/upload/v1684484853/website/User-avatar5.png" alt="Testimonials" />
								<Card.Body>
									<Card.Title >Harry</Card.Title>
									<Card.Text>
										Before we partnered with TheBridgeCode, we had an extremely outdated infrastructure and Technology that wasn't up to latest IIndustry
										Standards. TheBridgeCode replaced our Software and put us into a domain environment, and kept us informed in every step of the Process.
										Essentially, they brought us up-to-date.
									</Card.Text>
								</Card.Body>
							</Card>
						</div>
						<div className="p-2 mb-2 mx-auto">
							<Card className='card-align' style={{ padding: "10px", maxWidth: "22rem", marginInline: "auto" }}>
								<Card.Img variant="top" src="https://res.cloudinary.com/dm1muiikf/image/upload/v1684484853/website/User-avatar4.png" alt="Testimonials" />
								<Card.Body>
									<Card.Title >Mike</Card.Title>
									<Card.Text>
										It has been a pleasure working with you and your staff, and I can only rave over the high standards that you have maintained relative to
										our hardware and software. Team is always available in times of Need and Emergency.
									</Card.Text>
								</Card.Body>
							</Card>
						</div>
					</Carousel>
				</div>
			</div>

			{/* <!-- Cta Demo--> */}
			<div className="container-fluid p-0 m-0">
				<div className="parallax-container py-5 project">
					<div className="parallax-content section-xl">
						<div className="container">
							<div className="row justify-content-end text-right">
								<div className="col-sm-7 text-center">
									<h3 className="display-4 fw-bold text-white mb-5">Why The Bridge Code?</h3>
									<p className='text-light mb-4'>BridgeCode Technologies is a Firm where we provide software development, integrations, and resources you need to compete
										in the Fast changing world. We believe in the power of marketing, sales, content management and great customer service are key to success in any
										Business. We have started a New Approach for our Clients where they can see the Potential of our Skills and Software. Try our Free Demo! </p>
									<p className='mt-3 text-white fs-4 fw-bold'>If you have the Passion, We have the Plan!!</p>
									<div className="mt-4" id='quotation'>
										<Link to='/solution' className="cl-button -primary -small mx-1">Try Free Demo</Link>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			{/* Index Form */}
			<div className='container my-5'>
				<div className="container">
					<div className="row justify-content-center">
						<div className="col-md-12">
							{/* <h1>asdas</h1> */}
							<div className="row justify-content-center">
								<div className="col-md-5">
									<h3 className="heading pb-4 text-center">Let's talk about your Projects!</h3>
									<p><img src="https://res.cloudinary.com/dm1muiikf/image/upload/v1684484853/website/undraw-contact.svg" alt="undraw-contact" className="img-fluid" /></p>
								</div>
								<div className="col-md-6">
									<form className="mb-5" id="inquiry-form" onSubmit={submitHandler}>
										<div className="row">
											<div className="col-md-12 form-group py-2">
												<input type="text" className="form-control bg-light" name="name" id="name" placeholder="Full Name *" value={Name} onChange={(e) => setName(e.target.value)} required />
											</div>
										</div>
										<div className="row">
											<div className="col-md-12 form-group py-2">
												<input type="email" className="form-control bg-light" name="email" id="email" placeholder="Email *" value={Email} onChange={(e) => setEmail(e.target.value)} required />
											</div>
										</div>
										<div className="row">
											<div className="col-md-12 form-group py-2">
												<input type="number" className="form-control bg-light" name="mobileNo" id="mobileNo" placeholder="Mobile No *" value={MobileNo} onChange={(e) => setMobileNo(e.target.value)} required />
											</div>
										</div>
										<div className="row">
											<div className="col-md-12 form-group py-2">
												<input type="text" className="form-control bg-light" name="country" id="country" placeholder="Country *" value={Country} onChange={(e) => setCountry(e.target.value)} required />
											</div>
										</div>
										<div className="row">
											<div className="col-md-12 form-group py-2">
												<input type="text" className="form-control bg-light" name="industry" id="industry" placeholder="Industry" value={Industry} onChange={(e) => setIndustry(e.target.value)} />
											</div>
										</div>
										<div className='row'>
											<div className="col-md-12 form-group py-2">
												{/* <label for="exampleFormControlFile1">Example file input</label> */}
												<input type="file" aria-label="File" className="form-control-file bg-light" id="file" name='file' value={Image} onChange={(e) => setImage(e.target.value)} />
											</div>
										</div>
										<div className="row">
											<div className="col-md-12 form-group py-2">
												<textarea className="" name="message" id="message" placeholder="Write your message" value={Description} onChange={(e) => setDescription(e.target.value)} ></textarea>
											</div>
										</div>
										<div className="col-12 py-2">
											<div className="form-check">
												<input className="form-check-input" aria-label="checkbox" type="checkbox" name="subscribe" id="subscribe" />
												<label className="ps-3 form-check-label">
													I want to sign NDA First
												</label>
											</div>
										</div>
										<div className="row">
											<div className="col-md-12 py-1 d-block">
												<input type="submit" value="Send Email" onClick={handleShow} className="cl-button -primary -small rounded-2 py-2" />
												<div className="message">{message ? <p>{message}</p> : null}</div>
											</div>
										</div>
									</form>

									<div id="form-message-warning mt-4"></div>
									<div id="form-message-success">
										Your message was sent, thank you!
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default Home;