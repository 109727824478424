import { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { Cookies, useCookies } from "react-cookie";
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import Resizer from "react-image-file-resizer";
import API from '../../../middleware/API';
import moment from 'moment';

// ================================|| EditRole ||================================ //

function EditRole() {
  const navigate = useNavigate();
  const [params] = useSearchParams();
  const [Roledata, setRoleData] = useState();
  const [RoleName, SetRoleName] = useState("");                    // store the input values
  const [IsActive, SetIsActive] = useState("");                    // store the input values 
  const [cookie, SetCookie] = useCookies(['username']);
  const cookies = new Cookies();
  const user = cookies.get('id');

  useEffect(() => {
    getRoleById();
  }, []);

  let Rolesdata = "";

  const getRoleById = async () => {
    try {
      var GetRoleByIdAPI = API.getRoleById;
      GetRoleByIdAPI = GetRoleByIdAPI + "?id=" + params.get("Id");
      const response = await fetch(GetRoleByIdAPI);
      const jsonData = await response.json();
      setRoleData(jsonData.recordset);
      console.log(Roledata);
      Rolesdata = jsonData.recordset[0];
      SetRoleName(Rolesdata.Name);
      SetIsActive(Rolesdata.IsActive);
    }
    catch (error) {
      console.log(error);
    }
  };

 
  let submitHandler = async (e) => {
    e.preventDefault();
    
    var checked = false;
    if (document.querySelector('#IsActive:checked')) {
      checked = true;
    }

    try {
      var UpdateRoleAPI = API.updateRole;
      UpdateRoleAPI = UpdateRoleAPI + "?id=" + params.get("Id");
      let res = await fetch(UpdateRoleAPI, {
        method: "POST",
        headers: {
          'Accept': 'application/json',
          'Content-type': 'application/json',
        },
        json: true,
        body: JSON.stringify({
          name: RoleName,
          modifiedBy: user,
          isActive: checked
        }),
      });
      let resJson = await res.json();
      if (resJson.status === 200) {
        SetRoleName("");
        SetIsActive("");
        SetCookie("");
      }
      else
        navigate('/admin/roles');
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <Helmet>
        <title>Edit Role - The Bridge Code</title>
      </Helmet>
      <div className="container p-0">
        <div className="container row pt-5">
          <div className="col">
            <h3 className="card-header px-3 pb-3">Edit Role Details</h3>
          </div>
          <div className="col" style={{ textAlign: "end" }}>
            <Link to='/admin/roles' className="btn btn-info cl-button -primary">Back To List</Link>
          </div>
        </div>
        <div className='container'>
          <form className="pt-4" id='myform' onSubmit={submitHandler}>
            <div className="form-item pb-4">
              <input type="text" name="RoleName" id="RoleName" value={RoleName} onChange={(e) => SetRoleName(e.target.value)} required />
              <label className='labe' >Role Name</label>
            </div>

            <div className="form-check pb-4">
              <input className="form-check-input" type="checkbox" name="IsActive" id="IsActive" onChange={() => SetIsActive(!IsActive)} checked={IsActive} />
              <label className="form-check-label ms-1" for="flexCheckDefault">
                Is-Active
              </label>
            </div>
            <input type="submit" value="Submit" className="cl-button -primary -small rounded-2 mt-2" />
          </form>
        </div>
      </div>
    </>
  );
};

export default EditRole;
