import React from "react";
import { BsFillHandThumbsUpFill, BsFillCheckCircleFill } from "react-icons/bs";
import Faq from "react-faq-component";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

const data = {
    title: <h1 className='pb-3 display-5 fw-bold title'>FAQ (How it works)</h1>,
    rows: [
        {
            title: <b>1. How long does it take to create a fully-functioning mobile app?</b>,
            content: `The average time to develop an app with Discovery, Design, Development and Pre-launch stages is around 3 to 6 months depending on features and requirements. An app that 
            requires a lot of graphics can be particularly time-consuming to develop due to the conceptualization and execution needed for these features, But there's always a way to speed up 
            the process.`,
        },
        {
            title: <b>2. What is the cost of mobile app development?</b>,
            content: `There is no cut-and-dried answer to this question. The cost of building a mobile app will be solely dependent on the features and complexity desired by the app owner.<br />  
            You need to consider a number of factors before you can decide on a budget for your mobile app development project, some of which are:
            <ul>
                <li class="list-group-item">a. How complex do you want your front-end to be?</li>
                <li class="list-group-item">b. Is there a steep deadline that you must meet?</li>
                <li class="list-group-item">c. What are the stakeholder expectations for your mobile app?</li>
                <li class="list-group-item">d. How detailed do you want your UI to be?</li>
                <li class="list-group-item">e. How large a load must your app’s back-end be able to handle?</li>
            </ul> `,
        },
        {
            title: <b>3. How to choose a mobile app Development Technology or Platform?</b>,
            content: `The framework and programming language selected for your project will depend on various factors. Some of the main factors you need to consider are:
            <ul>
                <li class="list-group-item">a. The project requirements</li>
                <li class="list-group-item">b. Target Audience</li>
                <li class="list-group-item">c. Security</li>
            </ul> 
            It’s always good to choose mobile app development languages with lots of frameworks and libraries.`,
        },
        {
            title: <b>4. What are the prerequisites for a mobile app development project?</b>,
            content: `You don’t really need to come from a technical background to understand the prerequisites for a mobile app development project. We’ll list out the main ones here:
            <ul>
                <li class="list-group-item">=> An app idea that is practically and economically viable.</li>
                <li class="list-group-item">=> Enough funding to kick your mobile development project into high-gear.</li>
                <li class="list-group-item">=> The passion and determination to execute your idea well.</li>
                <li class="list-group-item">=> Great communication and a workplace conducive to achievements.</li>
            </ul>
            And of course, a creative tech partner like TheBridgeCode`,
        },
        {
            title: <b>5. Will I own the source code?</b>,
            content: `Yes, you will be assigned appropriate copyright or licence rights for the bespoke code we write for your project. We are transparent with intellectual property rights, 
            and you will have the opportunity to review our code ownership terms before the project begins.`,
        },
        {
            title: <b>6. Can I make changes to my app or website after its launched?</b>,
            content: `Yes, we recommend you launch the simplest possible version of your tech first (called your Minimum Viable Product) and then extend and adapt it over time. And, the 
            development frameworks we use allow for incremental changes and improvements.`,
        },
    ],
};

const styles = {
    bgColor: 'white',
    titleTextColor: "#000",
    rowTitleColor: "#000",
    rowContentColor: '#000',
    arrowColor: "#000",
    transitionDuration: "0.2s",

};

const config = {
    animate: true,
    // arrowIcon: "V",
    tabFocus: true,
    expandIcon: "+",
    collapseIcon: "-",
};

// ================================|| MobileApplicationDevelopment ||================================ //

const MobileApplicationDevelopment = () => {
    return (
        <>
            <Helmet>
				<title>Mobile Application Development - The Bridge Code</title>
			</Helmet>
            <div className='container-fluid p-0'>
                <div class="position-relative overflow-hidden text-center mobile">
                    <div class="col-md-7 p-lg-5 mx-auto my-1">
                        <h1 class="display-2 fw-bolder text-white title-c">Mobile Application</h1>
                        <p class="lead fw-normal text-light">Take your business to new heights with our quality driven mobile app solutions that offer extraordinary mobile experience</p>
                        <Link to='/contact-us'><button className='btn-change2'>Contact Us</button></Link>
                    </div>
                </div>
                <div className="container mt-5 mb-5">
                    <div className="row col-md-12">
                        <div className="col-md-6">
                            <div className="title"><h1>Overview</h1></div>
                            <p className="display-5">We Develop feature-rich mobile apps.</p>
                            <p className="fs-3 fw-bold">We take Pride to develop Future Mobile Apps.</p>
                            <p>With the Rise of mobile devices it is very much important for the business to get exposure across smartphones. We have the most effective development
                                strategies, with unique design and development models for each type of project.</p>
                            <ul className="">
                                <li className="list-group-item p-1 fs-5"><BsFillHandThumbsUpFill /> IOS and Android apps development</li>
                                <li className="list-group-item p-1 fs-5"><BsFillHandThumbsUpFill /> Native and cross-platform solutions</li>
                                <li className="list-group-item p-1 fs-5"><BsFillHandThumbsUpFill /> UI/UX design</li>
                                <li className="list-group-item p-1 fs-5"><BsFillHandThumbsUpFill /> Consulting and prototyping</li>
                                <li className="list-group-item p-1 fs-5"><BsFillHandThumbsUpFill /> Automated QA and testing</li>
                                <li className="list-group-item p-1 fs-5"><BsFillHandThumbsUpFill /> Notification and geofencing</li>
                                <li className="list-group-item p-1 fs-5"><BsFillHandThumbsUpFill /> Maintenance and post-warranty support</li>
                            </ul>
                        </div>
                        <div className="col-md-6 align-self-center">
                            <img src="https://res.cloudinary.com/dm1muiikf/image/upload/v1684484853/website/mobile_app/Mobile_App.svg" alt="" className="img-fluid" />
                        </div>
                    </div>
                </div>

                <div className="container-fluid csd_serv ">
                    <div className="container">
                        <div className="row text-center justify-content-between">
                            <h1 className="display-2 fw-bold text-light title-c mb-5">Technologies We Use</h1>
                            <div className="three rounded shadow-lg text-white pt-3" style={{ backgroundColor: "rgb(0,0,0,0.3)" }}>
                                <div className="mb-3"><img src="https://res.cloudinary.com/dm1muiikf/image/upload/v1684484853/website/csd/apple.png" className="img-fluid bg-body" width={75} alt="apple" /></div>
                                <h4>IOS </h4>
                                <p className="text-light">Our expert-level developers have developed many mobile applications successfully, and hence we know what it takes to craft a
                                    perfect iOS application to enhance users’ lives. Our team can understand your requirements and transform an idea into reality just the way you imagined!</p>
                                <div className="row px-2">
                                    <h4 className="text-start title">Features</h4>
                                    <ul className="col-md-5 text-start">
                                        <li className="list-group-item p-1"><BsFillCheckCircleFill /> IPhone App Development</li>
                                        <li className="list-group-item p-1"><BsFillCheckCircleFill /> Swift/Objective C</li>
                                        <li className="list-group-item p-1"><BsFillCheckCircleFill /> Swift UI</li>
                                    </ul>
                                    <ul className="col-md-6 text-start">
                                        <li className="list-group-item p-1"><BsFillCheckCircleFill /> IPad App Development</li>
                                        <li className="list-group-item p-1"><BsFillCheckCircleFill /> Siri Intents</li>
                                        <li className="list-group-item p-1"><BsFillCheckCircleFill /> Apple Watch</li>
                                    </ul>
                                </div>
                            </div>

                            <div className="three rounded shadow-lg text-white pt-3">
                                <div className="mb-3"><img src="https://res.cloudinary.com/dm1muiikf/image/upload/v1684484853/website/csd/android.png" className="img-fluid bg-body" width={85} alt="apple" /></div>
                                <h4>Android </h4>
                                <p className="text-light">Our Android developers have Experience working with many Enterprise Clients. Using cutting-edge technology and years of experience
                                    with successful completion and Uploading it to respected marketplaces, we can create professional mobile applications that will make your business stand out
                                    from your Competitors.</p>
                                <div className="row px-2">
                                    <h4 className="text-start title">Features</h4>
                                    <ul className="col-md-5 text-start">
                                        <li className="list-group-item p-1"><BsFillCheckCircleFill /> Android Native Development Kit</li>
                                        <li className="list-group-item p-1"><BsFillCheckCircleFill /> Java/J2ME</li>
                                        <li className="list-group-item p-1"><BsFillCheckCircleFill /> Android TV</li>
                                    </ul>
                                    <ul className="col-md-6 text-start">
                                        <li className="list-group-item p-1"><BsFillCheckCircleFill /> Kotlin</li>
                                        <li className="list-group-item p-1"><BsFillCheckCircleFill /> Tablet</li>
                                        <li className="list-group-item p-1"><BsFillCheckCircleFill /> Location APIs</li>
                                    </ul>
                                </div>
                            </div>
                            <div className="three"></div>
                        </div>
                    </div>
                </div>

                <div className="container p-5">
                    <div className="text-center mx-5">
                        <h1 className="title-c">Our Mobile Application Development Services</h1>
                        <p>BridgeCode welcomes possibilities to assist in specific development stages as well as to provide full-cycle mobile app implementation. Our mobile app development services include:</p>
                    </div>
                    <div className="row text-center justify-content-between">
                        <div className="col-md-3 bg-body rounded shadow-lg mx-2 my-4">
                            <img src="https://res.cloudinary.com/dm1muiikf/image/upload/v1684484853/website/mobile_app/consulting.png" alt="" className="img-fluid p-2" />
                            <h4>Mobile consulting</h4>
                            <p>Our consultants assist with app concept finalization, advise on platform/device compatibility, plan activities, and optimize costs.</p>
                        </div>
                        <div className="col-md-3 bg-body rounded shadow-lg mx-2 my-4">
                            <img src="https://res.cloudinary.com/dm1muiikf/image/upload/v1684484853/website/mobile_app/design.png" alt="" className="img-fluid p-2" />
                            <h4>Mobile UX and UI design</h4>
                            <p>Our designers create slick and intuitive interfaces that ensure conversion, engagement, and easy adoption.</p>
                        </div>
                        <div className="col-md-3 bg-body rounded shadow-lg mx-2 my-4">
                            <img src="https://res.cloudinary.com/dm1muiikf/image/upload/v1684484853/website/mobile_app/mobile.png" alt="" className="img-fluid p-2" />
                            <h4>Mobile app development</h4>
                            <p>Having completed many projects, our developers create and deploy fast, stable & high-performing mobile apps.</p>
                        </div>
                        <div className="col-md-3 bg-body rounded shadow-lg mx-2 my-4">
                            <img src="https://res.cloudinary.com/dm1muiikf/image/upload/v1684484853/website/mobile_app/back-end.png" alt="" className="img-fluid p-2" />
                            <h4>Back end development</h4>
                            <p>Our back-end developers build robust and secure mobile back end to ensure smooth and quick data sync and transfer.</p>
                        </div>
                        <div className="col-md-3 bg-body rounded shadow-lg mx-2 my-4">
                            <img src="https://res.cloudinary.com/dm1muiikf/image/upload/v1684484853/website/mobile_app/web-and-mobile.png" alt="" className="img-fluid p-2" />
                            <h4>Web development</h4>
                            <p>We are ready to complement your mobile app with a web app, web portal, online store or SaaS.</p>
                        </div>
                        <div className="col-md-3 bg-body rounded shadow-lg mx-2 my-4">
                            <img src="https://res.cloudinary.com/dm1muiikf/image/upload/v1684484853/website/mobile_app/integration.png" alt="" className="img-fluid p-2" />
                            <h4>Mobile app integration</h4>
                            <p>Well-versed in a wide range of APIs, we guarantee seamless integration with back ends and any third-party software.</p>
                        </div>
                        <div className="col-md-3 bg-body rounded shadow-lg mx-1 my-4">
                            <img src="https://res.cloudinary.com/dm1muiikf/image/upload/v1684484853/website/mobile_app/testing.png" alt="" className="img-fluid p-2" />
                            <h4>Mobile app QA and testing</h4>
                            <p>Our Certified test engineers perform functional, performance, security, UX, and accessibility testing.</p>
                        </div>
                        <div className="col-md-3 bg-body rounded shadow-lg mx-1 my-4">
                            <img src="https://res.cloudinary.com/dm1muiikf/image/upload/v1684484853/website/mobile_app/support.png" alt="" className="img-fluid p-2" />
                            <h4>Maintenance and support</h4>
                            <p>We offer post-launch support, compliance, security, performance management & app evolution.</p>
                        </div>
                        <div className="col-md-3 bg-body rounded shadow-lg mx-1 my-4">
                            <img src="https://res.cloudinary.com/dm1muiikf/image/upload/v1684484853/website/mobile_app/improve.png" alt="" className="img-fluid p-2" />
                            <h4>Mobile app modernization</h4>
                            <p>Our developers skillfully convert websites into mobile apps, redesign architectures, and refactor code in new tech.</p>
                        </div>
                    </div>
                </div>

                {/* <!-- Cta--> */}
                <div className="container-fluid p-0 m-0">
                    <div className="parallax-container py-5">
                        <div className="parallax-content section-xl">
                            <div className="container">
                                <div className="row justify-content-end text-right">
                                    <div className="col-sm-7 text-center">
                                        <h3 className="display-4 fw-bold text-white mb-5">Why The Bridge Code?</h3>
                                        <p className='text-light mb-4'>BridgeCode Technologies is a Firm where we provide software development, integrations, and resources you need to compete
                                            in the Fast changing world. We believe in the power of marketing, sales, content management and great customer service are key to success in any
                                            Business. We have started a New Approach for our Clients where they can see the Potential of our Skills and Software. Try our Free Demo! </p>
                                        <p className='mt-3 text-white fs-4 fw-bold'>If you have the Passion, We have the Plan!!</p>
                                        <div className="mt-4">
                                            <Link to='/solution' className="cl-button -primary -small mx-1">Try Free Demo</Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="container pt-5 pb-5">
                    <div className="col-md-12 ">
                        <div className="col-md-8 mx-auto">
                            <Faq data={data} styles={styles} config={config} />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default MobileApplicationDevelopment;