import React, { useState, useEffect } from "react";
import API from '../../../middleware/API';
import ReactPaginate from 'react-paginate';
import Card from 'react-bootstrap/Card';
import moment from 'moment';
import 'react-multi-carousel/lib/styles.css';
import '../../scss/Spinner.scss';
import { useCookies } from "react-cookie";
import { Link, useNavigate } from 'react-router-dom';
import { Helmet } from "react-helmet";
import { FaFilter, FaRegNewspaper } from "react-icons/fa";

// ================================|| Blogs ||================================ //

const Blogs = () => {
    const navigate = useNavigate();
    const [cookies, setCookie] = useCookies(['username']);
    const [BlogData, setBlogData] = useState();
    const [TotalBlog, setTotalBlog] = useState();
    const [Categorydata, setCategoryData] = useState();
    const [CategoryId, SetCategoryId] = useState("");
    const [loading, setLoading] = useState(false);

    const pageNumber = "1";
    const rowsperPage = "6";
    var totalPageCount = 0;

    var GetAllBlogsAPI = API.getAllBlogs;
    var GetBlogsByCategoryIdAPI = API.getBlogsByCategoryId;

    useEffect(() => {
        getAllBlogList();
        getCategories();
        document.getElementById("clear").style.display = "none";
    }, []);

    const getAllBlogList = async () => {
        try {
            setLoading(true);
            GetAllBlogsAPI = GetAllBlogsAPI + "?pageNumber=" + pageNumber + "&rowsperPage=" + rowsperPage + "&sortingBy=DESC";
            if (GetAllBlogsAPI == 'http://185.196.21.59:459/api/GetBlogs?pageNumber=1&rowsperPage=6&sortingBy=DESC') {
                document.getElementById("fail").style.display = "grid";
            }
            else {
                const response = await fetch(GetAllBlogsAPI);
                const jsonData = await response.json();
                setBlogData(jsonData.recordsets[0]);
                setTotalBlog(jsonData.recordsets[1][0].TotalRows);
                document.getElementById("success").style.display = "grid";
            }
            setLoading(false);
        }
        catch (error) {
            console.log(error);
            setLoading(false);
        }
    };

    const getCategories = async () => {
        try {
            var GetCategoriesAPI = API.getActiveCategories;
            const response = await fetch(GetCategoriesAPI);
            const jsonData = await response.json();
            setCategoryData(jsonData.recordset);
            console.log(Categorydata);
        }
        catch (error) {
            console.log(error);
        }
    };

    totalPageCount = Math.floor(TotalBlog / rowsperPage) + 1;

    const handlePageClick = async (event) => {
        var orderBy = document.getElementById('orderBy').value;
        if (CategoryId === "" || CategoryId === "0") {
            var currentPageNo = event.selected + 1;
            try {
                GetAllBlogsAPI = GetAllBlogsAPI + "?pageNumber=" + currentPageNo + "&rowsperPage=" + rowsperPage + "&sortingBy=" + orderBy;
                const response = await fetch(GetAllBlogsAPI);
                const jsonData = await response.json();
                setBlogData(jsonData.recordsets[0]);
                setTotalBlog(jsonData.recordsets[1][0].TotalRows);
            }
            catch (error) {
                console.log(error);
            }
            totalPageCount = Math.floor(TotalBlog / rowsperPage) + 1;
        } else {
            document.getElementById("clear").style.display = "block";
            var currentPageNo = event.selected + 1;
            try {
                GetBlogsByCategoryIdAPI = GetBlogsByCategoryIdAPI + "?categoryId=" + CategoryId + "&pageNumber=" + currentPageNo + "&rowsperPage=" + rowsperPage + "&sortingBy=" + orderBy;
                const response = await fetch(GetBlogsByCategoryIdAPI);
                const jsonData = await response.json();
                setBlogData(jsonData.recordsets[0]);
                setTotalBlog(jsonData.recordsets[1][0].TotalRows);
            }
            catch (error) {
                console.log(error);
            }
            totalPageCount = Math.floor(TotalBlog / rowsperPage) + 1;
        }

    };

    const handleChange = async (event) => {
        var categoryId = event.target.value;
        var orderBy = document.getElementById('orderBy').value;
        SetCategoryId(categoryId);
        if (categoryId === "0") {
            document.getElementById("clear").style.display = "none";
            try {
                GetAllBlogsAPI = GetAllBlogsAPI + "?pageNumber=" + pageNumber + "&rowsperPage=" + rowsperPage + "&sortingBy=" + orderBy;
                const response = await fetch(GetAllBlogsAPI);
                const jsonData = await response.json();
                setBlogData(jsonData.recordsets[0]);
                setTotalBlog(jsonData.recordsets[1][0].TotalRows);
            }
            catch (error) {
                console.log(error);
            }
        }
        else {
            document.getElementById("clear").style.display = "block";
            var currentPageNo = 1;
            if (event.selected)
                currentPageNo = event.selected + 1;
            else
                currentPageNo = 1;
            try {
                GetBlogsByCategoryIdAPI = GetBlogsByCategoryIdAPI + "?categoryId=" + categoryId + "&pageNumber=" + currentPageNo + "&rowsperPage=" + rowsperPage + "&sortingBy=" + orderBy;
                const response = await fetch(GetBlogsByCategoryIdAPI);
                const jsonData = await response.json();
                setBlogData(jsonData.recordsets[0]);
                setTotalBlog(jsonData.recordsets[1][0].TotalRows);
            }
            catch (error) {
                console.log(error);
            }
            totalPageCount = Math.floor(TotalBlog / rowsperPage) + 1;
        }
    };

    const handleChanged = async (event) => {
        var orderBy = event.target.value;
        var categoryId = document.getElementById('category').value;
        if (categoryId === "0")
            try {
                GetAllBlogsAPI = GetAllBlogsAPI + "?pageNumber=" + pageNumber + "&rowsperPage=" + rowsperPage + "&sortingBy=" + orderBy;
                const response = await fetch(GetAllBlogsAPI);
                const jsonData = await response.json();
                setBlogData(jsonData.recordsets[0]);
                setTotalBlog(jsonData.recordsets[1][0].TotalRows);
            }
            catch (error) {
                console.log(error);
            }
        else {
            var currentPageNo = 1;
            if (event.selected)
                currentPageNo = event.selected + 1;
            else
                currentPageNo = 1;
            try {
                GetBlogsByCategoryIdAPI = GetBlogsByCategoryIdAPI + "?categoryId=" + categoryId + "&pageNumber=" + currentPageNo + "&rowsperPage=" + rowsperPage + "&sortingBy=" + orderBy;
                const response = await fetch(GetBlogsByCategoryIdAPI);
                const jsonData = await response.json();
                setBlogData(jsonData.recordsets[0]);
                setTotalBlog(jsonData.recordsets[1][0].TotalRows);
            }
            catch (error) {
                console.log(error);
            }
            totalPageCount = Math.floor(TotalBlog / rowsperPage) + 1;
        }
    };

    function clearFilter() {
        document.getElementById('category').value = "0";
        document.getElementById('orderBy').value = "DESC";
        getAllBlogList();
    }

    function viewBlog(SeoTitle, BlogSlug, Meta_Description) {
        setCookie('Title', SeoTitle);
        setCookie('Slug', BlogSlug);
        setCookie('Description', Meta_Description);
        navigate('/blog/' + BlogSlug);
    }

    return (
        <>
            <Helmet>
                <title>Blogs - The Bridge Code</title>
            </Helmet>
            <div className='container-fluid p-0'>
                <div class="position-relative overflow-hidden text-center blog-img">
                    <div class="col-md-9 p-lg-9 mx-auto my-1">
                        <h1 class="display-2 fw-bolder title-c text-white">Blog</h1>
                        <p class="lead fw-normal text-white">Read our Latest Blogs here.</p>
                        <Link to='/contact-us' class="cl-button -primary -big rounded-3">Contact Us</Link>
                    </div>
                </div>
                <div className="container p-0 mx-auto row mt-5">
                    <div className="col-md-1 col-sm-1"><span className="btn btn-info cl-button -primary my-3"><FaFilter /> Filter </span></div>
                    <div className="col-md-3 col-sm-3">
                        <select class="form-select mt-3 mb-1" id='category' onChange={handleChange}>
                            <option key="0" value="0">-- Select Category --</option>
                            {Categorydata && Categorydata.map((Categorydata) => (
                                <option key={Categorydata.Id} value={Categorydata.Id}>
                                    {Categorydata.Name}
                                </option>
                            ))}
                        </select>
                    </div>
                    <div className="col-md-3 col-sm-3">
                        <select class="form-select mt-3 mb-1" id='orderBy' onChange={handleChanged}>
                            <option key="1" value="DESC" selected>Descending</option>
                            <option key="2" value="ASC">Ascending</option>
                        </select>
                    </div>
                    <div className="col-md-1 col-sm-1"><span id="clear" className="btn btn-info cl-button -primary mx-1 my-3" onClick={() => clearFilter()} style={{ cursor: "pointer" }}>Clear</span></div>
                </div>

                {loading ?
                    <>
                        <div className="spinner">
                            <div className="lds-roller">
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                            </div>

                            <div>Loading...</div>
                        </div>
                    </> :
                    <>
                        <div className="container p-0 mx-auto">
                            <div className="blog">
                                {BlogData &&
                                    BlogData.map(({ Id, BlogTitle, SeoTitle, BlogSlug, Meta_Description, BlogImage, CreatedOn, Category }) => (
                                        <Card className="m-2">
                                            <a onClick={() => viewBlog(SeoTitle, BlogSlug, Meta_Description)} className="text-decoration-none text-dark" style={{ cursor: "pointer" }} >
                                                <Card.Body className="align-middle d-flex"><img src={BlogImage} className="img-fluid" /></Card.Body>
                                                <Card.Body className="pb-3 text-gray"><a className="h5">{BlogTitle}</a></Card.Body>
                                                <Card.Body className="pt-0 pb-0 text-gray"><p class="mb-0">{moment(CreatedOn).format('DD-MM-YYYY')}</p></Card.Body>
                                                <Card.Body className="pt-0 pb-3 text-gray"><span class="card-tag tag type py-1 px-2 text-white fw-blod"><FaRegNewspaper /> {Category}</span></Card.Body>
                                            </a>
                                        </Card>
                                    ))}
                            </div>
                            <div id="fail" className="blog">
                                <Card className="m-2">
                                    <a href='/blog/beginners-guide-to-java-mastering-the-basics' className="text-decoration-none text-dark" style={{ cursor: "pointer" }} >
                                        <Card.Body className="align-middle d-flex"><img src='/blog1.jpg' style={{ objectFit: "cover", height: "250px" }} className="img-fluid" /></Card.Body>
                                        <Card.Body className="pb-3 text-gray"><a className="h5">A Beginner's Guide to Java: Mastering the Basics</a></Card.Body>
                                        <Card.Body className="pt-0 pb-0 text-gray"><p class="mb-0">12-12-2023</p></Card.Body>
                                        <Card.Body className="pt-0 pb-3 text-gray"><span class="card-tag tag type py-1 px-2 text-white fw-blod"><FaRegNewspaper /> Development and Design</span></Card.Body>
                                    </a>
                                </Card>
                                <Card className="m-2">
                                    <a href='/blog/understanding-collection-types-in-C' className="text-decoration-none text-dark" style={{ cursor: "pointer" }} >
                                        <Card.Body className="align-middle d-flex"><img src='/blog2.jpg' style={{ objectFit: "cover", height: "250px" }} className="img-fluid" /></Card.Body>
                                        <Card.Body className="pb-3 text-gray"><a className="h5">Understanding Collection Types in C#</a></Card.Body>
                                        <Card.Body className="pt-0 pb-0 text-gray"><p class="mb-0">12-12-2023</p></Card.Body>
                                        <Card.Body className="pt-0 pb-3 text-gray"><span class="card-tag tag type py-1 px-2 text-white fw-blod"><FaRegNewspaper /> Development and Design</span></Card.Body>
                                    </a>
                                </Card>
                                <Card className="m-2">
                                    <a href='/blog/ensuring-secure-remote-access-for-employees-working-from-home-a-comprehensive-guide' className="text-decoration-none text-dark" style={{ cursor: "pointer" }} >
                                        <Card.Body className="align-middle d-flex"><img src='/blog3.jpg' style={{ objectFit: "cover", height: "250px" }} className="img-fluid" /></Card.Body>
                                        <Card.Body className="pb-3 text-gray"><a className="h5">Ensuring Secure Remote Access for Employees Working from Home: A Comprehensive Guide</a></Card.Body>
                                        <Card.Body className="pt-0 pb-0 text-gray"><p class="mb-0">27-11-2023</p></Card.Body>
                                        <Card.Body className="pt-0 pb-3 text-gray"><span class="card-tag tag type py-1 px-2 text-white fw-blod"><FaRegNewspaper /> Cyber Security</span></Card.Body>
                                    </a>
                                </Card>
                                <Card className="m-2">
                                    <a href='/blog/mastering-python-your-ultimate-guide-to-learning-the-language-of-data-science-and-automation' className="text-decoration-none text-dark" style={{ cursor: "pointer" }} >
                                        <Card.Body className="align-middle d-flex"><img src='/blog4.jpg' style={{ objectFit: "cover", height: "250px" }} className="img-fluid" /></Card.Body>
                                        <Card.Body className="pb-3 text-gray"><a className="h5">Mastering Python: Your Ultimate Guide to Learning the Language of Data Science and Automation</a></Card.Body>
                                        <Card.Body className="pt-0 pb-0 text-gray"><p class="mb-0">04-12-2023</p></Card.Body>
                                        <Card.Body className="pt-0 pb-3 text-gray"><span class="card-tag tag type py-1 px-2 text-white fw-blod"><FaRegNewspaper /> Development and Design</span></Card.Body>
                                    </a>
                                </Card>
                                <Card className="m-2">
                                    <a href='/blog/use-cases-for-blockchain-in-government-and-private-sector' className="text-decoration-none text-dark" style={{ cursor: "pointer" }} >
                                        <Card.Body className="align-middle d-flex"><img src='/blog5.jpg' style={{ objectFit: "cover", height: "250px" }} className="img-fluid" /></Card.Body>
                                        <Card.Body className="pb-3 text-gray"><a className="h5">Use cases for Blockchain in Government and Private sector</a></Card.Body>
                                        <Card.Body className="pt-0 pb-0 text-gray"><p class="mb-0">03-10-2023</p></Card.Body>
                                        <Card.Body className="pt-0 pb-3 text-gray"><span class="card-tag tag type py-1 px-2 text-white fw-blod"><FaRegNewspaper /> General IT</span></Card.Body>
                                    </a>
                                </Card>
                                <Card className="m-2">
                                    <a href='/blog/mastering-your-website-management-with-plesk' className="text-decoration-none text-dark" style={{ cursor: "pointer" }} >
                                        <Card.Body className="align-middle d-flex"><img src='/blog6.jpg' style={{ objectFit: "cover", height: "250px" }} className="img-fluid" /></Card.Body>
                                        <Card.Body className="pb-3 text-gray"><a className="h3">Mastering Your Website Management with Plesk</a></Card.Body>
                                        <Card.Body className="pt-0 pb-0 text-gray"><p class="mb-0">09-10-2023</p></Card.Body>
                                        <Card.Body className="pt-0 pb-3 text-gray"><span class="card-tag tag type py-1 px-2 text-white fw-blod"><FaRegNewspaper /> General IT</span></Card.Body>
                                    </a>
                                </Card>
                            </div>
                            <div class="row">
                                <div class="col-lg-12 d-flex justify-content-center">
                                    <div class="paginationDiv">
                                        <ReactPaginate
                                            nextLabel=">"
                                            onPageChange={handlePageClick}
                                            pageRangeDisplayed={3}
                                            marginPagesDisplayed={1}
                                            pageCount={totalPageCount}
                                            previousLabel="<"
                                            pageClassName="page-item"
                                            pageLinkClassName="page-link"
                                            previousClassName="page-item"
                                            previousLinkClassName="page-link"
                                            nextClassName="page-item"
                                            nextLinkClassName="page-link"
                                            breakLabel="..."
                                            breakClassName="page-item"
                                            breakLinkClassName="page-link"
                                            containerClassName="pagination"
                                            activeClassName="active"
                                            renderOnZeroPageCount={null}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>}
            </div>
        </>
    );
};

export default Blogs;