import { useState, useEffect } from "react";
import { MdDelete } from "react-icons/md";
import { FaCheck, FaTimes, FaEdit, FaEye } from "react-icons/fa";
import { Link, useNavigate } from 'react-router-dom';
import { Helmet } from "react-helmet";
import moment from 'moment';
import API from '../../../middleware/API';
import DataTable from 'react-data-table-component';

// ================================|| UserList ||================================ //

function UserList() {
    const navigate = useNavigate();
    const [Page, setPage] = useState(1);
    const [Userdata, setUserData] = useState();
    const [TotalUser, setTotalUser] = useState();
    const [loading, setLoading] = useState(false);

    const rowsperPage = "5";

    useEffect(() => {
        getUserList();
    }, [Page]);

    const getUserList = async () => {
        try {
            setLoading(true);
            var GetUserAPI = API.getAllUsers;
            GetUserAPI = GetUserAPI + '?pageNumber=' + Page + '&rowsperPage=' + rowsperPage;
            const response = await fetch(GetUserAPI);
            const jsonData = await response.json();
            setUserData(jsonData.recordsets[0]);
            setTotalUser(jsonData.recordsets[1][0].TotalRows);
            setLoading(false);
        }
        catch (error) {
            console.log(error);
            setLoading(false);
        }
    };

    function viewUserById(id) {
        navigate('/admin/user?Id=' + id);
    }

    function editUserById(id) {
        navigate('/admin/edit-user?Id=' + id);
    }

    function deleteUserById(id) {
        var result = window.confirm('Are you sure you want to delete this record?');
        if (result) {
            try {
                var DeleteUserByIdAPI = API.deleteUserById;
                DeleteUserByIdAPI = DeleteUserByIdAPI + "?id=" + id;
                const response = fetch(DeleteUserByIdAPI).then(() => {
                    getUserList(Page);
                });
            }
            catch (error) {
                console.log(error);
            }
        }
    }

    const columns = [
        {
            name: 'Id',
            selector: row => row.Id,
            sortable: true,
            width: "4rem"
        },
        {
            name: 'Role Name',
            cell: (row) => row.Role ? row.Role : (<span>N/A</span>),
            sortable: true,
            width: "8rem"
        },
        {
            name: 'Full Name',
            selector: row => row.FullName,
            sortable: true
        },
        {
            name: 'Username',
            selector: row => row.UserName,
            sortable: true
        },
        {
            name: 'Password',
            selector: row => row.Password,
            sortable: true
        },
        {
            name: 'Email',
            selector: row => row.EmailId,
            sortable: true
        },
        {
            name: 'Created By',
            selector: row => row.CreatedBy,
            sortable: true
        },
        {
            name: 'Created Date',
            selector: row => moment(row.CreatedOn).format('DD-MM-YYYY'),
            sortable: true
        },
        {
            name: 'Modified By',
            selector: row => row.ModifiedBy,
            sortable: true
        },
        {
            name: 'Modified Date',
            cell: (row) => row.ModifiedOn ? moment(row.ModifiedOn).format('DD-MM-YYYY') : (<span>N/A</span>),
            sortable: true
        },
        {
            name: 'Status',
            cell: (row) => row.IsActive ? (<FaCheck color="green" className="fa-2x"/>) : (<FaTimes color="red" className="fa-2x" />),
            sortable: true
        },
        {
            name: 'Action',
            cell: (props) => <div>
                <a><FaEdit size={30} className="m-1 p-1 cl-button -primary -small" onClick={() => editUserById(props.Id)} style={{ cursor: "pointer" }} /></a>
                <a><FaEye size={30} className="m-1 p-1 cl-button -primary -small" onClick={() => viewUserById(props.Id)} style={{ cursor: "pointer" }} /></a>
                <a><MdDelete size={30} className="m-1 p-1 cl-button -primary -small" onClick={() => deleteUserById(props.Id)} style={{ cursor: "pointer" }} /></a>
            </div>,
            sortable: false
        },
    ];

    return (
        <>
            <Helmet>
                <title>User - The Bridge Code</title>
            </Helmet>
            <div className="container-fluid">
                <div className="container d-flex pt-5 pb-3">
                    <div className="col">
                        <h3 className="card-header px-3 pb-3">User Details</h3>
                    </div>
                    <div className="col" style={{ textAlign: "end" }}>
                        <Link to='/admin/save-user' className="btn btn-info cl-button -primary">Add User</Link>
                    </div>
                </div>
                
                <div className="container mx-auto pt-3">
                    <div className="px-3 pb-3">
                        <div className="col-sm-12 table-responsive text-nowrap shadow p-3 mb-5 bg-body rounded">
                            <DataTable
                                columns={columns}
                                data={Userdata}
                                pagination
                                paginationServer
                                progressPending={loading}
                                paginationTotalRows={TotalUser}
                                paginationPerPage={rowsperPage}
                                paginationComponentOptions={{
                                    noRowsPerPage: true
                                }}
                                onChangePage={page => setPage(page)}
                            ></DataTable>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default UserList;