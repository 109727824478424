import React from "react";
import Card from 'react-bootstrap/Card';
import Carousel from 'react-multi-carousel';
import ModalDialog from './ModalDialog';
import Faq from "react-faq-component";
import { FcRight } from "react-icons/fc";
import { FaAngleDoubleDown, FaCheck } from "react-icons/fa";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

// CRM Demo Technologies and Features
const data = {

	rows: [
		{
			id: 1,
			title:
				<div>
					<div className='fw-bolder fs-3 text-dark'> &nbsp;Technologies & Servers</div>
				</div>,
			content:
				<div>
					<div className='row align-middle'>
						<hr />
						<div className='col-md-2 align-self-center py-2'><img src='https://res.cloudinary.com/dm1muiikf/image/upload/v1684484853/website/csd/Net_3.1_1.png' className='img-fluid' width={105} alt="ASP.Net Core 3.1" /></div>
						<div className='col-md-2 align-self-center py-2'><img src='https://res.cloudinary.com/dm1muiikf/image/upload/v1684484853/website/csd/sql.png' className='img-fluid' width={140} alt="MSSQL" /></div>
						<div className='col-md-2 align-self-center py-2'><img src='https://res.cloudinary.com/dm1muiikf/image/upload/v1684484853/website/csd/ajax.png' className='img-fluid' width={140} alt="AJAX" /></div>
						<div className='col-md-2 align-self-center py-2'><img src='https://res.cloudinary.com/dm1muiikf/image/upload/v1684484853/website/csd/javascript.png' className='img-fluid' width={140} alt="JavaScript" /></div>
						<div className='col-md-2 align-self-center py-2'><img src='https://res.cloudinary.com/dm1muiikf/image/upload/v1684484853/website/csd/css.png' className='img-fluid' width={140} alt="CSS" /></div>
						<div className='col-md-2 align-self-center py-2'><img src='https://res.cloudinary.com/dm1muiikf/image/upload/v1684484853/website/csd/jquery.png' className='img-fluid' width={140} alt="Jquery" /></div>
						<div className='col-md-2 align-self-center py-2'><img src='https://res.cloudinary.com/dm1muiikf/image/upload/v1684484853/website/csd/red_query.svg' className='' width={100} alt="Red Query" /></div>
						<div className='col-md-2 align-self-center py-2'><img src='https://res.cloudinary.com/dm1muiikf/image/upload/v1684484853/website/csd/html.png' className='img-fluid' width={140} alt="HTML" /></div>
						<div className='col-md-2 align-self-center py-2'><img src='https://res.cloudinary.com/dm1muiikf/image/upload/v1684484853/website/csd/boostrap.png' className='img-fluid' width={140} alt="Boostrap" /></div>
					</div>
				</div>,
			open: true
		},
		{
			id: 2,
			title: <p className='fw-bolder fs-3 text-dark'>&nbsp;Features</p>,
			content:
				<div className='col-md-12 row'>
					<hr />
					<div className='col-md-4'>
						<ul className='fs-6 fw-bold' style={{ listStyle: "none" }}>
							<li><FcRight /> Log in</li>
							<li><FcRight /> Signup</li>
							<li><FcRight /> Dashboard</li>
							<li><FcRight /> Role Management</li>
							<li><FcRight /> Role Permission</li>
							<li><FcRight /> Banners</li>
							<li><FcRight /> Manage Company(s) </li>
							<li><FcRight /> Create Company Document Types(s)</li>
							<li><FcRight /> Generate PI</li>
						</ul>
					</div>
					<div className='col-md-4'>
						<ul className='fs-6 fw-bold' style={{ listStyle: "none" }}>
							<li><FcRight /> Add Shareholder/ Director</li>
							<li><FcRight /> Add Other Company Employee</li>
							<li><FcRight /> Manage Team</li>
							<li><FcRight /> User Login</li>
							<li><FcRight /> Sales Person</li>
							<li><FcRight /> Services</li>
							<li><FcRight /> Company Services </li>
							<li><FcRight /> Employee Services</li>
							<li><FcRight /> My Account</li>
						</ul>
					</div>
					<div className='col-md-4'>
						<ul className='fs-6 fw-bold' style={{ listStyle: "none" }}>
							<li><FcRight /> Invoice</li>
							<li><FcRight /> Payment Receipts </li>
							<li><FcRight /> Account Management </li>
							<li><FcRight /> Support Tickets</li>
							<li><FcRight /> Document Checklist</li>
							<li><FcRight /> Service Request</li>
							<li><FcRight /> Notification </li>
							<li><FcRight /> Setting</li>
							<li><FcRight /> Contact</li>
							<li><FcRight /> Help</li>
						</ul>
					</div>
				</div>
			,
			open: false
		},
	],
};

// POS Demo Technologies and Features
const data1 = {
	rows: [
		{
			id: 3,
			title: <p className='fw-bolder fs-3 text-center text-dark'>&nbsp;Technologies & Servers</p>,
			content:
				<div>
					<div className='row align-middle'>
						<hr />
						<div className='col-md-2 align-self-center py-2'><img src='https://res.cloudinary.com/dm1muiikf/image/upload/v1684484853/website/csd/Net_06.png' className='img-fluid mt-3' width={140} alt="ASP.Net Core 6" /></div>
						<div className='col-md-2 align-self-center py-2'><img src='https://res.cloudinary.com/dm1muiikf/image/upload/v1684484853/website/csd/mongo_db.png' className='' width={100} alt="Mongo DB" /></div>
						<div className='col-md-2 align-self-center py-2'><img src='https://res.cloudinary.com/dm1muiikf/image/upload/v1684484853/website/csd/html.png' className='img-fluid' width={140} alt="HTML" /></div>
						<div className='col-md-2 align-self-center py-2'><img src='https://res.cloudinary.com/dm1muiikf/image/upload/v1684484853/website/csd/css.png' className='img-fluid' width={140} alt="CSS" /></div>
						<div className='col-md-2 align-self-center py-2'><img src='https://res.cloudinary.com/dm1muiikf/image/upload/v1684484853/website/csd/boostrap.png' className='img-fluid' width={140} alt="Boostrap" /></div>
						<div className='col-md-2 align-self-center py-2'><img src='https://res.cloudinary.com/dm1muiikf/image/upload/v1684484853/website/csd/ajax.png' className='img-fluid' width={140} alt="AJAX" /></div>
						<div className='col-md-2 align-self-center py-2'><img src='https://res.cloudinary.com/dm1muiikf/image/upload/v1684484853/website/csd/red_query.svg' className='img-fluid' width={140} alt="Red Query" /></div>
						<div className='col-md-2 align-self-center py-2'><img src='https://res.cloudinary.com/dm1muiikf/image/upload/v1684484853/website/csd/javascript.png' className='img-fluid' width={140} alt="JavaScript" /></div>
						<div className='col-md-2 align-self-center py-2'><img src='https://res.cloudinary.com/dm1muiikf/image/upload/v1684484853/website/csd/jquery.png' className='img-fluid' width={140} alt="Jquery" /></div>
					</div>
				</div>
		},
		{
			id: 4,
			title: <p className='fw-bolder fs-3 text-dark'>&nbsp;Features</p>,
			content:
				<div className='col-md-12 row'>
					<hr />
					<div className='col-md-6'>
						<ul className='fs-6' style={{ listStyle: "none" }}>
							<li className='fw-bold'><FcRight /> Sign In</li>
							<li ><FcRight /> <u className='fw-bold'>Dashboard:-</u>
								<li className='ps-5'><FaCheck style={{ color: "green" }} /> Sales</li>
								<li className='ps-5'><FaCheck style={{ color: "green" }} /> Purchse</li>
								<li className='ps-5'><FaCheck style={{ color: "green" }} /> Payment Charts</li>
								<li className='ps-5'><FaCheck style={{ color: "green" }} /> Orders</li>
								<li className='ps-5'><FaCheck style={{ color: "green" }} /> Monthly Sale Amount</li>
								<li className='ps-5'><FaCheck style={{ color: "green" }} /> Monthly Purchase Amount</li>
								<li className='ps-5'><FaCheck style={{ color: "green" }} /> Monthly Return Amount</li>
								<li className='ps-5'><FaCheck style={{ color: "green" }} /> Current Day Recieved Amount via Cash</li>
								<li className='ps-5'><FaCheck style={{ color: "green" }} /> PayTm (UPI), POS Machine</li>
								<li className='ps-5'><FaCheck style={{ color: "green" }} /> Top Selling Products Monthwise</li>
							</li>
							<li className='fw-bold'><FcRight /> Manage Supplier</li>
							<li className='fw-bold'><FcRight /> Sales</li>
							<li className='fw-bold'><FcRight /> Manage Product</li>
							<li className='fw-bold'><FcRight /> Return Order </li>
							<li className='fw-bold'><FcRight /> Order </li>
							<li ><FcRight /> <u className='fw-bold'>Graph Report Generation:-</u>
								<li className='ps-5'><FaCheck style={{ color: "green" }} /> Sales</li>
								<li className='ps-5'><FaCheck style={{ color: "green" }} /> Purchse</li>
								<li className='ps-5'><FaCheck style={{ color: "green" }} /> Payment</li>
							</li>
						</ul>
					</div>
					<div className='col-md-6'>
						<ul className='fs-6' style={{ listStyle: "none" }}>
							<li ><FcRight /> <u className='fw-bold'>CashFlow Report Generation:-</u>
								<li className='ps-5'><FaCheck style={{ color: "green" }} /> Sales</li>
								<li className='ps-5'><FaCheck style={{ color: "green" }} /> Product</li>
								<li className='ps-5'><FaCheck style={{ color: "green" }} /> Purchse</li>
								<li className='ps-5'><FaCheck style={{ color: "green" }} /> Payment Charts</li>
								<li className='ps-5'><FaCheck style={{ color: "green" }} /> Return Order</li>
								<li className='ps-5'><FaCheck style={{ color: "green" }} /> Accountant</li>
								<li className='ps-5'><FaCheck style={{ color: "green" }} /> Customer </li>
							</li>
							<li className='fw-bold'><FcRight /> Invoice Generation</li>
							<li className='fw-bold'><FcRight /> Sharing Feature </li>
							<li className='fw-bold'><FcRight /> Share Invoice on Whatsapp </li>
							<li className='fw-bold'><FcRight /> Barcode Scanning Feature</li>
							<li className='fw-bold'><FcRight /> Manage Online Order</li>
							<li className='fw-bold'><FcRight /> Knowledgebase</li>
							<li ><FcRight /> <u className='fw-bold'>Profile:-</u>
								<li className='ps-5'><FaCheck style={{ color: "green" }} /> Admin</li>
								<li className='ps-5'><FaCheck style={{ color: "green" }} /> Manager</li>
							</li>
							<li className='fw-bold'><FcRight /> Notification </li>
							<li className='fw-bold'><FcRight /> View Deleted Orders History</li>
							<li className='fw-bold'><FcRight /> Download Data (Excel, PDF)</li>
							<li className='fw-bold'><FcRight /> Invoice Print/Save as PDF Functionality</li>
							<li className='fw-bold'><FcRight /> Search Functionality</li>
							<li className='fw-bold'><FcRight /> Order Placed from Details (For E.g. Web, Mobile)</li>
						</ul>
					</div>
				</div>
			,
			open: false,
		},
	],
};

// Account Management Demo Technologies and Features
const data2 = {
	rows: [
		{
			id: 5,
			title: <p className='fw-bolder fs-3 text-dark'>&nbsp;Technologies & Servers</p>,
			content:
				<div>
					<div className='col-md-12 row'>
						<hr />
						<div className='col-md-2 align-self-center py-2'><img src='https://res.cloudinary.com/dm1muiikf/image/upload/v1684484853/website/csd/Net_06.png' className='img-fluid mt-3' width={140} alt="ASP.Net Core 6" /></div>
						<div className='col-md-2 align-self-center py-2'><img src='https://res.cloudinary.com/dm1muiikf/image/upload/v1684484853/website/csd/oracle.png' className='img-fluid mt-3' width={140} alt="Oracle" /></div>
						<div className='col-md-2 align-self-center py-2'><img src='https://res.cloudinary.com/dm1muiikf/image/upload/v1684484853/website/csd/ajax.png' className='img-fluid mt-3' width={140} alt="AJAX" /></div>
						<div className='col-md-2 align-self-center py-2'><img src='https://res.cloudinary.com/dm1muiikf/image/upload/v1684484853/website/csd/javascript.png' className='img-fluid' width={140} alt="JavaScript" /></div>
						<div className='col-md-2 align-self-center py-2'><img src='https://res.cloudinary.com/dm1muiikf/image/upload/v1684484853/website/csd/css.png' className='img-fluid' width={140} alt="css" /></div>
						<div className='col-md-2 align-self-center py-2'><img src='https://res.cloudinary.com/dm1muiikf/image/upload/v1684484853/website/csd/jquery.png' className='img-fluid' width={140} alt="Jquery" /></div>
						<div className='col-md-2 align-self-center py-2'><img src='https://res.cloudinary.com/dm1muiikf/image/upload/v1684484853/website/csd/html.png' className='img-fluid' width={140} alt="HTML" /></div>
						<div className='col-md-2 align-self-center py-2'><img src='https://res.cloudinary.com/dm1muiikf/image/upload/v1684484853/website/csd/boostrap.png' className='img-fluid' width={140} alt="Boostrap" /></div>
					</div>
				</div>
		},
		{
			id: 6,
			title: <p className='fw-bolder fs-3 text-dark'>&nbsp;Features</p>,
			content:
				<div className='col-md-12 row'>
					<hr />
					<div className='col-md-6'>
						<ul className='fs-6' style={{ listStyle: "none" }}>
							<li className='fw-bold'><FcRight /> Sign In</li>
							<li ><FcRight /> <u className='fw-bold'>Dashboard:-</u>
								<li className='ps-5'><FaCheck style={{ color: "green" }} /> Company </li>
								<li className='ps-5'><FaCheck style={{ color: "green" }} /> Employee</li>
								<li className='ps-5'><FaCheck style={{ color: "green" }} /> Clients </li>
								<li className='ps-5'><FaCheck style={{ color: "green" }} /> Support Tickets</li>
								<li className='ps-5'><FaCheck style={{ color: "green" }} /> Role Management</li>
								<li className='ps-5'><FaCheck style={{ color: "green" }} /> Role Permission</li>
								<li className='ps-5'><FaCheck style={{ color: "green" }} /> User </li>
								<li className='ps-5'><FaCheck style={{ color: "green" }} /> Currency</li>
								<li className='ps-5'><FaCheck style={{ color: "green" }} /> Company Management</li>
								<li className='ps-5'><FaCheck style={{ color: "green" }} /> Account Management</li>
								<li className='ps-5'><FaCheck style={{ color: "green" }} /> Support Ticket Management</li>
							</li>
						</ul>
					</div>
					<div className='col-md-6'>
						<ul className='fs-6' style={{ listStyle: "none" }}>
							<li ><FcRight /> <u className='fw-bold'>Employee :-</u>
								<li className='ps-5'><FaCheck style={{ color: "green" }} /> Sign In</li>
								<li className='ps-5'><FaCheck style={{ color: "green" }} /> Dashboard</li>
								<li className='ps-5'><FaCheck style={{ color: "green" }} /> Support Ticket Management</li>
							</li>
							<li ><FcRight /> <u className='fw-bold'>Accountant :-</u>
								<li className='ps-5'><FaCheck style={{ color: "green" }} /> Sign In</li>
								<li className='ps-5'><FaCheck style={{ color: "green" }} /> Dashboard </li>
								<li className='ps-5'><FaCheck style={{ color: "green" }} /> Account Management</li>
								<li className='ps-5'><FaCheck style={{ color: "green" }} /> Account Sumary Of Comapanies</li>
							</li>
						</ul>
					</div>
				</div>
			,
		},
	],
};

// Nop-Commerce Demo Technologies and Features
const data3 = {
	rows: [
		{
			id: 7,
			title: <p className='fw-bolder fs-3 text-dark'>&nbsp;Technologies & Servers</p>,
			content:
				<div>
					<div className='col-md-12 row mx-auto'>
						<hr />
						<div className='col-md-2 align-self-center py-2'><img src='https://res.cloudinary.com/dm1muiikf/image/upload/v1684484853/website/csd/Net_06.png' className='img-fluid' width={140} alt="ASP.Net Core 6" /></div>
						<div className='col-md-2 align-self-center py-2'><img src='https://res.cloudinary.com/dm1muiikf/image/upload/v1684484853/website/csd/sql.png' className='img-fluid' width={140} alt="MSSQL" /></div>
						<div className='col-md-2 align-self-center py-2'><img src='https://res.cloudinary.com/dm1muiikf/image/upload/v1684484853/website/csd/linq_sql.png' className='' width={100} alt="Linq Sql" /></div>
						<div className='col-md-2 align-self-center py-2'><img src='https://res.cloudinary.com/dm1muiikf/image/upload/v1684484853/website/csd/jquery.png' className='img-fluid' width={140} alt="jQuery" /></div>
						<div className='col-md-2 align-self-center py-2'><img src='https://res.cloudinary.com/dm1muiikf/image/upload/v1684484853/website/csd/ajax.png' className='img-fluid' width={140} alt="AJAX" /></div>
						<div className='col-md-2 align-self-center py-2'><img src='https://res.cloudinary.com/dm1muiikf/image/upload/v1684484853/website/csd/boostrap.png' className='img-fluid' width={140} alt="Bootstap" /></div>
					</div>
				</div>,
		},
		{
			id: 8,
			title: <p className='fw-bolder fs-3 text-dark'>&nbsp;Features</p>,
			content:
				<div className='col-md-12 row'>
					<hr />
					<div className='col-md-6'>
						<ul className='fs-6' style={{ listStyle: "none" }}>
							<li className='fw-bold text-center fs-4'> Admin</li>
							<li className='fw-bold'><FcRight /> Dashboard</li>
							<li className='fw-bold'><FcRight /> Orders </li>
							<li className='fw-bold'><FcRight /> New Customers</li>
							<li className='fw-bold'><FcRight /> Catalog</li>
							<li className='fw-bold'><FcRight /> Products</li>
							<li className='fw-bold'><FcRight /> Categories</li>
							<li className='fw-bold'><FcRight /> Manufacturers</li>
							<li className='fw-bold'><FcRight /> Product Reviews</li>
							<li className='fw-bold'><FcRight /> Product Tags</li>
							<li className='fw-bold'><FcRight /> Attributes</li>
							<li className='fw-bold'><FcRight /> Sales Full Structure</li>
							<li className='fw-bold'><FcRight /> Customers Activies</li>
							<li className='fw-bold'><FcRight /> Vendors Activies Log & Types</li>
							<li className='fw-bold'><FcRight /> Offers & Promotions</li>
							<li className='fw-bold'><FcRight /> All Types of Account Setting</li>
							<li className='fw-bold'><FcRight /> Much More...</li>
						</ul>
					</div>
					<div className='col-md-6'>
						<ul className='fs-6' style={{ listStyle: "none" }}>
							<li className='fw-bold text-center fs-4'> Customers</li>
							<li className='fw-bold'><FcRight /> Sign In</li>
							<li className='fw-bold'><FcRight /> Sign Up</li>
							<li className='fw-bold'><FcRight /> Home Page - Featured Prodocts</li>
							<li className='fw-bold'><FcRight /> Category - Products By Categories</li>
							<li className='fw-bold'><FcRight /> Setting</li>
							<li ><FcRight /> <u className='fw-bold'>My Account :-</u>
								<li className='ps-5'><FaCheck style={{ color: "green" }} /> Customer Info </li>
								<li className='ps-5'><FaCheck style={{ color: "green" }} /> Address</li>
								<li className='ps-5'><FaCheck style={{ color: "green" }} /> Return Requests </li>
								<li className='ps-5'><FaCheck style={{ color: "green" }} /> Downloadable Products</li>
								<li className='ps-5'><FaCheck style={{ color: "green" }} /> Back In Stock Subscriptions</li>
								<li className='ps-5'><FaCheck style={{ color: "green" }} /> Reward Points</li>
								<li className='ps-5'><FaCheck style={{ color: "green" }} /> Change Password</li>
								<li className='ps-5'><FaCheck style={{ color: "green" }} /> My Product Reviews</li>
								<li className='ps-5'><FaCheck style={{ color: "green" }} /> Shopping Cart</li>
								<li className='ps-5'><FaCheck style={{ color: "green" }} /> Payment (Internet banking, Debit/Credit card, UPI, Cash on Delivery) </li>
								<li className='ps-5'><FaCheck style={{ color: "green" }} /> Wishlist </li>
							</li>
						</ul>
					</div>
				</div>
			,
		},
	],
};

// E-Commerce Demo Technologies and Features
const data4 = {
	rows: [
		{
			id: 9,
			title: <p className='fw-bolder fs-3 text-dark'>&nbsp;Technologies & Servers</p>,
			content:
				<div>
					<div className='col-md-12 row'>
						<hr />
						<div className='col-md-2 align-self-center py-2'><img src='https://res.cloudinary.com/dm1muiikf/image/upload/v1684485463/website/csd/react.png' className='img-fluid mt-3' width={140} alt="ASP.Net Core 6" /></div>
						<div className='col-md-2 align-self-center py-2'><img src='https://res.cloudinary.com/dm1muiikf/image/upload/v1715858027/website/csd/npm.png' className='img-fluid mt-3' width={140} alt="Oracle" /></div>
						<div className='col-md-2 align-self-center py-2'><img src='https://res.cloudinary.com/dm1muiikf/image/upload/v1715858028/website/csd/node.png' className='img-fluid mt-3' width={140} alt="AJAX" /></div>
						<div className='col-md-2 align-self-center py-2'><img src='https://res.cloudinary.com/dm1muiikf/image/upload/v1684484853/website/csd/boostrap.png' className='img-fluid' width={140} alt="Boostrap" /></div>
						<div className='col-md-2 align-self-center py-2'><img src='https://res.cloudinary.com/dm1muiikf/image/upload/v1684484853/website/csd/css.png' className='img-fluid' width={140} alt="css" /></div>
						<div className='col-md-2 align-self-center py-2'><img src='https://res.cloudinary.com/dm1muiikf/image/upload/v1684484853/website/csd/javascript.png' className='img-fluid' width={140} alt="JavaScript" /></div>
						<div className='col-md-2 align-self-center py-2'><img src='https://res.cloudinary.com/dm1muiikf/image/upload/v1684484853/website/csd/jquery.png' className='img-fluid' width={140} alt="Jquery" /></div>
					</div>
				</div>
		},
		{
			id: 10,
			title: <p className='fw-bolder fs-3 text-dark'>&nbsp;Features</p>,
			content:
				<div className='col-md-12 row'>
					<hr />
					<div className='col-md-6'>
						<ul className='fs-6' style={{ listStyle: "none" }}>
							<li className='fw-bold'><FcRight /> Sign Up</li>
							<li className='fw-bold'><FcRight /> Sign In</li>
							<li ><FcRight /> <u className='fw-bold'>Home Page:-</u>
								<li className='ps-5'><FaCheck style={{ color: "green" }} /> Clothes by Gender</li>
								<li className='ps-5'><FaCheck style={{ color: "green" }} /> Explore Products</li>
								<li className='ps-5'><FaCheck style={{ color: "green" }} /> Social Media Post Integration</li>
								<li className='ps-5'><FaCheck style={{ color: "green" }} /> Subscribe to Newsletter</li>
							</li>
							<li className='fw-bold'><FcRight /> About Us</li>
							<li className='fw-bold'><FcRight /> Contact Us</li>
							<li ><FcRight /> <u className='fw-bold'>Men's :-</u>
								<li className='ps-5'><FaCheck style={{ color: "green" }} /> Clothes by Category</li>
							</li>
							<li ><FcRight /> <u className='fw-bold'>Women's :-</u>
								<li className='ps-5'><FaCheck style={{ color: "green" }} /> Clothes by Category</li>
							</li>
							<li ><FcRight /> <u className='fw-bold'>Kid's :-</u>
								<li className='ps-5'><FaCheck style={{ color: "green" }} /> Clothes by Category</li>
							</li>
						</ul>
					</div>
					<div className='col-md-6'>
						<ul className='fs-6' style={{ listStyle: "none" }}>
							<li ><FcRight /> <u className='fw-bold'>Order :-</u>
								<li className='ps-5'><FaCheck style={{ color: "green" }} /> Cart (Coupon Code, Payment Options)</li>
								<li className='ps-5'><FaCheck style={{ color: "green" }} /> Wishlist</li>
								<li className='ps-5'><FaCheck style={{ color: "green" }} /> Orders</li>
								<li className='ps-5'><FaCheck style={{ color: "green" }} /> Sharing (Order Tracking)</li>
							</li>
							<li ><FcRight /> <u className='fw-bold'>Account :-</u>
								<li className='ps-5'><FaCheck style={{ color: "green" }} /> Profile</li>
								<li className='ps-5'><FaCheck style={{ color: "green" }} /> Edit Account</li>
								<li className='ps-5'><FaCheck style={{ color: "green" }} /> Change Password</li>
								<li className='ps-5'><FaCheck style={{ color: "green" }} /> Deactivate Account</li>
							</li>
						</ul>
					</div>
				</div>
			,
		},
	],
};

// ================================|| Solution ||================================ //

const Solution = () => {
	const styles = {
		bgColor: '',
		titleTextColor: "#000",
		rowTitleColor: "#000",
		rowContentColor: '#000',
		arrowColor: "#000",
		transitionDuration: "1s",
		// rowTitleColor: 'blue',
		rowContentPaddingBottom: '20px',
	};

	const config = {
		animate: true,
		arrowIcon: <FaAngleDoubleDown />,
		tabFocus: true,
		// expandIcon: "+",
		// collapseIcon: "-",
	};

	const responsive = {
		desktop: {
			breakpoint: { max: 3000, min: 1024 },
			items: 1,
			slidesToSlide: 1 // optional, default to 1.
		},
		tablet: {
			breakpoint: { max: 1024, min: 464 },
			items: 1,
			slidesToSlide: 1 // optional, default to 1.
		},
		mobile: {
			breakpoint: { max: 464, min: 0 },
			items: 1,
			slidesToSlide: 1 // optional, default to 1.
		}
	};

	return (
		<>
			<Helmet>
				<title>Solution - The Bridge Code</title>
			</Helmet>
			<div className="container-fluid p-0">
				<div className="position-relative overflow-hidden text-center pro_web">
					<div className="col-md-7 p-lg-5 mx-auto ">
						<h1 className="display-2 fw-bolder text-dark pb-4 title-c">Our Solution</h1>
						<p className="lead fw-normal text-dark">Experience Amazing Technology and Features with our Live Demo.</p>
						<Link to='/contact-us' class="cl-button -primary -big rounded-3">Contact Us</Link>
					</div>
				</div>

				{/* CRM DEMO */}
				<div className="container-fluid" style={{ paddingBottom: '100px', paddingTop: '100px' }} data-aos="fade-up">
					<div className="row">
						<div className="col-xl-5 mx-auto d-block d-flex justify-content-center">
							<div class="laptop align-self-center">
								<div class="content">
									<div className='single-welcome-slide-wrapper'>
										<Carousel
											swipeable={true}
											draggable={false}
											showDots={false}
											responsive={responsive}
											ssr={true} // means to render carousel on server-side.
											infinite={true}
											autoPlay={true}
											autoPlaySpeed={3000}
											keyBoardControl={true}
											customTransition="all 1.5"
											transitionDuration={500}
											containerClass="carousel-container"
											removeArrowOnDeviceType={["tablet", "mobile"]} >
											<Card className='mx-auto' >
												<Card.Img variant="top" src="https://res.cloudinary.com/dm1muiikf/image/upload/v1684484853/website/porfolio/custome_portfolio/B1.png" className='img-fluid' />
											</Card>
											<Card className='mx-auto'>
												<Card.Img variant="top" src="https://res.cloudinary.com/dm1muiikf/image/upload/v1684484853/website/porfolio/custome_portfolio/B2.png" className='img-fluid' />
											</Card>
											<Card className='mx-auto' >
												<Card.Img variant="top" src="https://res.cloudinary.com/dm1muiikf/image/upload/v1684484853/website/porfolio/custome_portfolio/B3.png" className='img-fluid' />
											</Card>
											<Card className='mx-auto' >
												<Card.Img variant="top" src="https://res.cloudinary.com/dm1muiikf/image/upload/v1684484853/website/porfolio/custome_portfolio/B4.png" className='img-fluid' />
											</Card>
											<Card className='mx-auto' >
												<Card.Img variant="top" src="https://res.cloudinary.com/dm1muiikf/image/upload/v1684484853/website/porfolio/custome_portfolio/B5.png" className='img-fluid' />
											</Card>
											<Card className='mx-auto' >
												<Card.Img variant="top" src="https://res.cloudinary.com/dm1muiikf/image/upload/v1684484853/website/porfolio/custome_portfolio/B6.png" className='img-fluid' />
											</Card>
											<Card className='mx-auto' >
												<Card.Img variant="top" src="https://res.cloudinary.com/dm1muiikf/image/upload/v1684484853/website/porfolio/custome_portfolio/B7.png" className='img-fluid' />
											</Card>
											<Card className='mx-auto' >
												<Card.Img variant="top" src="https://res.cloudinary.com/dm1muiikf/image/upload/v1684484853/website/porfolio/custome_portfolio/B8.png" className='img-fluid' />
											</Card>
											<Card className='mx-auto' >
												<Card.Img variant="top" src="https://res.cloudinary.com/dm1muiikf/image/upload/v1684484853/website/porfolio/custome_portfolio/B9.png" className='img-fluid' />
											</Card>
											<Card className='mx-auto' >
												<Card.Img variant="top" src="https://res.cloudinary.com/dm1muiikf/image/upload/v1684484853/website/porfolio/custome_portfolio/B10.png" className='img-fluid' />
											</Card>
											<Card className='mx-auto' >
												<Card.Img variant="top" src="https://res.cloudinary.com/dm1muiikf/image/upload/v1684484853/website/porfolio/custome_portfolio/B11.png" className='img-fluid' />
											</Card>
											<Card className='mx-auto' >
												<Card.Img variant="top" src="https://res.cloudinary.com/dm1muiikf/image/upload/v1684484853/website/porfolio/custome_portfolio/B12.png" className='img-fluid' />
											</Card>
											<Card className='mx-auto' >
												<Card.Img variant="top" src="https://res.cloudinary.com/dm1muiikf/image/upload/v1684484853/website/porfolio/custome_portfolio/B13.png" className='img-fluid' />
											</Card>
											<Card className='mx-auto' >
												<Card.Img variant="top" src="https://res.cloudinary.com/dm1muiikf/image/upload/v1684484853/website/porfolio/custome_portfolio/B14.png" className='img-fluid' />
											</Card>
											<Card className='mx-auto' >
												<Card.Img variant="top" src="https://res.cloudinary.com/dm1muiikf/image/upload/v1684484853/website/porfolio/custome_portfolio/B15.png" className='img-fluid' />
											</Card>
											<Card className='mx-auto' >
												<Card.Img variant="top" src="https://res.cloudinary.com/dm1muiikf/image/upload/v1684484853/website/porfolio/custome_portfolio/B16.png" className='img-fluid' />
											</Card>
											<Card className='mx-auto' >
												<Card.Img variant="top" src="https://res.cloudinary.com/dm1muiikf/image/upload/v1684484853/website/porfolio/custome_portfolio/B17.png" className='img-fluid' />
											</Card>
											<Card className='mx-auto' >
												<Card.Img variant="top" src="https://res.cloudinary.com/dm1muiikf/image/upload/v1684484853/website/porfolio/custome_portfolio/B18.png" className='img-fluid' />
											</Card>
											<Card className='mx-auto' >
												<Card.Img variant="top" src="https://res.cloudinary.com/dm1muiikf/image/upload/v1684484853/website/porfolio/custome_portfolio/B19.png" className='img-fluid' />
											</Card>
											<Card className='mx-auto' >
												<Card.Img variant="top" src="https://res.cloudinary.com/dm1muiikf/image/upload/v1684484853/website/porfolio/custome_portfolio/B20.png" className='img-fluid' />
											</Card>
										</Carousel>
									</div>
								</div>
							</div>
						</div>
						<div className="col-xl-5 px-md-5 mx-auto align-self-center pt-5">
							<div className='pt-5'>
								<h2 className="mb-4 fw-bold" style={{ color: "#FF6C23" }}>CRM Demo</h2>
								<div className='text-decoration-none'>
									<h3 className="my-4 fs-2 fw-bold">Amazing customer relations management guaranteed.</h3>
								</div>

								<div className="col-md-12">
									<div className="mx-auto">
										<Faq data={data} styles={styles} config={config} collapseId={1} />
									</div>
								</div>

							</div>
							<div className='pt-3'>
								<ModalDialog name="pos" />
							</div>
						</div>
					</div>
				</div>

				{/* POS DEMO */}
				<div className="container-fluid custome_web">
					<div className="d-xl-flex p-md-0 m-md-0 justify-content-xl-around">
						<div className="col-xl-5 d-md-flex mb-5 pb-5 order-2">
							<div class="laptop align-self-center mx-auto">
								<div class="content">
									<div className='single-welcome-slide-wrapper'>
										<Carousel
											swipeable={true}
											draggable={false}
											showDots={false}
											responsive={responsive}
											ssr={true} // means to render carousel on server-side.
											infinite={true}
											autoPlay={true}
											autoPlaySpeed={3000}
											keyBoardControl={true}
											customTransition="all 1.5"
											transitionDuration={500}
											containerClass="carousel-container"
											removeArrowOnDeviceType={["tablet", "mobile"]} >
											<Card className='mx-auto' >
												<Card.Img variant="top" src="https://res.cloudinary.com/dm1muiikf/image/upload/v1684484853/website/porfolio/custome_portfolio/1.png" className='img-fluid' />
											</Card>
											<Card className='mx-auto'>
												<Card.Img variant="top" src="https://res.cloudinary.com/dm1muiikf/image/upload/v1684484853/website/porfolio/custome_portfolio/2.png" className='img-fluid' />
											</Card>
											<Card className='mx-auto' >
												<Card.Img variant="top" src="https://res.cloudinary.com/dm1muiikf/image/upload/v1684484853/website/porfolio/custome_portfolio/3.png" className='img-fluid' />
											</Card>
											<Card className='mx-auto' >
												<Card.Img variant="top" src="https://res.cloudinary.com/dm1muiikf/image/upload/v1684484853/website/porfolio/custome_portfolio/4.png" className='img-fluid' />
											</Card>
											<Card className='mx-auto' >
												<Card.Img variant="top" src="https://res.cloudinary.com/dm1muiikf/image/upload/v1684484853/website/porfolio/custome_portfolio/5.png" className='img-fluid' />
											</Card>
											<Card className='mx-auto' >
												<Card.Img variant="top" src="https://res.cloudinary.com/dm1muiikf/image/upload/v1684484853/website/porfolio/custome_portfolio/6.png" className='img-fluid' />
											</Card>
											<Card className='mx-auto' >
												<Card.Img variant="top" src="https://res.cloudinary.com/dm1muiikf/image/upload/v1684484853/website/porfolio/custome_portfolio/7.png" className='img-fluid' />
											</Card>
											<Card className='mx-auto' >
												<Card.Img variant="top" src="https://res.cloudinary.com/dm1muiikf/image/upload/v1684484853/website/porfolio/custome_portfolio/8.png" className='img-fluid' />
											</Card>
											<Card className='mx-auto' >
												<Card.Img variant="top" src="https://res.cloudinary.com/dm1muiikf/image/upload/v1684484853/website/porfolio/custome_portfolio/9.png" className='img-fluid' />
											</Card>
											<Card className='mx-auto' >
												<Card.Img variant="top" src="https://res.cloudinary.com/dm1muiikf/image/upload/v1684484853/website/porfolio/custome_portfolio/10.png" className='img-fluid' />
											</Card>
											<Card className='mx-auto' >
												<Card.Img variant="top" src="https://res.cloudinary.com/dm1muiikf/image/upload/v1684484853/website/porfolio/custome_portfolio/11.png" className='img-fluid' />
											</Card>
											<Card className='mx-auto' >
												<Card.Img variant="top" src="https://res.cloudinary.com/dm1muiikf/image/upload/v1684484853/website/porfolio/custome_portfolio/12.png" className='img-fluid' />
											</Card>
											<Card className='mx-auto' >
												<Card.Img variant="top" src="https://res.cloudinary.com/dm1muiikf/image/upload/v1684484853/website/porfolio/custome_portfolio/13.png" className='img-fluid' />
											</Card>
										</Carousel>
									</div>
								</div>
							</div>
						</div>
						<div className="col-xl-5 ps-md-3 mx-md-5 mx-xl-0 mx-xxl-5 align-self-center">
							<div className=''>
								<h2 className="mb-4 fw-bold" style={{ color: "#FF6C23" }}>POS Managemanet</h2>
								<div className='text-decoration-none'>
									<h3 className="my-4 fs-2 fw-bold text-white">Everyone loves a good POS-itivity boost!</h3>
								</div>
								<p><Faq data={data1} styles={styles} config={config} collapseId={1} />
								</p>
							</div>
							<div className='pt-3'>
								<ModalDialog name="portal" />
							</div>
						</div>
					</div>
				</div>

				{/* Account Management */}
				<div className="container-fluid" style={{ paddingBottom: '150px', paddingTop: '100px' }} data-aos="fade-up">
					<div className="row">
						<div className="col-xl-5 mx-auto d-block d-flex justify-content-center">
							<div class="laptop align-self-center mx-auto">
								<div class="content">
									<div className='single-welcome-slide-wrapper'>
										<Carousel
											swipeable={true}
											draggable={false}
											showDots={false}
											responsive={responsive}
											ssr={true} // means to render carousel on server-side.
											infinite={true}
											autoPlay={true}
											autoPlaySpeed={3000}
											keyBoardControl={true}
											customTransition="all 1.5"
											transitionDuration={500}
											containerClass="carousel-container"
											removeArrowOnDeviceType={["tablet", "mobile"]} >
											<Card className='mx-auto' >
												<Card.Img variant="top" src="https://res.cloudinary.com/dm1muiikf/image/upload/v1684484853/website/porfolio/custome_portfolio/A1.png" className='img-fluid' />
											</Card>
											<Card className='mx-auto'>
												<Card.Img variant="top" src="https://res.cloudinary.com/dm1muiikf/image/upload/v1684484853/website/porfolio/custome_portfolio/A2.png" className='img-fluid' />
											</Card>
											<Card className='mx-auto' >
												<Card.Img variant="top" src="https://res.cloudinary.com/dm1muiikf/image/upload/v1684484853/website/porfolio/custome_portfolio/A3.png" className='img-fluid' />
											</Card>
											<Card className='mx-auto' >
												<Card.Img variant="top" src="https://res.cloudinary.com/dm1muiikf/image/upload/v1684484853/website/porfolio/custome_portfolio/A4.png" className='img-fluid' />
											</Card>
											<Card className='mx-auto' >
												<Card.Img variant="top" src="https://res.cloudinary.com/dm1muiikf/image/upload/v1684484853/website/porfolio/custome_portfolio/A5.png" className='img-fluid' />
											</Card>
											<Card className='mx-auto' >
												<Card.Img variant="top" src="https://res.cloudinary.com/dm1muiikf/image/upload/v1684484853/website/porfolio/custome_portfolio/A6.png" className='img-fluid' />
											</Card>
											<Card className='mx-auto' >
												<Card.Img variant="top" src="https://res.cloudinary.com/dm1muiikf/image/upload/v1684484853/website/porfolio/custome_portfolio/A7.png" className='img-fluid' />
											</Card>
											<Card className='mx-auto' >
												<Card.Img variant="top" src="https://res.cloudinary.com/dm1muiikf/image/upload/v1684484853/website/porfolio/custome_portfolio/A8.png" className='img-fluid' />
											</Card>
											<Card className='mx-auto' >
												<Card.Img variant="top" src="https://res.cloudinary.com/dm1muiikf/image/upload/v1684484853/website/porfolio/custome_portfolio/A9.png" className='img-fluid' />
											</Card>
											<Card className='mx-auto' >
												<Card.Img variant="top" src="https://res.cloudinary.com/dm1muiikf/image/upload/v1684484853/website/porfolio/custome_portfolio/A10.png" className='img-fluid' />
											</Card>
											<Card className='mx-auto' >
												<Card.Img variant="top" src="https://res.cloudinary.com/dm1muiikf/image/upload/v1684484853/website/porfolio/custome_portfolio/A11.png" className='img-fluid' />
											</Card>
											<Card className='mx-auto' >
												<Card.Img variant="top" src="https://res.cloudinary.com/dm1muiikf/image/upload/v1684484853/website/porfolio/custome_portfolio/A12.png" className='img-fluid' />
											</Card>
											<Card className='mx-auto' >
												<Card.Img variant="top" src="https://res.cloudinary.com/dm1muiikf/image/upload/v1684484853/website/porfolio/custome_portfolio/A13.png" className='img-fluid' />
											</Card>
										</Carousel>
									</div>
								</div>
							</div>
						</div>
						<div className="col-xl-5 px-md-5 mx-xl-auto align-self-center pt-5">
							<div className='pt-5'>
								<h2 className="mb-4 fw-bold" style={{ color: "#FF6C23" }}>Account Management Demo</h2>
								<div className='text-decoration-none'>
									<h3 className="my-4 fs-2 fw-bold">Managing Accounts, Empowering Businesses</h3>
								</div>

								<div className="col-md-12 ">
									<div className="mx-auto">
										<Faq data={data2} styles={styles} config={config} collapseId={1} />
									</div>
								</div>

							</div>
							<div className='pt-3'>
								<ModalDialog name="non-commerce" />
							</div>
						</div>
					</div>
				</div>

				{/* NopCommerce DEMO */}
				<div className="container-fluid custome_web">
					<div className="d-xl-flex p-md-0 m-md-0 justify-content-xl-around">
						<div className="col-xl-5 d-md-flex mb-5 pb-5 order-2">
							<div class="laptop align-self-center mx-auto">
								<div class="content">
									<div className='single-welcome-slide-wrapper'>
										<Carousel
											swipeable={true}
											draggable={false}
											showDots={false}
											responsive={responsive}
											ssr={true} // means to render carousel on server-side.
											infinite={true}
											autoPlay={true}
											autoPlaySpeed={3000}
											keyBoardControl={true}
											customTransition="all 1.5"
											transitionDuration={500}
											containerClass="carousel-container"
											removeArrowOnDeviceType={["tablet", "mobile"]} >
											<Card className='mx-auto' >
												<Card.Img variant="top" src="https://res.cloudinary.com/dm1muiikf/image/upload/v1684484853/website/porfolio/custome_portfolio/D1.png" className='img-fluid' />
											</Card>
											<Card className='mx-auto'>
												<Card.Img variant="top" src="https://res.cloudinary.com/dm1muiikf/image/upload/v1684484853/website/porfolio/custome_portfolio/D2.png" className='img-fluid' />
											</Card>
											<Card className='mx-auto' >
												<Card.Img variant="top" src="https://res.cloudinary.com/dm1muiikf/image/upload/v1684484853/website/porfolio/custome_portfolio/D3.png" className='img-fluid' />
											</Card>
											<Card className='mx-auto' >
												<Card.Img variant="top" src="https://res.cloudinary.com/dm1muiikf/image/upload/v1684484853/website/porfolio/custome_portfolio/D4.png" className='img-fluid' />
											</Card>
											<Card className='mx-auto' >
												<Card.Img variant="top" src="https://res.cloudinary.com/dm1muiikf/image/upload/v1684484853/website/porfolio/custome_portfolio/D5.png" className='img-fluid' />
											</Card>
											<Card className='mx-auto' >
												<Card.Img variant="top" src="https://res.cloudinary.com/dm1muiikf/image/upload/v1684484853/website/porfolio/custome_portfolio/D6.png" className='img-fluid' />
											</Card>
											<Card className='mx-auto' >
												<Card.Img variant="top" src="https://res.cloudinary.com/dm1muiikf/image/upload/v1684484853/website/porfolio/custome_portfolio/D7.png" className='img-fluid' />
											</Card>
											<Card className='mx-auto' >
												<Card.Img variant="top" src="https://res.cloudinary.com/dm1muiikf/image/upload/v1684484853/website/porfolio/custome_portfolio/D8.png" className='img-fluid' />
											</Card>
											<Card className='mx-auto' >
												<Card.Img variant="top" src="https://res.cloudinary.com/dm1muiikf/image/upload/v1684484853/website/porfolio/custome_portfolio/D9.png" className='img-fluid' />
											</Card>
											<Card className='mx-auto' >
												<Card.Img variant="top" src="https://res.cloudinary.com/dm1muiikf/image/upload/v1684484853/website/porfolio/custome_portfolio/D10.png" className='img-fluid' />
											</Card>
										</Carousel>
									</div>
								</div>
							</div>
						</div>
						<div className="col-xl-5 ps-md-3 mx-md-5 mx-xl-0 mx-xxl-5 align-self-center">
							<div className=''>
								<h2 className="mb-4 fw-bold" style={{ color: "#FF6C23" }}>Nop-Commerce Demo</h2>
								<div className='text-decoration-none'>
									<h3 className="my-4 fs-2 fw-bold text-white">Amazing E-commerce platform for everyone built using Nop-Commerce.</h3>
								</div>

								<div className="col-md-12 ">
									<div className="mx-auto">
										<Faq data={data3} styles={styles} config={config} collapseId={1} />
									</div>
								</div>

							</div>
							<div className='pt-3'>
								<ModalDialog name="portal" />
							</div>
						</div>
					</div>
				</div>

				{/* E-Commerce DEMO */}
				<div className="container-fluid" style={{ paddingBottom: '150px', paddingTop: '100px' }} data-aos="fade-up">
					<div className="row">
						<div className="col-xl-5 mx-auto d-block d-flex justify-content-center">
							<div class="laptop align-self-center mx-auto">
								<div class="content">
									<div className='single-welcome-slide-wrapper'>
										<Carousel
											swipeable={true}
											draggable={false}
											showDots={false}
											responsive={responsive}
											ssr={true} // means to render carousel on server-side.
											infinite={true}
											autoPlay={true}
											autoPlaySpeed={3000}
											keyBoardControl={true}
											customTransition="all 1.5"
											transitionDuration={500}
											containerClass="carousel-container"
											removeArrowOnDeviceType={["tablet", "mobile"]} >
											<Card className='mx-auto' >
												<Card.Img variant="top" src="https://res.cloudinary.com/dm1muiikf/image/upload/v1684484853/website/porfolio/custome_portfolio/E1.png" className='img-fluid' />
											</Card>
											<Card className='mx-auto'>
												<Card.Img variant="top" src="https://res.cloudinary.com/dm1muiikf/image/upload/v1684484853/website/porfolio/custome_portfolio/E2.png" className='img-fluid' />
											</Card>
											<Card className='mx-auto' >
												<Card.Img variant="top" src="https://res.cloudinary.com/dm1muiikf/image/upload/v1684484853/website/porfolio/custome_portfolio/E3.png" className='img-fluid' />
											</Card>
											<Card className='mx-auto' >
												<Card.Img variant="top" src="https://res.cloudinary.com/dm1muiikf/image/upload/v1684484853/website/porfolio/custome_portfolio/E4.png" className='img-fluid' />
											</Card>
											<Card className='mx-auto' >
												<Card.Img variant="top" src="https://res.cloudinary.com/dm1muiikf/image/upload/v1684484853/website/porfolio/custome_portfolio/E5.png" className='img-fluid' />
											</Card>
											<Card className='mx-auto' >
												<Card.Img variant="top" src="https://res.cloudinary.com/dm1muiikf/image/upload/v1684484853/website/porfolio/custome_portfolio/E6.png" className='img-fluid' />
											</Card>
											<Card className='mx-auto' >
												<Card.Img variant="top" src="https://res.cloudinary.com/dm1muiikf/image/upload/v1684484853/website/porfolio/custome_portfolio/E7.png" className='img-fluid' />
											</Card>
											<Card className='mx-auto' >
												<Card.Img variant="top" src="https://res.cloudinary.com/dm1muiikf/image/upload/v1684484853/website/porfolio/custome_portfolio/E8.png" className='img-fluid' />
											</Card>
											<Card className='mx-auto' >
												<Card.Img variant="top" src="https://res.cloudinary.com/dm1muiikf/image/upload/v1684484853/website/porfolio/custome_portfolio/E9.png" className='img-fluid' />
											</Card>
											<Card className='mx-auto' >
												<Card.Img variant="top" src="https://res.cloudinary.com/dm1muiikf/image/upload/v1684484853/website/porfolio/custome_portfolio/E10.png" className='img-fluid' />
											</Card>
											<Card className='mx-auto' >
												<Card.Img variant="top" src="https://res.cloudinary.com/dm1muiikf/image/upload/v1684484853/website/porfolio/custome_portfolio/E11.png" className='img-fluid' />
											</Card>
											<Card className='mx-auto' >
												<Card.Img variant="top" src="https://res.cloudinary.com/dm1muiikf/image/upload/v1684484853/website/porfolio/custome_portfolio/E12.png" className='img-fluid' />
											</Card>
											<Card className='mx-auto' >
												<Card.Img variant="top" src="https://res.cloudinary.com/dm1muiikf/image/upload/v1684484853/website/porfolio/custome_portfolio/E13.png" className='img-fluid' />
											</Card>
											<Card className='mx-auto' >
												<Card.Img variant="top" src="https://res.cloudinary.com/dm1muiikf/image/upload/v1684484853/website/porfolio/custome_portfolio/E14.png" className='img-fluid' />
											</Card>
										</Carousel>
									</div>
								</div>
							</div>
						</div>
						<div className="col-xl-5 px-md-5 mx-xl-auto align-self-center pt-5">
							<div className='pt-5'>
								<h2 className="mb-4 fw-bold" style={{ color: "#FF6C23" }}>E-Commerce Demo</h2>
								<div className='text-decoration-none'>
									<h3 className="my-4 fs-2 fw-bold">Amazing E-commerce platform for everyone built using React.</h3>
								</div>

								<div className="col-md-12 ">
									<div className="mx-auto">
										<Faq data={data4} styles={styles} config={config} collapseId={1} />
									</div>
								</div>

							</div>
							<div className='pt-3'>
								<ModalDialog name="non-commerce" />
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default Solution;