import React from 'react';
import { BsFillArrowRightCircleFill } from "react-icons/bs";
import { Link } from 'react-router-dom';
import { Helmet } from "react-helmet";

// ================================|| Portfolio ||================================ //

const Portfolio = () => {
	return (
		<>
			<Helmet>
				<title>Portfolio - The Bridge Code</title>
			</Helmet>
			<div class="container-fluid p-0">
				<div class="position-relative overflow-hidden text-center portfolio">
					<div class="col-md-7 p-lg-5 mx-auto my-5">
						<h1 class="display-2 fw-bolder text-white pb-4 title-c">Portfolio</h1>
						<p class="lead fw-normal text-light">We love to see your growth with us</p>
						<Link to='/contact-us' class="cl-button -primary -big rounded-3">Contact Us</Link>
					</div>
				</div>

				<div className="container-fluid px-2 py-3 px-md-5" data-aos="fade-up">
					<div className="row m-0">
						{/* <div class="zoom-ball">
							<div class="icon icon-1">
								<img src="https://res.cloudinary.com/dm1muiikf/image/upload/v1684484853/website/porfolio/shap-30.png" alt="" />
							</div>
						</div> */}
						<div className="col-md-6 d-md-flex">
							<a className="me-md-4 thumbnail" >
								<img src="https://res.cloudinary.com/dm1muiikf/image/upload/v1684484853/website/porfolio/Website-1.png" alt="" className="img-fluid " />
							</a>
						</div>
						<div className="col-md-6 ps-md-3 align-self-center">
							<div className='sky_wrap'>
								<h2 className="mb-4 display-4 fw-bold">Website Development</h2>
								<p className='text-decoration-none text-primary'>
									<h3 className="my-4 fs-3 fw-bold">We Develop website that are simply Impossible to Ignore.</h3>
								</p>
								<p>IT refers to the creating, building, and maintaining of websites. Creating a simple single static page of plain text to complex web applications we have helped numeruous businesses develop their website.</p>
								<Link to='/portfolio/website-development'>
									<button type="button" class="cl-button -primary -small">VIEW PORTFOLIO <BsFillArrowRightCircleFill /> </button>
								</Link>
							</div>
						</div>
					</div>
				</div>

				<div className="container-fluid bg-light py-5">
					<div className="d-md-flex ps-md-5">
						<a className="order-2">
							<img src="https://res.cloudinary.com/dm1muiikf/image/upload/v1684484853/website/porfolio/Mobile-App-Development.png" alt="" className="img-fluid" />
						</a>
						<div className="col-md-6 align-self-center ps-md-5 mt-4 mt-md-0 me-md-4">
							<div className='sky_wrap'>
								<h2 className="mb-4 display-4 fw-bold">Mobile Application Development</h2>
								<p className='text-decoration-none text-primary'>
									<h3 className="my-4 fs-3 fw-bold">MAKING THINGS EASY FOR YOU</h3>
								</p>
								<p>The Bridgecode carries intensive expertise in making high-performing, digitally transforming, and full-feature-packed native applications for all leading mobile platforms.</p>
								<Link to='/portfolio/mobile-development'>
									<button type="button" class="cl-button -primary -small">VIEW PORTFOLIO <BsFillArrowRightCircleFill /> </button>
								</Link>
							</div>
						</div>
					</div>
				</div>

				<div className="container-fluid px-2 py-3 px-md-5" data-aos="fade-up">
					<div className="row m-0">
						<div className="col-md-6 d-md-flex">
							<a className="me-md-4 thumbnail">
								<img src="https://res.cloudinary.com/dm1muiikf/image/upload/v1684484853/website/porfolio/Custom-Software-Development.png" alt="" className="img-fluid" />
							</a>
						</div>
						<div className="col-md-6 ps-md-3 align-self-center">
							<div className='sky_wrap'>
								<h2 className="mb-4 display-4 fw-bold">Custom Software Development</h2>
								<p className='text-decoration-none text-primary'>
									<h3 className="my-4 fs-3 fw-bold">CATERS TO YOUR SPECIFIC NEEDS</h3>
								</p>
								<p>The Bridgecode started its journey by providing custom software solutions. With our core expertise in developing customized solutions.</p>
								<Link to='/portfolio/custom-development'>
									<button type="button" class="cl-button -primary -small">VIEW PORTFOLIO <BsFillArrowRightCircleFill /> </button>
								</Link>
							</div>
						</div>
					</div>
				</div>

				<div className="container-fluid bg-light py-5">
					<div className="d-md-flex ps-md-5">
						<a className="order-2">
							<img src="https://res.cloudinary.com/dm1muiikf/image/upload/v1684484853/website/porfolio/Ecommerce-Solution-1.png" alt="" className="img-fluid" />
						</a>
						<div className="col-md-6 align-self-center ps-md-5 mt-4 mt-md-0 me-md-4">
							<div className='sky_wrap'>
								<h2 className="mb-4 display-4 fw-bold">E-commerce Devlopment</h2>
								<p className='text-decoration-none text-primary'>
									<h3 className="my-4 fs-3 fw-bold">STAND OUT FROM COMPETITION IN MARKET</h3>
								</p>
								<p>With Bridgecode Top-rated ecommerce solutions, stand out from the competitive landscape, and scale up your business solutions.</p>
								<Link to='/portfolio/e-commerce-development'>
									<button type="button" class="cl-button -primary -small">VIEW PORTFOLIO <BsFillArrowRightCircleFill /> </button>
								</Link>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default Portfolio;