import React from 'react';
import ReactDOM from 'react-dom/client';
// import ReactDOM from 'react-dom';
import './index.css';
import 'swiper/css/bundle';
import App from './App';
import Admin from './admin/Admin';
import { Cookies } from "react-cookie";
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';

const root = ReactDOM.createRoot(document.getElementById('root'));
const admin = ReactDOM.createRoot(document.getElementById('admin'));
const cookies = new Cookies();
const user = cookies.get('username');
const userId = cookies.get('id');

if ((user != null && user != "") || (userId != null && userId != "")) {
  admin.render(
    <React.StrictMode>
      <Admin />
    </React.StrictMode>
  );
  root.render(
    document.getElementById('root').style.display === "none"
  );
} else {
  root.render(
    <React.StrictMode>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </React.StrictMode>
  );
  admin.render(
    document.getElementById('admin').style.display === "none"
  );
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
