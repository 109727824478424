import React from 'react';
import '../../navbar/app-1.scss';
import { Link } from 'react-router-dom';
import { Helmet } from "react-helmet";

// ================================|| EcommerceDevelopmentPortfolio ||================================ //

const EcommerceDevelopmentPortfolio = () => {

    return (
        <>
            <Helmet>
				<title>Ecommerce Development Portfolio - The Bridge Code</title>
			</Helmet>
            <div className="container-fluid p-0">
                <div className="position-relative overflow-hidden text-center pro_web">
                    <div className="col-md-7 p-lg-5 mx-auto ">
                        <h1 className="display-2 fw-bolder text-dark pb-4 title-c">Ecommerce Development Portfolio</h1>
                        <p className="lead fw-normal text-dark">We love to see your growth with us</p>
                    </div>
                </div>

                <div className="container-fluid" style={{ paddingBottom: '100px', paddingTop: '100px' }} data-aos="fade-up">
                    <div className="row">
                        <div className="col-md-5 mx-auto d-block d-md-flex align-self-center">
                            <img src='https://res.cloudinary.com/dm1muiikf/image/upload/v1684484853/website/ecomm/snowmart.png' className='img-fluid shadow-sl rounded-3' alt='snowmart'/>
                        </div>
                        <div className="col-md-5 ps-md-3 mx-auto align-self-center pt-5">
                            <div className=''>
                                <h2 className="mb-4 fw-bold" style={{ color: "#FF6C23" }}>Snowmart Marketplace</h2>
                                <h3 className="my-4 fs-2 fw-bold">Giving the best e-commerce experience</h3>
                                <p>Snowmart is an Cloud based Grocery shopping E-commerce Platform where Customer can purchase various day-to-day basis products and order them online
                                    without any hassle and easy payment options. Snowmart is a one-stop supermarket chain that aims to offer customers a wide range of basic home and
                                    personal products under one roof. Our core objective is to offer customers good products at great value.
                                </p>
                            </div>
                            <div className='pt-3'>
                                <Link to='/solution' className="cl-button -primary -small mx-1 px-3 rounded-3">Try Free Demo</Link>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="container-fluid custome_web" >
                    <div className="row d-md-flex p-md-0 m-md-0 justify-content-center">
                        <div className="col-md-5 d-md-flex align-self-center order-2">
                            <img src='https://res.cloudinary.com/dm1muiikf/image/upload/v1684484853/website/ecomm/jiosupply.png' className='img-fluid shadow-sl border border-3 rounded-3' alt='Jio Supply'/>
                        </div>
                        <div className="col-md-5 ps-md-3 align-self-center">
                            <div className=''>
                                <h2 className="mb-4 fw-bold" style={{ color: "#FF6C23" }}>JioSupply</h2>
                                <h3 className="my-4 fs-2 fw-bold text-white">Giving the best e-commerce experience</h3>
                                <p>JioSupply is an your one-stop shop for all your hotel Industry Amenities. They have been Operational since 10 years serving Hotels in USA. They built a
                                    reputation for providing top-quality products at competitive prices. Client wanted to develop a portal for Hotel Industry where they can contact them
                                    for all the amenities which is used in hotel like bedding, bath linens, or guest room amenities etc. Earlier very few people in the Hotel Industry used
                                    online solutions for Ordering, but after JioSupply started the platform is widely used and also have a 99% customer retention rate.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default EcommerceDevelopmentPortfolio;