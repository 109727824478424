import React from 'react';
import ModalImage from "react-modal-image";
import { Link } from 'react-router-dom';
import { Helmet } from "react-helmet";

// ================================|| WebsiteDevelopmentPortfolio ||================================ //

const WebsiteDevelopmentPortfolio = () => {
	return (
		<>
			<Helmet>
				<title>Website Development Portfolio - The Bridge Code</title>
			</Helmet>
			<div className="er-fluid bg-light " style={{ paddingTop: '150px' }}>
				<div className='row'>
					<div className='justify-content-center text-center pb-5'>
						<div className='col-md-6' style={{ marginBottom: "10px" }}>
							<h1 className='display-1 fw-bold' style={{ color: "#FF6C23" }}>Our Work</h1>
							<p className='fs-3'>We partner with leaders to imagine digital solutions that drive transformational value. And then we make them real.</p>
						</div>
					</div>
					<div className='row text-center d-flex justify-content-around mb-5 mx-auto contain mt-5'>
						<div className='three d-inline mx-1 mb-5'>
							<ModalImage
								small={'https://res.cloudinary.com/dm1muiikf/image/upload/v1684484853/website/porfolio/website_portfolio/01.png'}
								large={'https://res.cloudinary.com/dm1muiikf/image/upload/v1684484853/website/porfolio/website_portfolio/01.png'}
								alt="The Bridge Code!"
								hideDownload="true"
								className="shadow-sl border border-dark border-2 rounded-3"
							/>
						</div>
						<div className='three d-inline mb-5 mx-1 ' style={{ marginTop: "-80px" }}>
							<ModalImage
								small={'https://res.cloudinary.com/dm1muiikf/image/upload/v1684484853/website/porfolio/website_portfolio/02.png'}
								large={'https://res.cloudinary.com/dm1muiikf/image/upload/v1684484853/website/porfolio/website_portfolio/02.1.png'}
								alt="The Bridge Code!"
								hideDownload="true"
								className="shadow-sl border border-dark border-2 rounded-3"
							/>
						</div>
						<div className='three d-inline mb-5 mx-1 ' style={{ marginTop: "-150px" }}>
							<ModalImage
								small={'https://res.cloudinary.com/dm1muiikf/image/upload/v1684484853/website/porfolio/website_portfolio/03.png'}
								large={'https://res.cloudinary.com/dm1muiikf/image/upload/v1684484853/website/porfolio/website_portfolio/03.1.png'}
								alt="The Bridge Code!"
								hideDownload="true"
								className="shadow-sl border border-dark border-2 rounded-3"
							/>
						</div>
					</div>

					<div className='row text-center d-flex justify-content-around mb-5 mx-auto contain'>
						<div className='three d-inline mx-1 mb-5 '>
							<ModalImage
								small={'https://res.cloudinary.com/dm1muiikf/image/upload/v1684484853/website/porfolio/website_portfolio/04.png'}
								large={'https://res.cloudinary.com/dm1muiikf/image/upload/v1684484853/website/porfolio/website_portfolio/04.1.png'}
								alt="The Bridge Code!"
								hideDownload="true"
								className="shadow-sl border border-dark border-2 rounded-3"
							/>
						</div>
						<div className='three d-inline mb-5 mx-1 ' style={{ marginTop: "-80px" }}>
							<ModalImage
								small={'https://res.cloudinary.com/dm1muiikf/image/upload/v1684484853/website/porfolio/website_portfolio/05.png'}
								large={'https://res.cloudinary.com/dm1muiikf/image/upload/v1684484853/website/porfolio/website_portfolio/05.png'}
								alt="The Bridge Code!"
								hideDownload="true"
								className="shadow-sl border border-dark border-2 rounded-3"
							/>
						</div>
						<div className='three d-inline mb-5 mx-1 ' style={{ marginTop: "-150px" }}>
							<ModalImage
								small={'https://res.cloudinary.com/dm1muiikf/image/upload/v1684484853/website/porfolio/website_portfolio/06.png'}
								large={'https://res.cloudinary.com/dm1muiikf/image/upload/v1684484853/website/porfolio/website_portfolio/06.1.png'}
								alt="The Bridge Code!"
								hideDownload="true"
								className="shadow-sl border border-dark border-2 rounded-3"
							/>
						</div>
					</div>

					<div className='row text-center d-flex justify-content-around mb-5 mx-auto contain'>
						<div className='three d-inline mx-1 mb-5 '>
							<ModalImage
								small={'https://res.cloudinary.com/dm1muiikf/image/upload/v1684484853/website/porfolio/website_portfolio/07.png'}
								large={'https://res.cloudinary.com/dm1muiikf/image/upload/v1684484853/website/porfolio/website_portfolio/07.1.png'}
								alt="The Bridge Code!"
								hideDownload="true"
								className="shadow-sl border border-dark border-2 rounded-3"
							/>
						</div>
						<div className='three d-inline mb-5 mx-1 ' style={{ marginTop: "-80px" }}>
							<ModalImage
								small={'https://res.cloudinary.com/dm1muiikf/image/upload/v1684484853/website/porfolio/website_portfolio/08.png'}
								large={'https://res.cloudinary.com/dm1muiikf/image/upload/v1684484853/website/porfolio/website_portfolio/08.1.png'}
								alt="The Bridge Code!"
								hideDownload="true"
								className="shadow-sl border border-dark border-2 rounded-3"
							/>
						</div>
						<div className='three d-inline mb-5 mx-1 ' style={{ marginTop: "-150px" }}>
							<ModalImage
								small={'https://res.cloudinary.com/dm1muiikf/image/upload/v1684484853/website/porfolio/website_portfolio/09.png'}
								large={'https://res.cloudinary.com/dm1muiikf/image/upload/v1684484853/website/porfolio/website_portfolio/09.1.png'}
								alt="The Bridge Code!"
								hideDownload="true"
								className="shadow-sl border border-dark border-2 rounded-3"
							/>
						</div>
					</div>

					<div className='row text-center d-flex justify-content-around mb-5 mx-auto contain'>
						<div className='three d-inline mx-1 mb-5 '>
							<ModalImage
								small={'https://res.cloudinary.com/dm1muiikf/image/upload/v1684484853/website/porfolio/website_portfolio/10.png'}
								large={'https://res.cloudinary.com/dm1muiikf/image/upload/v1684484853/website/porfolio/website_portfolio/10.1.png'}
								alt="The Bridge Code!"
								hideDownload="true"
								className="shadow-sl border border-dark border-2 rounded-3"
							/>
						</div>
						<div className='three d-inline mb-5 mx-1 ' style={{ marginTop: "-80px" }}>
							<ModalImage
								small={'https://res.cloudinary.com/dm1muiikf/image/upload/v1684484853/website/porfolio/website_portfolio/11.png'}
								large={'https://res.cloudinary.com/dm1muiikf/image/upload/v1684484853/website/porfolio/website_portfolio/11.1.png'}
								alt="The Bridge Code!"
								hideDownload="true"
								className="shadow-sl border border-dark border-2 rounded-3"
							/>
						</div>
						<div className='three d-inline mb-5 mx-1 ' style={{ marginTop: "-150px" }}>
							<ModalImage
								small={'https://res.cloudinary.com/dm1muiikf/image/upload/v1684484853/website/porfolio/website_portfolio/12.png'}
								large={'https://res.cloudinary.com/dm1muiikf/image/upload/v1684484853/website/porfolio/website_portfolio/12.1.png'}
								alt="The Bridge Code!"
								hideDownload="true"
								className="shadow-sl border border-dark border-2 rounded-3"
							/>
						</div>
					</div>

					<div className='row text-center d-flex justify-content-around mb-5 mx-auto contain'>
						<div className='three d-inline mx-1 mb-5 '>
							<ModalImage
								small={'https://res.cloudinary.com/dm1muiikf/image/upload/v1684484853/website/porfolio/website_portfolio/13.png'}
								large={'https://res.cloudinary.com/dm1muiikf/image/upload/v1684484853/website/porfolio/website_portfolio/13.1.png'}
								alt="The Bridge Code!"
								hideDownload="true"
								className="shadow-sl border border-dark border-2 rounded-3"
							/>
						</div>
						<div className='three d-inline mb-5 mx-1 ' style={{ marginTop: "-80px" }}>
							<ModalImage
								small={'https://res.cloudinary.com/dm1muiikf/image/upload/v1684484853/website/porfolio/website_portfolio/14.png'}
								large={'https://res.cloudinary.com/dm1muiikf/image/upload/v1684484853/website/porfolio/website_portfolio/14.1.png'}
								alt="The Bridge Code!"
								hideDownload="true"
								className="shadow-sl border border-dark border-2 rounded-3"
							/>
						</div>
						<div className='three d-inline mb-5 mx-1 ' style={{ marginTop: "-150px" }}>
							<ModalImage
								small={'https://res.cloudinary.com/dm1muiikf/image/upload/v1684484853/website/porfolio/website_portfolio/15.png'}
								large={'https://res.cloudinary.com/dm1muiikf/image/upload/v1684484853/website/porfolio/website_portfolio/15.1.png'}
								alt="The Bridge Code!"
								hideDownload="true"
								className="shadow-sl border border-dark border-2 rounded-3"
							/>
						</div>
					</div>

					<div className='row text-center d-flex justify-content-around mb-5 mx-auto contain'>
						<div className='three d-inline mx-1 mb-5 '>
							<ModalImage
								small={'https://res.cloudinary.com/dm1muiikf/image/upload/v1684484853/website/porfolio/website_portfolio/16.png'}
								large={'https://res.cloudinary.com/dm1muiikf/image/upload/v1684484853/website/porfolio/website_portfolio/16.1.png'}
								alt="The Bridge Code!"
								hideDownload="true"
								className="shadow-sl border border-dark border-2 rounded-3"
							/>
						</div>
						<div className='three d-inline mb-5 mx-1 ' style={{ marginTop: "-80px" }}>
							<ModalImage
								small={'https://res.cloudinary.com/dm1muiikf/image/upload/v1684484853/website/porfolio/website_portfolio/18.png'}
								large={'https://res.cloudinary.com/dm1muiikf/image/upload/v1684484853/website/porfolio/website_portfolio/18.1.png'}
								alt="The Bridge Code!"
								hideDownload="true"
								className="shadow-sl border border-dark border-2 rounded-3"
							/>
						</div>
						<div className='three d-inline mb-5 mx-1 ' style={{ marginTop: "-150px" }}>
							<ModalImage
								small={'https://res.cloudinary.com/dm1muiikf/image/upload/v1684484853/website/porfolio/website_portfolio/19.png'}
								large={'https://res.cloudinary.com/dm1muiikf/image/upload/v1684484853/website/porfolio/website_portfolio/19.1.png'}
								alt="The Bridge Code!"
								hideDownload="true"
								className="shadow-sl border border-dark border-2 rounded-3"
							/>
						</div>
					</div>

					<div className='row text-center d-flex justify-content-around mb-5 mx-auto contain'>
						<div className='three d-inline mx-1 mb-5 '>
							<ModalImage
								small={'https://res.cloudinary.com/dm1muiikf/image/upload/v1684484853/website/porfolio/website_portfolio/20.png'}
								large={'https://res.cloudinary.com/dm1muiikf/image/upload/v1684484853/website/porfolio/website_portfolio/20.1.png'}
								alt="The Bridge Code!"
								hideDownload="true"
								className="shadow-sl border border-dark border-2 rounded-3"
							/>
						</div>
						<div className='three d-inline mb-5 mx-1 ' style={{ marginTop: "80px" }}>
							<Link to='/contact-us' className="cl-button -primary -big">Contact Us</Link>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default WebsiteDevelopmentPortfolio;