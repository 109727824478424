import { useState, useEffect } from "react";
import { MdDelete } from "react-icons/md";
import { Link, useNavigate } from 'react-router-dom';
import { FaFilePdf, FaLinkedin, FaGithub, FaFileArchive, FaUserSlash, FaUserCheck, FaEye } from "react-icons/fa";
import { Helmet } from "react-helmet";
import moment from 'moment';
import API from '../../../middleware/API';
import DataTable from 'react-data-table-component';

// ================================|| RecruitmentList ||================================ //

function RecruitmentList() {
    const navigate = useNavigate();
    const [Page, setPage] = useState(1);
    const [Careerdata, setCareerData] = useState();
    const [TotalCareer, setTotalCareer] = useState();
    const [loading, setLoading] = useState(false);

    const rowsperPage = "5";

    useEffect(() => {
        getCareerList();
    }, [Page]);

    const getCareerList = async () => {
        try {
            setLoading(true);
            var GetCareerDetailAPI = API.getCareerDetail;
            GetCareerDetailAPI = GetCareerDetailAPI + '?pageNumber=' + Page + '&rowsperPage=' + rowsperPage;
            const response = await fetch(GetCareerDetailAPI);
            const jsonData = await response.json();
            setCareerData(jsonData.recordsets[0]);
            setTotalCareer(jsonData.recordsets[1][0].TotalRows);
            setLoading(false);
        }
        catch (error) {
            console.log(error);
            setLoading(false);
        }
    };

    async function replyCandidateNotAppropriateForJobOpening(props) {
        try {
            var UpdateCareerStatusById = API.updateCareerStatusById;
            let res = await fetch(UpdateCareerStatusById, {
                method: "POST",
                headers: {
                    'Accept': 'application/json',
                    'Content-type': 'application/json',
                },
                json: true,
                body: JSON.stringify({
                    id: props.Id,
                    firstName: props.FirstName,
                    lastName: props.LastName,
                    appliedForRole: props.AppliedForRole,
                    otherRole: props.OtherRole,
                    email: props.Email,
                    status: 0
                }),
            });
            let resJson = await res.json();
            if (res.status === 200) {
                getCareerList(Page);
            }
        }
        catch (error) {
            console.log(error);
        }
    }
    
    async function replyCandidateSuitableForJobOpening(props) {
        try {
            var UpdateCareerStatusById = API.updateCareerStatusById;
            let res = await fetch(UpdateCareerStatusById, {
                method: "POST",
                headers: {
                    'Accept': 'application/json',
                    'Content-type': 'application/json',
                },
                json: true,
                body: JSON.stringify({
                    id: props.Id,
                    firstName: props.FirstName,
                    lastName: props.LastName,
                    appliedForRole: props.AppliedForRole,
                    otherRole: props.OtherRole,
                    email: props.Email,
                    status: 1
                }),
            });
            let resJson = await res.json();
            if (res.status === 200) {
                getCareerList(Page);
            }
        }
        catch (error) {
            console.log(error);
        }
    }

    function viewCareerById(id) {
        navigate('/admin/recruitment?Id=' + id);
    }

    function deleteCareerById(id) {
        var result = window.confirm('Are you sure you want to delete this record?');
        if (result) {
            try {
                var DeleteCareerDetailByIdAPI = API.deleteCareerDetailById;
                DeleteCareerDetailByIdAPI = DeleteCareerDetailByIdAPI + "?id=" + id;
                const response = fetch(DeleteCareerDetailByIdAPI).then(() => {
                    getCareerList(Page);
                });
            }
            catch (error) {
                console.log(error);
            }
        }
    }

    const columns = [
        {
            name: 'Id',
            selector: row => row.Id,
            sortable: true,
            width: "4rem"
        },
        {
            name: 'First Name',
            selector: row => row.FirstName,
            sortable: true,
            width: "7rem"
        },
        {
            name: 'Last Name',
            selector: row => row.LastName,
            sortable: true,
            width: "7rem"
        },
        {
            name: 'Applied For Role',
            selector: row => row.AppliedForRole,
            sortable: true,
            width: "8rem"
        },
        {
            name: 'Other Role',
            cell: (row) => row.OtherRole ? row.OtherRole : (<span>N/A</span>),
            sortable: true,
            width: "12rem"
        },
        {
            name: 'CV',
            cell: (props) =>
                <div><a href={props.CV} target="_blank"><FaFilePdf size={'1.5rem'} /></a></div>,
            sortable: false,
            width: "4rem"
        },
        {
            name: 'Linked In',
            cell: (props) =>
                <div><a href={props.LinkedIn} target="_blank"><FaLinkedin size={'1.5rem'} /></a></div>,
            sortable: false,
            width: "6rem"
        },
        {
            name: 'GitHub',
            cell: (props) =>
                <div><a href={props.GitHub} target="_blank"><FaGithub size={'1.5rem'} /></a></div>,
            sortable: false,
            width: "5rem"
        },
        {
            name: 'Portfolio',
            cell: (props) =>
                <div><a href={props.PortFolio} target="_blank"><FaFileArchive size={'1.5rem'} /></a></div>,
            sortable: false,
            width: "5rem"
        },
        {
            name: 'Created Date',
            selector: row => moment(row.CreatedOn).format('DD-MM-YYYY'),
            sortable: true,
            width: "8rem"
        },
        {
            name: 'Action',
            cell: (props) => <div>
                <a><FaUserSlash size={30} title="Reply to Candidate not appropriate for Job Opening" className="m-1 p-1 cl-button -primary -small" onClick={() => replyCandidateNotAppropriateForJobOpening(props)} style={{ cursor: "pointer" }} /></a>
                <a><FaUserCheck size={30} title="Reply to Candidate suitable for Job Opening" className="m-1 p-1 cl-button -primary -small" onClick={() => replyCandidateSuitableForJobOpening(props)} style={{ cursor: "pointer" }} /></a>
                <a><FaEye size={30} className="m-1 p-1 cl-button -primary -small" onClick={() => viewCareerById(props.Id)} style={{ cursor: "pointer" }} /></a>
                <a><MdDelete size={30} className="m-1 p-1 cl-button -primary -small" onClick={() => deleteCareerById(props.Id)} style={{ cursor: "pointer" }} /></a>
            </div>,
            sortable: false
        },
    ];

    return (
        <>
            <Helmet>
                <title>Recruitment - The Bridge Code</title>
            </Helmet>
            <div className="container-fluid">
                <div className="container d-flex pt-5 pb-3">
                    <div className="col">
                        <h3 className="card-header px-3 ">Recruitment Details (Career)</h3>
                    </div>
                    <div className="col " style={{ textAlign: "end" }}>
                        <Link to='/admin/replied' className="btn btn-info cl-button -primary mx-1 my-1">Replied</Link>
                    </div>
                </div>
                <div className="container mx-auto pt-3">
                    <div className="px-3 pb-3">
                        <div className="col-sm-12 table-responsive text-nowrap shadow p-3 mb-5 bg-body rounded">
                            <DataTable
                                columns={columns}
                                data={Careerdata}
                                pagination
                                paginationServer
                                progressPending={loading}
                                paginationTotalRows={TotalCareer}
                                paginationPerPage={rowsperPage}
                                paginationComponentOptions={{
                                    noRowsPerPage: true
                                }}
                                onChangePage={page => setPage(page)}
                            ></DataTable>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default RecruitmentList;