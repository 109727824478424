import React from 'react';
import '../../navbar/app-1.scss';
import { Link } from 'react-router-dom';

// ================================|| CustomDevelopmentPortfolio ||================================ //

const CustomDevelopmentPortfolio = () => {

    return (
        <>
            <div className="container-fluid p-0">
                <div className="position-relative overflow-hidden text-center pro_web">
                    <div className="col-md-7 p-lg-5 mx-auto ">
                        <h1 className="display-2 fw-bolder text-dark pb-4 title-c">Custom Development Portfolio</h1>
                        <p className="lead fw-normal text-dark">We love to see your growth with us</p>
                    </div>
                </div>

                <div className="container-fluid" style={{ paddingBottom: '100px', paddingTop: '100px' }} data-aos="fade-up">
                    <div className="row">
                        <div className="col-md-5 mx-auto d-block d-md-flex align-self-center pb-5">
                            <img src='https://res.cloudinary.com/dm1muiikf/image/upload/v1684484853/website/porfolio/custome_portfolio/B1.1.png' className='img-fluid shadow-sl border border-3 rounded-3' alt='CRM'/>
                        </div>
                        <div className="col-md-5 ps-md-3 align-self-center">
                            <div className=''>
                                <h2 className="mb-4 fw-bold" style={{ color: "#FF6C23" }}>CRM Development</h2>
                                <h3 className="my-4 fs-2 fw-bold">Providing CRM strategy that works</h3>
                                <p>CRM is the combination of practices, strategies and technologies that companies use to manage and analyze customer interactions and data throughout the
                                    customer lifecycle. The goal is to improve customer service relationships and assist in customer retention and drive sales growth.
                                    <p>We developed unique features by understanding Client business by using this client has seen fast turnaround time with sales and exponential growth.</p>
                                </p>
                            </div>
                            <div className='pt-3'>
                                <Link to='/solution' className="cl-button -primary -small mx-1">Try Free Demo</Link>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="container-fluid custome_web" >
                    <div className="row d-md-flex p-md-0 m-md-0 justify-content-center">

                        <div className="col-md-5 mx-auto d-block d-md-flex align-self-center order-md-2 pt-4">
                            <img src='https://res.cloudinary.com/dm1muiikf/image/upload/v1684484853/website/porfolio/custome_portfolio/1.png' className='img-fluid shadow-sl border border-3 rounded-3 ' alt='POS'/>
                        </div>

                        <div className="col-md-5 ps-md-3 mx-auto align-self-center pt-5">
                            <div className=''>
                                <h2 className="mb-4 fw-bold" style={{ color: "#FF6C23" }}>POS System Development</h2>
                                <h3 className="my-4 fs-2 fw-bold">Making Transactions fast, efficient, and stress-free.</h3>
                                <p>POS solution designed for walk-in customers who wants fast and hassle free checkout Experience. The Client came with a Unique requirement
                                    where he wish to Integrate E-commerce, POS and Mobile app all together. We have developed a Custom solution for the Client according to his needs where
                                    he can manage and check multiple business from different platform with same accuracy and Single Database.
                                </p>
                            </div>
                            <div className='pt-3'>
                                <Link to='/solution' className="cl-button -secondary -small mx-1 px-3 rounded-3">Try Free Demo</Link>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="container-fluid" style={{ paddingBottom: '150px', paddingTop: '100px' }} data-aos="fade-up">
                    <div className="row">
                        <div className="col-md-5 mx-auto d-block d-md-flex align-self-center pb-5">
                            <img src='https://res.cloudinary.com/dm1muiikf/image/upload/v1684484853/website/porfolio/custome_portfolio/A1.png' className='img-fluid shadow-sl border border-3 rounded-3' alt='Account Managemnt'/>
                        </div>
                        <div className="col-md-5 ps-md-3 mx-auto align-self-center pt-5">
                            <div className=''>
                                <h2 className="mb-4 fw-bold" style={{ color: "#FF6C23" }}>Account Managemnt</h2>
                                <h3 className="my-4 fs-2 fw-bold">Advancing Your Financial Strategy</h3>
                                <p>Today's World Accounting is led by Technology by using Accounting software makes your Business Smart, Error-free and Easy to manage. From basic invoicing
                                    and billing, to tax calculations and project management, accounting software is an essential tool for your business' financial data. It also helps
                                    manage clients, reconcile bank accounts, and generate insightful financial reports that help your business grow smoothly and continuously. Our Client
                                    used Excel sheets to manage his accounting we designed a simple to use yet Effective Software which helps the client in Day-to-Day Accounting and
                                    Decision Making.
                                </p>
                            </div>
                            <div className='pt-3'>
                                <Link to='/solution' className="cl-button -primary -small mx-1">Try Free Demo</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default CustomDevelopmentPortfolio;