import React, { useState } from 'react';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'animate.css';
import './site/navbar/app-1.scss';
import { Routes, Route, useLocation } from 'react-router-dom';
import { Cookies, useCookies } from "react-cookie";
import Header from './site/navbar/Header';
import Footer from './site/navbar/Footer';
import Home from './site/pages';
import About from './site/pages/about/About';
import Login from './site/pages/login/Login';
import Portfolio from './site/pages/portfolio/Portfolio';
import Solution from './site/pages/solution/Solution';
import Contact from './site/pages/contact/Contact';
import Services from './site/pages/services-pages/Services';
import Career from './site/pages/career/Career';
import MobileApplicationDevelopment from './site/pages/services-pages/MobileApplicationDevelopment';
import WebsiteDevelopment from './site/pages/services-pages/WebsiteDevelopment';
import CustomSoftwareDevelopment from './site/pages/services-pages/CustomSoftwareDevelopment';
import HybridAppDevelopment from './site/pages/services-pages/HybridAppDevelopment';
import EcommerceDevelopment from './site/pages/services-pages/EcommerceDevelopment';
import HireDedicatedResource from './site/pages/services-pages/HireDedicatedResource';
import WebsiteDevelopmentPortfolio from './site/pages/portfolio/WebsiteDevelopment';
import CustomDevelopmentPortfolio from './site/pages/portfolio/CustomDevelopment';
import MobileDevelopmentPortfolio from './site/pages/portfolio/MobileDevelopment';
import EcommerceDevelopmentPortfolio from './site/pages/portfolio/EcommerceDevelopment';
import CaseStudy from './site/pages/case-study/CaseStudy';
import SupplyChain from './site/pages/case-study/SupplyChain';
import Travel from './site/pages/case-study/Travel';
import Hitech from './site/pages/case-study/Hitech';
import HealthCare from './site/pages/case-study/HealthCare';
import DigitalRetail from './site/pages/case-study/DigitalRetail';
import Fintech from './site/pages/case-study/Fintech';
import MediaAdvertising from './site/pages/case-study/MediaAdvertising';
import PrivacyPolicy from './site/pages/policy-pages/PrivacyPolicy';
import TermsOfUse from './site/pages/policy-pages/TermsOfUse';
import TermsConditions from './site/pages/policy-pages/TermsConditions';
import Blogs from './site/pages/blog/Blogs';
import Blog from './site/pages/blog/Blog';
import Page404 from './site/pages/extra-pages/Page404';
import "../src/site/scss/demo.scss";


function App() {
	const [isAuth, setIsAuth] = useState(localStorage.getItem("isAuth"));
	const location = useLocation();

	const cookies = new Cookies();
	const BlogSlug = cookies.get('Slug');

	const data = {
		isAuth,
		setIsAuth,
	};
	console.log(data);

	return (
		<>
			{location.pathname == "/login" ? null : <Header />}{" "}
			<Routes>
				<Route exact path='/' element={<Home />} />
				<Route path='/about-us' element={<About />} />
				<Route path='/blogs' element={<Blogs />} />
				<Route exact path='/blog/:BlogSlug' element={<Blog />} />
				<Route path='/login' element={<Login />} />
				<Route path='/services' element={<Services />} />
				<Route path='/portfolio' element={<Portfolio />} />
				<Route path='/solution' element={<Solution />} />
				<Route path='/career' element={<Career />} />
				<Route path='/contact-us' element={<Contact />} />
				<Route path='/mobile-application-development' element={<MobileApplicationDevelopment />} />
				<Route path='/website-development' element={<WebsiteDevelopment />} />
				<Route path='/custom-software-development' element={<CustomSoftwareDevelopment />} />
				<Route path='/hybridapp-development' element={<HybridAppDevelopment />} />
				<Route path='/e-commerce-development' element={<EcommerceDevelopment />} />
				<Route path='/hire-dedicated-resources' element={<HireDedicatedResource />} />
				<Route path='/portfolio/website-development' element={<WebsiteDevelopmentPortfolio />} />
				<Route path='/portfolio/custom-development' element={<CustomDevelopmentPortfolio />} />
				<Route path='/portfolio/mobile-development' element={<MobileDevelopmentPortfolio />} />
				<Route path='/portfolio/e-commerce-development' element={<EcommerceDevelopmentPortfolio />} />
				<Route path='/case-study' element={<CaseStudy />} />
				<Route path='/case-study/supplychain' element={<SupplyChain />} />
				<Route path='/case-study/travel' element={<Travel />} />
				<Route path='/case-study/hitech' element={<Hitech />} />
				<Route path='/case-study/healthcare' element={<HealthCare />} />
				<Route path='/case-study/digitalretail' element={<DigitalRetail />} />
				<Route path='/case-study/fintech' element={<Fintech />} />
				<Route path='/case-study/mediaadvertising' element={<MediaAdvertising />} />
				<Route path='/privacy-policy' element={<PrivacyPolicy />} />
				<Route path='/terms-of-use' element={<TermsOfUse />} />
				<Route path='/terms-conditions' element={<TermsConditions />} />
				<Route path="*" element={<Page404 />} />
			</Routes>
			{document.getElementById('chatIconTbc') && location.pathname == "/" ? document.getElementById('chatIconTbc').classList.remove('d-none') : null}{" "}
			{location.pathname == "/login" ? document.getElementById('chatIconTbc').classList.add('d-none') : null}{" "}
			{location.pathname == "/login" ? null : <Footer />}{" "}
		</>
	);
}

export default App;