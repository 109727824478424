import { useState, useEffect } from "react";
import { FaFile } from "react-icons/fa";
import { Link, useSearchParams } from 'react-router-dom';
import moment from 'moment';
import API from '../../../middleware/API';

// ================================|| ProjectDetail ||================================ //

function ProjectDetail() {
    const [params] = useSearchParams();
    const [Projectdata, setProjectData] = useState();

    useEffect(() => {
        getProjectDetailById();
    }, []);

    const getProjectDetailById = async () => {
        try {
            var GetProjectDetailByIdAPI = API.getProjectDetailById;
            GetProjectDetailByIdAPI = GetProjectDetailByIdAPI + "?id=" + params.get("Id");
            const response = await fetch(GetProjectDetailByIdAPI);
            const jsonData = await response.json();
            setProjectData(jsonData.recordsets[0]);
            console.log(Projectdata);
        }
        catch (error) {
            console.log(error);
        }
    };

    return (
        <>
            <div className="container-fluid">
                <div className="container d-flex pt-5">
                    <div className="col">
                        <h3 className="card-header px-3 pb-3">Project Detail</h3>
                    </div>
                    <div className="col" style={{ textAlign: "end" }}>
                        <Link to='/admin/project-details' className="btn btn-info cl-button -primary">Back to List</Link>
                    </div>
                </div>
                <div className="container mx-auto ">
                    <div className="px-3 pb-3">
                        <div className="table-responsive text-nowrap shadow p-3 mb-5 bg-body rounded">
                            <div className="container">
                                <div className="col-md-8 mx-auto">
                                    <div className="border rounded-3 p-2">
                                        <table className="row">
                                            <div className="col-md-3">
                                                <thead>
                                                    <tr>
                                                        <div className="col pb-2 fs-6 fw-bold">Index :- </div>
                                                        <div className="col pb-2 fs-6 fw-bold">Name :- </div>
                                                        <div className="col pb-2 fs-6 fw-bold">Email :- </div>
                                                        <div className="col pb-2 fs-6 fw-bold">MobileNo :- </div>
                                                        <div className="col pb-2 fs-6 fw-bold">Country :- </div>
                                                        <div className="col pb-2 fs-6 fw-bold">Industry :- </div>
                                                        <div className="col pb-2 fs-6 fw-bold">Document :- </div>
                                                        <div className="col pb-2 fs-6 fw-bold">Message :- </div>
                                                        <div className="col pb-2 fs-6 fw-bold">Created On :- </div>
                                                    </tr>
                                                </thead>
                                            </div>
                                            <div className="col-md-9">
                                                <tbody className="pb-4">
                                                    {Projectdata &&
                                                        Projectdata.map(({ Id, Name, Email, MobileNo, Country, Industry, Image, Description, CreatedOn }) => (
                                                            <tr key={Id}>
                                                                <div className='col table-data pb-2'>{Id}</div>
                                                                <div className='col table-data pb-2'>{Name}</div>
                                                                <div className='col table-data pb-2'><a className="" href='mailto:{Email}' style={{ cursor: "pointer" }}>{Email}</a></div>
                                                                <div className='col table-data pb-2'>{MobileNo}</div>
                                                                <div className='col table-data pb-2'>{Country}</div>
                                                                <div className='col table-data pb-2'>{Industry}</div>
                                                                <div className='col table-data pb-2'><a href={Image} target="_blank"><FaFile size={'1.2rem'} /></a></div>
                                                                <div className='col table-data pb-2' style={{whiteSpace: 'pre-wrap', overflowWrap: 'break-word'}}>{Description}</div>
                                                                <div className='col table-data pb-2'>{moment(CreatedOn).format('DD-MM-YYYY')}</div>
                                                            </tr>
                                                        ))}
                                                </tbody>
                                            </div>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default ProjectDetail;