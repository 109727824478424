// const ServerPort = 'https://192.168.1.4/'
//const ServerPort = 'http://localhost:1070/'
const ServerPort = "https://185.196.21.59:459/"

var API = {
    userLogin: ServerPort + 'api/Login',
    signuploadform: ServerPort + 'api/GetSignature',

    getActiveModules: ServerPort + 'api/GetActiveModules',

    saveUser: ServerPort + 'api/SaveUser',
    getAllUsers: ServerPort + 'api/GetUsers',
    getUsersCount: ServerPort + 'api/GetUsersCount',
    getUserById: ServerPort + 'api/GetUsersById',
    updateUser: ServerPort + 'api/UpdateUser',
    deleteUserById: ServerPort + 'api/DeleteUsersById',

    saveRole: ServerPort + 'api/SaveRole',
    getAllRoles: ServerPort + 'api/GetRoles',
    getActiveRoles: ServerPort + 'api/GetActiveRoles',
    getRoleById: ServerPort + 'api/GetRoleById',
    updateRole: ServerPort + 'api/UpdateRole',
    updateRoleStatus: ServerPort + 'api/UpdateRoleStatusById',
    deleteRoleById: ServerPort + 'api/DeleteRoleById',
    deleteRolePermissionByRoleId: ServerPort + 'api/DeleteRolePermissionByRoleId',

    rolePermission: ServerPort + 'api/RolePermission',
    getRolePermissionByRoleId: ServerPort + 'api/GetRolePermissionByRoleId',

    checkBlogExists: ServerPort + 'api/CheckBlogExists',
    saveBlog: ServerPort + 'api/SaveBlog',
    getAllBlogs: ServerPort + 'api/GetBlogs',
    getBlogsCount: ServerPort + 'api/GetBlogsCount',
    getBlogsByCategoryId: ServerPort + 'api/GetBlogsByCategoryId',
    getAdminBlogs: ServerPort + 'api/GetAdminBlogs',
    getBlogById: ServerPort + 'api/GetBlogsById',
    getBlogByBlogSlug: ServerPort + 'api/GetBlogsByBlogSlug',
    updateBlog: ServerPort + 'api/UpdateBlog',
    updateBlogStatusById: ServerPort + 'api/UpdateBlogStatusById',
    deleteBlogById: ServerPort + 'api/DeleteBlogsById',

    getScheduleBlogs: ServerPort + 'api/GetScheduleBlogs',

    saveCategory: ServerPort + 'api/SaveCategory',
    getCategories: ServerPort + 'api/GetCategories',
    getActiveCategories: ServerPort + 'api/GetActiveCategories',
    getCategoryById: ServerPort + 'api/GetCategoryById',
    updateCategory: ServerPort + 'api/UpdateCategory',
    updateCategoryStatus: ServerPort + 'api/UpdateCategoryStatusById',
    deleteCategoryById: ServerPort + 'api/DeleteCategoryById',

    getTrashBlogs: ServerPort + 'api/GetTrashBlogs',
    revertBlogById: ServerPort + 'api/RevertBlogById',
    deleteTrashBlogs: ServerPort + 'api/DeleteTrashBlogs',
    deleteTrashBlogById: ServerPort + 'api/DeleteTrashBlogsById',

    saveBlogComments: ServerPort + 'api/SaveBlogComments',
    getAdminBlogComments: ServerPort + 'api/GetAdminBlogComments',
    getAdminBlogCommentsById: ServerPort + 'api/GetAdminBlogCommentsById',
    getBlogCommentsByBlogId: ServerPort + 'api/GetBlogComments',
    updateBlogCommentStatus: ServerPort + 'api/UpdateBlogCommentStatusById',
    deleteBlogCommentById: ServerPort + 'api/DeleteBlogCommentById',

    sendCareerDetailEmail: ServerPort + 'api/SendCareerDetailEmail',
    saveCareerDetail: ServerPort + 'api/SaveCareerDetails',
    getCareerDetail: ServerPort + 'api/GetCareerDetails',
    getCareerDetailCount: ServerPort + 'api/GetCareerDetailsCount',
    getCareerDetailCountByMonth: ServerPort + 'api/GetCareerDetailsCountByMonth',
    getCareerDetailById: ServerPort + 'api/GetCareerDetailsById',
    updateCareerStatusById: ServerPort + 'api/UpdateCareerStatusById',
    deleteCareerDetailById: ServerPort + 'api/DeleteCareerDetailsById',

    getRepliedCareerDetails: ServerPort + 'api/GetRepliedCareerDetails',

    sendContactDetailEmail: ServerPort + 'api/SendContactDetailEmail',
    saveContactDetail: ServerPort + 'api/SaveContactDetails',
    getContactDetail: ServerPort + 'api/GetContactDetails',
    getContactDetailCount: ServerPort + 'api/GetContactDetailsCount',
    getContactDetailCountByMonth: ServerPort + 'api/GetContactDetailsCountByMonth',
    getContactDetailById: ServerPort + 'api/GetContactDetailsById',
    deleteContactDetailById: ServerPort + 'api/DeleteContactDetailsById',

    sendOTPEmail: ServerPort + 'api/SendOTPEmail',
    saveDemoProjectDetail: ServerPort + 'api/SaveDemoProjectDetails',
    getDemoProjectDetail: ServerPort + 'api/GetDemoProjectDetails',
    getDemoProjectDetailCount: ServerPort + 'api/GetDemoProjectDetailsCount',
    getDemoProjectDetailCountByMonth: ServerPort + 'api/GetDemoProjectDetailsCountByMonth',
    getDemoProjectDetailById: ServerPort + 'api/GetDemoProjectDetailsById',
    deleteDemoProjectDetailById: ServerPort + 'api/DeleteDemoProjectDetailsById',

    sendProjectDetailEmail: ServerPort + 'api/SendProjectDetailEmail',
    saveProjectDetail: ServerPort + 'api/SaveProjectDetails',
    getProjectDetail: ServerPort + 'api/GetProjectDetails',
    getProjectDetailCount: ServerPort + 'api/GetProjectDetailsCount',
    getProjectDetailCountByMonth: ServerPort + 'api/GetProjectDetailsCountByMonth',
    getProjectDetailById: ServerPort + 'api/GetProjectDetailsById',
    deleteProjectDetailById: ServerPort + 'api/DeleteProjectDetailsById',

    saveCurrentOpening: ServerPort + 'api/SaveCurrentOpening',
    getCurrentOpenings: ServerPort + 'api/GetCurrentOpenings',
    getAdminCurrentOpenings: ServerPort + 'api/GetAdminCurrentOpenings',
    getCurrentOpeningById: ServerPort + 'api/GetCurrentOpeningById',
    updateCurrentOpening: ServerPort + 'api/UpdateCurrentOpenings',
    updateCurrentOpeningStatusById: ServerPort + 'api/UpdateCurrentOpeningStatusById',
    deleteCurrentOpeningById: ServerPort + 'api/DeleteCurrentOpeningById'
}

export default API;