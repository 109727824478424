import React from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

// ================================|| MediaAdvertising ||================================ //

const MediaAdvertising = () => {
	return (
		<>
			<Helmet>
				<title>Media & Advertising - The Bridge Code</title>
			</Helmet>
			<div className='container-fluid p-0 '>
				<div class="position-relative overflow-hidden text-center supplychain">
					<div class="col-md-5 p-lg-5 mx-auto my-1">
						<h1 class="display-2 fw-bolder text-white title-c">Media Advertising Case Study</h1>
						<Link to='/contact-us' class="cl-button -primary -big rounded-3">Contact Us</Link>
					</div>
				</div>

				<div className="container">
					<div>
						<h4 className="text-h4">Summary</h4>
						<p>
							Chunky a food brand has been around since many years. Nearly 100 years later, Chunky has dedicated fans all over the country, but they aren't as aware of
							Chunky long family history.
						</p>
						<p>Here’s how we updated Chunky website to give it a present-day feel without sacrificing its heritage.</p>
					</div>

					<div>
						<h4 className="text-h4">Challenge</h4>
						<p>Creating a dynamic website that acts as a resource for getting to know Chunky purpose, story, and products.</p>
					</div>

					<div>
						<h4 className="text-h4">Insights</h4>
						<p>
							Creating more recognizable and scalable branding that lives beyond the website. Exhibiting Chunky family heritage and regional story while communicating
							how the brand is modernizing for today.
						</p>
					</div>

					<div>
						<h4 className="text-h4">Solution</h4>
						<p>
							Our strategy revolved around maintaining the integrity of Chunky family-owned legacy while still appealing on a global and national scale. A paramount
							component of the redesign was ensuring that Chunky existing superfans were not left in the wayside in the endeavor to attract new customers.
						</p>
						<p>
							Each product is clearly defined with its own style to make finding your favorite products easy and fun. Whether you look for salted vs. unsalted,
							organic vs. conventional, the UX design aligned with SEO recommendations makes the product pages simple to navigate to find exactly what they were looking for.
						</p>
						<p>We also overhauled the UX for user-submitted recipes. Chunky fans can share their favorite original recipes through social media or on the website itself.</p>
					</div>

					<div>
						<h4 className="text-h4">Results</h4>
						<p>Overall, AMP provided Chunky with a refreshed website that appealed to a broader audience while still catering to their long-time fans. We took great care in ensuring that their core values permeated throughout the site to maintain their values-forward, family-owned feel.</p>
						<p>We envisioned quick, fun, interactive components sprinkled throughout the site to further boost customer engagement – and, as a result, advertiser value.</p>
						<p>Overall, the site makes it easy for customers to learn about and locate their favorite Chunky products, simplifying the experience while making it fun.</p>
					</div>
					<p style={{ marginTop: "50px" }}></p>

					<div class="container-cs" id="case_study">
						<div class="circles-container">
							<div class="circles circleOne"></div>
							<div class="circles circleTwo"></div>
							<div class="circles circleThree"></div>
							<div class="circles circleFour"></div>
							<div class="logo">Media Advertising Case Study</div>
						</div>
						<svg width="1230" height="820" viewBox="0 0 1230 820" fill="none" xmlns="http://www.w3.org/2000/svg" class="mx-auto">
							<g opacity="0.15" class="line bottom bottomTwo">
								<path d="M615.5 661L615.5 497" stroke="#2378D5" stroke-width="12" />
							</g>
							<g opacity="0.15" class="line top topFour">
								<path d="M829 105L829 160.231C829 171.277 820.046 180.231 809 180.231L635 180.231C623.954 180.231 615 189.185 615 200.231L615 268" stroke="#2378D5" stroke-width="12" />
							</g>
							<g opacity="0.15" class="line top topThree">
								<path d="M615 104L615 268" stroke="#2378D5" stroke-width="12" />
							</g>
							<g opacity="0.15" class="line top topTwo">
								<path d="M402 106L402 160.769C402 171.815 410.954 180.769 422 180.769L595 180.769C606.046 180.769 615 189.724 615 200.769L615 268" stroke="#2378D5" stroke-width="12" />
							</g>
							<g opacity="0.15" class="line top topFive">
								<path d="M1041 105L1041 160.231C1041 171.276 1032.05 180.231 1021 180.231L635 180.231C623.954 180.231 615 189.185 615 200.231L615 268" stroke="#2378D5" stroke-width="12" />
							</g>
							<g opacity="0.15" class="line top topOne">
								<path d="M190 106L190 160.769C190 171.815 198.954 180.769 210 180.769L594.503 180.769C605.549 180.769 614.503 189.724 614.503 200.769L614.503 268" stroke="#2378D5" stroke-width="12" />
							</g>
							<g class="dots top dotsOne">
								<path d="M190 106L190 160.769C190 171.815 198.954 180.769 210 180.769L594.503 180.769C605.549 180.769 614.503 189.724 614.503 200.769L614.503 268" stroke="#2378D5" stroke-width="4" />
							</g>
							<g class="dots top dotsFive">
								<path d="M1041 105L1041 160.231C1041 171.276 1032.05 180.231 1021 180.231L635 180.231C623.954 180.231 615 189.185 615 200.231L615 268" stroke="#2378D5" stroke-width="4" />
							</g>
							<g class="dots top dotsTwo">
								<path d="M402 106L402 160.769C402 171.815 410.954 180.769 422 180.769L595 180.769C606.046 180.769 615 189.724 615 200.769L615 268" stroke="#2378D5" stroke-width="4" />
							</g>
							<g class="dots top dotsThree">
								<path d="M615 104L615 268" stroke="#2378D5" stroke-width="4" />
							</g>
							<g class="dots top dotsFour">
								<path d="M829 105L829 160.231C829 171.277 820.046 180.231 809 180.231L635 180.231C623.954 180.231 615 189.185 615 200.231L615 268" stroke="#2378D5" stroke-width="4" />
							</g>
							<g class="dots bottom dotsOne">
								<path d="M353.5 659.5L353.5 604.5C353.5 593.455 362.454 584.5 373.5 584.5L595 584.5C606.046 584.5 615 575.546 615 564.5L615 497" stroke="#2378D5" stroke-width="4" />
							</g>
							<g class="dots bottom dotsTwo">
								<path d="M615.5 661L615.5 497" stroke="#2378D5" stroke-width="4" />
							</g>
							<g class="dots bottom dotsThree">
								<path d="M877 659.5L877 604.5C877 593.454 868.046 584.5 857 584.5L635.5 584.5C624.454 584.5 615.5 575.545 615.5 564.5L615.5 497" stroke="#2378D5" stroke-width="4" />
							</g>
							<g filter="url(#filter0_d_484_1188)" class="block top topOne">
								<rect x="127" y="1" width="125" height="125" rx="6" fill="white" />
								<image x="150" y="23" width={85} xlinkHref="https://res.cloudinary.com/dm1muiikf/image/upload/v1684484853/website/csd/php.png" />
								<rect x="126.5" y="0.5" width="126" height="126" rx="6.5" stroke="#ADADAD" stroke-opacity="0.6" />
							</g>
							<g filter="url(#filter1_d_484_1188)" class="block top topTwo">
								<rect x="339.25" y="1" width="125" height="125" rx="6" fill="white" />
								<image x="343" y="1" width={115} xlinkHref="https://res.cloudinary.com/dm1muiikf/image/upload/v1684484853/website/csd/wordpress.png" />
								<rect x="338.75" y="0.5" width="126" height="126" rx="6.5" stroke="#ADADAD" stroke-opacity="0.6" />
							</g>
							<g filter="url(#filter2_d_484_1188)" class="block top topThree">
								<rect x="551.5" y="1" width="125" height="125" rx="6" fill="white" />
								<image x="575" y="23" width={75} xlinkHref="https://res.cloudinary.com/dm1muiikf/image/upload/v1684484853/website/case_study/acf.png" />
								<rect x="551" y="0.5" width="126" height="126" rx="6.5" stroke="#ADADAD" stroke-opacity="0.6" />
							</g>
							<g filter="url(#filter3_d_484_1188)" class="block top topFour">
								<rect x="763.75" y="1" width="125" height="125" rx="6" fill="white" />
								<image x="773" y="23" width={105} xlinkHref="https://res.cloudinary.com/dm1muiikf/image/upload/v1684484853/website/csd/sql.png" />
								<rect x="763.25" y="0.5" width="126" height="126" rx="6.5" stroke="#ADADAD" stroke-opacity="0.6" />
							</g>
							<g filter="url(#filter4_d_484_1188)" class="block top topFive">
								<rect x="976" y="1" width="125" height="125" rx="6" fill="white" />
								<image x="985" y="15" width={105} xlinkHref="https://res.cloudinary.com/dm1muiikf/image/upload/v1684484853/website/csd/html.png" />
								<rect x="975.5" y="0.5" width="126" height="126" rx="6.5" stroke="#ADADAD" stroke-opacity="0.6" />
							</g>
							<g filter="url(#filter6_d_484_1188)" class="block bottom bottomTwo">
								<rect x="525" y="640" width="180" height="176" rx="6" fill="white" />
								<image x="532" y="650" width={155} xlinkHref="https://res.cloudinary.com/dm1muiikf/image/upload/v1690872419/website/case_study/food.png" />
								<rect x="524.5" y="639.5" width="181" height="177" rx="6.5" stroke="#ADADAD" stroke-opacity="0.6" />
							</g>
							<defs>
								<filter id="filter0_d_484_1188" x="126" y="0" width="130" height="130" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
									<feFlood flood-opacity="0" result="BackgroundImageFix" />
									<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
									<feOffset dx="3" dy="3" />
									<feColorMatrix type="matrix" values="0 0 0 0 0.0340278 0 0 0 0 0.157404 0 0 0 0 0.291667 0 0 0 0.15 0" />
									<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_484_1188" />
									<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_484_1188" result="shape" />
								</filter>
								<filter id="filter1_d_484_1188" x="338.25" y="0" width="130" height="130" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
									<feFlood flood-opacity="0" result="BackgroundImageFix" />
									<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
									<feOffset dx="3" dy="3" />
									<feColorMatrix type="matrix" values="0 0 0 0 0.0340278 0 0 0 0 0.157404 0 0 0 0 0.291667 0 0 0 0.15 0" />
									<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_484_1188" />
									<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_484_1188" result="shape" />
								</filter>
								<filter id="filter2_d_484_1188" x="550.5" y="0" width="130" height="130" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
									<feFlood flood-opacity="0" result="BackgroundImageFix" />
									<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
									<feOffset dx="3" dy="3" />
									<feColorMatrix type="matrix" values="0 0 0 0 0.0340278 0 0 0 0 0.157404 0 0 0 0 0.291667 0 0 0 0.15 0" />
									<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_484_1188" />
									<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_484_1188" result="shape" />
								</filter>
								<filter id="filter3_d_484_1188" x="762.75" y="0" width="130" height="130" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
									<feFlood flood-opacity="0" result="BackgroundImageFix" />
									<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
									<feOffset dx="3" dy="3" />
									<feColorMatrix type="matrix" values="0 0 0 0 0.0340278 0 0 0 0 0.157404 0 0 0 0 0.291667 0 0 0 0.15 0" />
									<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_484_1188" />
									<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_484_1188" result="shape" />
								</filter>
								<filter id="filter4_d_484_1188" x="975" y="0" width="130" height="130" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
									<feFlood flood-opacity="0" result="BackgroundImageFix" />
									<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
									<feOffset dx="3" dy="3" />
									<feColorMatrix type="matrix" values="0 0 0 0 0.0340278 0 0 0 0 0.157404 0 0 0 0 0.291667 0 0 0 0.15 0" />
									<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_484_1188" />
									<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_484_1188" result="shape" />
								</filter>
								<filter id="filter5_d_484_1188" x="259" y="639" width="185" height="181" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
									<feFlood flood-opacity="0" result="BackgroundImageFix" />
									<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
									<feOffset dx="3" dy="3" />
									<feColorMatrix type="matrix" values="0 0 0 0 0.0340278 0 0 0 0 0.157404 0 0 0 0 0.291667 0 0 0 0.15 0" />
									<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_484_1188" />
									<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_484_1188" result="shape" />
								</filter>
								<filter id="filter6_d_484_1188" x="524" y="639" width="185" height="181" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
									<feFlood flood-opacity="0" result="BackgroundImageFix" />
									<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
									<feOffset dx="3" dy="3" />
									<feColorMatrix type="matrix" values="0 0 0 0 0.0340278 0 0 0 0 0.157404 0 0 0 0 0.291667 0 0 0 0.15 0" />
									<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_484_1188" />
									<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_484_1188" result="shape" />
								</filter>
								<filter id="filter7_d_484_1188" x="789" y="639" width="185" height="181" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
									<feFlood flood-opacity="0" result="BackgroundImageFix" />
									<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
									<feOffset dx="3" dy="3" />
									<feColorMatrix type="matrix" values="0 0 0 0 0.0340278 0 0 0 0 0.157404 0 0 0 0 0.291667 0 0 0 0.15 0" />
									<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_484_1188" />
									<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_484_1188" result="shape" />
								</filter>
							</defs>
						</svg>
					</div>

					{/* **********************  Mobile view casestudy ********************** */}
					<div class="" id="casestudy">
						<div id="demo">
							<svg viewBox="0 0 630 1630" fill="none" xmlns="http://www.w3.org/2000/svg" class="mt-5">
								<g opacity="0.15" class="line bottom bottomTwo">
									<path d="M315 104L315 1468" stroke="#2378D5" stroke-width="12" />
								</g>
								<g opacity="0.15" class="line top topTwo">
									<path d="M315 104L315 1468" stroke="#2378D5" stroke-width="12" />
								</g>
								<g class="dots top dotsTwo">
									<path d="M315 104L315 468" stroke="#2378D5" stroke-width="4" />
								</g>
								<g class="dots top dotsThree">
									<path d="M315 104L315 468" stroke="#2378D5" stroke-width="4" />
								</g>
								<g class="dots top dotsFour">
									<path d="M315 104L315 468" stroke="#2378D5" stroke-width="4" />
								</g>
								<g class="dots bottom dotsOne">
									<path d="M315 661L315.5 1497" stroke="#2378D5" stroke-width="4" />
								</g>
								<g class="dots bottom dotsTwo">
									<path d="M315 661L315.5 1497" stroke="#2378D5" stroke-width="4" />
								</g>
								<g class="dots bottom dotsThree">
									<path d="M315 661L315.5 1497" stroke="#2378D5" stroke-width="4" />
								</g>
								<g class="block top topOne">
									<rect x="250" y="1" width="125" height="125" rx="6" fill="white" />
									<image x="255" y="15" width={111} xlinkHref="https://res.cloudinary.com/dm1muiikf/image/upload/v1684484853/website/csd/php.png" />
									<rect x="250.5" y="0.5" width="126" height="126" rx="6.5" stroke="#ADADAD" stroke-opacity="0.6" />
								</g>
								<g class="block top topTwo">
									<rect x="250" y="228" width="125" height="125" rx="6" fill="white" />
									<image x="256" y="229" width={115} xlinkHref="https://res.cloudinary.com/dm1muiikf/image/upload/v1684484853/website/csd/wordpress.png" />
									<rect x="250.5" y="228.5" width="126" height="126" rx="6.5" stroke="#ADADAD" stroke-opacity="0.6" />
								</g>
								<g class="block top topThree">
									<rect x="250" y="455" width="125" height="125" rx="6" fill="white" />
									<image x="269" y="469" width={95} xlinkHref="https://res.cloudinary.com/dm1muiikf/image/upload/v1684484853/website/case_study/acf.png" />
									<rect x="250.5" y="455.5" width="126" height="126" rx="6.5" stroke="#ADADAD" stroke-opacity="0.6" />
								</g>
								<g class="block top topFour">
									<rect x="250" y="682" width="125" height="125" rx="6" fill="white" />
									<image x="259" y="695" width={105} xlinkHref="https://res.cloudinary.com/dm1muiikf/image/upload/v1684484853/website/csd/sql.png" />
									<rect x="250.25" y="682.5" width="126" height="126" rx="6.5" stroke="#ADADAD" stroke-opacity="0.6" />
								</g>
								<g class="block top topFive">
									<rect x="250" y="909" width="125" height="125" rx="6" fill="white" />
									<image x="250" y="920" width={115} xlinkHref="https://res.cloudinary.com/dm1muiikf/image/upload/v1684484853/website/csd/html.png" />
									<rect x="250.5" y="909.5" width="126" height="126" rx="6.5" stroke="#ADADAD" stroke-opacity="0.6" />
								</g>

								<svg id="svg" viewBox="0 0 320 1" width="71%" >
									<circle class="background" cx="220" cy="290" r="35" stroke="" fill="#0051AA" />
									<circle class="background" cx="220" cy="290" r="75" stroke="" fill="#B3D1EF" />
									<circle class="background" cx="220" cy="290" r="55" stroke="" fill="#0051AA" />
									<foreignObject x="165" y="255" width="100" height="100">
										<div className="fs-6 text-white text-center">
											Media Advertising Case Study
										</div>
									</foreignObject>
									<circle class="background" cx="220" cy="290" r="95" stroke="" fill="rgba(0, 74, 171, 0.1)" />
								</svg>

								<g class="block bottom bottomTwo">
									<rect x="220" y="1436" width="180" height="176" rx="6" fill="white" />
									<image x="229" y="1457" width={155} xlinkHref="https://res.cloudinary.com/dm1muiikf/image/upload/v1690872419/website/case_study/food.png" />
									<rect x="220.5" y="1436.5" width="181" height="177" rx="6.5" stroke="#ADADAD" stroke-opacity="0.6" />
								</g>
								<defs>
									<filter id="filter0_d_484_1188" x="126" y="0" width="130" height="130" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
										<feFlood flood-opacity="0" result="BackgroundImageFix" />
										<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
										<feOffset dx="3" dy="3" />
										<feColorMatrix type="matrix" values="0 0 0 0 0.0340278 0 0 0 0 0.157404 0 0 0 0 0.291667 0 0 0 0.15 0" />
										<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_484_1188" />
										<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_484_1188" result="shape" />
									</filter>
									<filter id="filter1_d_484_1188" x="338.25" y="0" width="130" height="130" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
										<feFlood flood-opacity="0" result="BackgroundImageFix" />
										<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
										<feOffset dx="3" dy="3" />
										<feColorMatrix type="matrix" values="0 0 0 0 0.0340278 0 0 0 0 0.157404 0 0 0 0 0.291667 0 0 0 0.15 0" />
										<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_484_1188" />
										<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_484_1188" result="shape" />
									</filter>
									<filter id="filter2_d_484_1188" x="550.5" y="0" width="130" height="130" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
										<feFlood flood-opacity="0" result="BackgroundImageFix" />
										<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
										<feOffset dx="3" dy="3" />
										<feColorMatrix type="matrix" values="0 0 0 0 0.0340278 0 0 0 0 0.157404 0 0 0 0 0.291667 0 0 0 0.15 0" />
										<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_484_1188" />
										<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_484_1188" result="shape" />
									</filter>
									<filter id="filter3_d_484_1188" x="762.75" y="0" width="130" height="130" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
										<feFlood flood-opacity="0" result="BackgroundImageFix" />
										<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
										<feOffset dx="3" dy="3" />
										<feColorMatrix type="matrix" values="0 0 0 0 0.0340278 0 0 0 0 0.157404 0 0 0 0 0.291667 0 0 0 0.15 0" />
										<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_484_1188" />
										<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_484_1188" result="shape" />
									</filter>
									<filter id="filter4_d_484_1188" x="975" y="0" width="130" height="130" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
										<feFlood flood-opacity="0" result="BackgroundImageFix" />
										<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
										<feOffset dx="3" dy="3" />
										<feColorMatrix type="matrix" values="0 0 0 0 0.0340278 0 0 0 0 0.157404 0 0 0 0 0.291667 0 0 0 0.15 0" />
										<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_484_1188" />
										<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_484_1188" result="shape" />
									</filter>
									<filter id="filter5_d_484_1188" x="259" y="639" width="185" height="181" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
										<feFlood flood-opacity="0" result="BackgroundImageFix" />
										<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
										<feOffset dx="3" dy="3" />
										<feColorMatrix type="matrix" values="0 0 0 0 0.0340278 0 0 0 0 0.157404 0 0 0 0 0.291667 0 0 0 0.15 0" />
										<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_484_1188" />
										<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_484_1188" result="shape" />
									</filter>
									<filter id="filter6_d_484_1188" x="524" y="639" width="185" height="181" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
										<feFlood flood-opacity="0" result="BackgroundImageFix" />
										<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
										<feOffset dx="3" dy="3" />
										<feColorMatrix type="matrix" values="0 0 0 0 0.0340278 0 0 0 0 0.157404 0 0 0 0 0.291667 0 0 0 0.15 0" />
										<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_484_1188" />
										<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_484_1188" result="shape" />
									</filter>
									<filter id="filter7_d_484_1188" x="789" y="639" width="185" height="181" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
										<feFlood flood-opacity="0" result="BackgroundImageFix" />
										<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
										<feOffset dx="3" dy="3" />
										<feColorMatrix type="matrix" values="0 0 0 0 0.0340278 0 0 0 0 0.157404 0 0 0 0 0.291667 0 0 0 0.15 0" />
										<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_484_1188" />
										<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_484_1188" result="shape" />
									</filter>
								</defs>
							</svg>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default MediaAdvertising;