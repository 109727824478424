import React from 'react';
import { Link } from "react-router-dom";

const Page404 = () => {

	return (
		<>
			<div id='oopss' className='mx-auto text-center'>
				<div id='error-text'>
					<img className='mw-100' src="https://cdn.rawgit.com/ahmedhosna95/upload/1731955f/sad404.svg" alt="404" /><br />
					<span className='display-2 fw-bolder'>404 PAGE</span>
					<p class="p-a">
						The page you were looking for could not be found</p>
					<Link to='/' class="cl-button -primary -big rounded-3">Back to Site</Link>
				</div>
			</div>
		</>
	);
};

export default Page404;